import axios from 'axios';
import { ISearch } from 'models/ISearch';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const searchBundles = async (params: ISearch) => {
  return axios(`${BASE_URL}/api/search/bundles`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'GET',
    params: params,
  }).then(response => response.data)
};
