import React from 'react';

interface Props {
  children?: React.ReactNode;
  bundle: any;
}

const JourneySection: React.FC<Props> = ({ bundle }) => {

  if (bundle && bundle.map_url) {
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          overflow: 'hidden',
        }}
      >
        <iframe
          width="100%"
          // Container height + top bar that's removed
          height="359px"
          src={bundle.map_url}
          title="map"
          style={{
            position: 'relative',
            // Hide top bar
            top: -59,
            border: 0,
          }}
          // Below recommended by https://developers.google.com/maps/documentation/embed/embedding-map
          frameBorder="0"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  } else {
    return null;
  }
};

export default JourneySection;
