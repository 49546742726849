/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { CircularProgress, Box } from '@mui/material';

import { useCodeConfirmation } from 'hooks/api';

import { UserContext } from 'context/User';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#F9FAFB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh',
    padding: '50px 0',
    color: '#252525',
  },
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CodeConfirmation = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const classes = useStyles();
  const code = query.get('code');

  const { emailVerifyApi } = useCodeConfirmation();

  const { userState } = useContext(UserContext);

  useEffect(() => {
    emailVerifyApi({
      token: code || '',
    });
  }, []);

  useEffect(() => {
    if (userState.is_email_verified) {
      navigate('/');
    }
  }, [userState.is_email_verified]);

  return (
    <Container component="main" className={classes.container}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '100px',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
};

export default CodeConfirmation;
