type ICameraIcon = {
  width?: string;
  height?: string;
};
const CameraIcon = ({ width = '27', height = '25' }: ICameraIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2723 2.89339L18.7124 5.56022H24.1128V21.5612H2.77815V5.56022H8.17848L10.6186 2.89339H16.2723ZM17.4457 0.226562H9.44522L7.00508 2.89339H2.77815C1.3114 2.89339 0.111328 4.09346 0.111328 5.56022V21.5612C0.111328 23.0279 1.3114 24.228 2.77815 24.228H24.1128C25.5795 24.228 26.7796 23.0279 26.7796 21.5612V5.56022C26.7796 4.09346 25.5795 2.89339 24.1128 2.89339H19.8858L17.4457 0.226562ZM13.4455 9.56046C15.6456 9.56046 17.4457 11.3606 17.4457 13.5607C17.4457 15.7608 15.6456 17.5609 13.4455 17.5609C11.2453 17.5609 9.44522 15.7608 9.44522 13.5607C9.44522 11.3606 11.2453 9.56046 13.4455 9.56046ZM13.4455 6.89363C9.76524 6.89363 6.77839 9.88048 6.77839 13.5607C6.77839 17.2409 9.76524 20.2278 13.4455 20.2278C17.1257 20.2278 20.1125 17.2409 20.1125 13.5607C20.1125 9.88048 17.1257 6.89363 13.4455 6.89363Z"
      fill="white"
    />
  </svg>
);

export default CameraIcon;
