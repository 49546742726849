import React, { FC } from 'react';
import AppleLogin from 'react-apple-login';
import * as styles from './styles';
import AppleIcon from 'assets/apple-icon';
import { IContinueWithAppleProps } from './ContinueWithAppleType.d';

const ContinueWithApple: FC<IContinueWithAppleProps> = ({ title }) => {
  return (
    <AppleLogin
      clientId={process.env.REACT_APP_APPLE_CLIENT_ID || ''}
      redirectURI={process.env.REACT_APP_BASE_URL + '/api/user/apple-auth'}
      usePopup={false}
      responseType="code"
      scope="name email"
      responseMode="form_post"
      render={(
        renderProps //Custom Apple Sign in Button
      ) => (
        <button
          onClick={renderProps.onClick}
          style={styles.SignInWithAppleButton}
        >
          <i className="fa-brands fa-apple px-2 "></i>
          <AppleIcon />
          {title}
        </button>
      )}
    />
  );
};

export default ContinueWithApple;
