import React, { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormGroup, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import { useDeleteUser } from 'hooks/api';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
}

const DeleteAccountConfirmDrawer: React.FC<Props> = ({
  open,
  setOpen,
  email,
}) => {
  const drawerBleeding = 0;

  const MAGIC_WORD = 'DELETE';
  const [ confirmationText, setConfirmationText ] = useState('');
  const [ deleteReasons, setDeleteReasons ] = useState({
    wrongLocation: false,
    notInterested: false,
    unexpected: false,
    other: false,
    otherReason: '',
  });
  const { mutateAsync: deleteUserMutation } = useDeleteUser();
  const [ step, setStep ] = useState(1);
  const continueHandler = () => {
    if (step === 1) {
      setStep(step + 1);
    } else if (step === 2) {
      deleteUserMutation(deleteReasons);
    }
  };

  const handleCheckboxChange = (param: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setDeleteReasons({...deleteReasons, [param]: event.target.checked})
    }
  }

  const handleTextboxChange = (param: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setDeleteReasons({...deleteReasons, [param]: event.target.value})
    }
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => {}}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: false,
      }}
      style={{
        backgroundColor: '#939393',
      }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          maxWidth: '600px',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
        padding={'20px'}
      >
        <Typography variant='h6' component='h2'>
          Account deletion
        </Typography>
        {step === 1 && (
          <div>
            <p>
              We're trying to make Tandem as useful as possible.
              If the app didn't meet your expectations, we'd love to hear how we can improve it.
            </p>
            <FormGroup>
              <FormLabel>Why are you deleting your account?</FormLabel>
              <FormControlLabel
                value="wrongLocation"
                control={
                  <Checkbox
                    size='small'
                    checked={deleteReasons.wrongLocation}
                    onChange={handleCheckboxChange('wrongLocation')}
                  />
                }
                label="I'm not in Toronto" />
              <FormControlLabel
                value="notInterested"
                control={
                  <Checkbox
                    size='small'
                    checked={deleteReasons.notInterested}
                    onChange={handleCheckboxChange('notInterested')}
                  />
                }
                label="I didn't like any of the experiences"
              />
              <FormControlLabel
                value="unexpected"
                control={
                  <Checkbox
                    size='small'
                    checked={deleteReasons.unexpected}
                    onChange={handleCheckboxChange('unexpected')}
                  />
                }
                label="I thought it was something else (such as a dating app)"
              />
              <FormControlLabel
                value="other"
                control={
                  <Checkbox
                    size='small'
                    checked={deleteReasons.other}
                    onChange={handleCheckboxChange('other')}
                  />
                }
                label="Some other reason"
              />
              <TextField
                id="otherReason"
                label="Please tell us how we can improve"
                value={deleteReasons.otherReason}
                onChange={handleTextboxChange('otherReason')}
                variant="standard"
                size='small'
                fullWidth
                margin='none'
                style={{ display: deleteReasons.other ? 'block' : 'none' }}
              />
            </FormGroup>
          </div>
        )}
        {step === 2 && (
          <div>
            <p>
              You’re about to permanently delete your account. This action
              cannot be undone. Once you submit your account for deletion,
              the deletion process will begin and you will not be able to
              retrieve any saved content. You also will not be able to login
              to your account with your existing credentials.
            </p>
            <p>
              After deletion is completed you will receive a confirmation
              email.
            </p>
            <p>
              Before you permanently delete your account, please type{' '}
              <Box
                component={'span'}
                style={{ color: 'red', fontWeight: 'bold' }}
              >
                {MAGIC_WORD}
              </Box>
            </p>
            <TextField
              id='confirmationText'
              required
              fullWidth
              placeholder={`Enter '${MAGIC_WORD}'`}
              onChange={(event) => setConfirmationText(event.target.value)}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
            marginBottom: '10px',
          }}
        >
          <Button
            style={{ marginRight: '20px' }}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant={step === 2 ? 'contained' : 'outlined'}
            onClick={continueHandler}
            disabled={step === 2 && confirmationText.toUpperCase() !== MAGIC_WORD.toUpperCase()}
            color='error'
          >
            {step === 2 ? 'Delete My Account' : 'Continue'}
          </Button>
        </div>
      </Box>
    </SwipeableDrawer>
  );
};

export default DeleteAccountConfirmDrawer;
