type IUploadFileIcon = {
  width?: string;
  height?: string;
};

const UploadFileIcon = ({ width = '54', height = '68' }: IUploadFileIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5524 0.782227H6.71047C3.01971 0.782227 0.0335531 3.80194 0.0335531 7.4927L0 61.1765C0 64.8672 2.98616 67.887 6.67692 67.887H46.9733C50.6641 67.887 53.6838 64.8672 53.6838 61.1765V20.9136L33.5524 0.782227ZM46.9733 61.1765H6.71047V7.4927H30.1971V24.2689H46.9733V61.1765ZM13.4209 44.4339L18.1518 49.1647L23.4867 43.8635V57.8213H30.1971V43.8635L35.532 49.1983L40.2628 44.4339L26.8754 30.9794L13.4209 44.4339Z"
        fill="#3381FF"
      />
    </svg>
  );
};

export default UploadFileIcon;
