/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ConnectionBox from './ConnectionBox';
import { UserContext } from 'context/User';
import { useGetActiveConnection, useSetActiveConnection } from 'hooks/api';

interface Props {
  children?: React.ReactNode;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  partners?: any[];
  handleRemovePartner: any;
}

const ConnectWithPartner: React.FC<Props> = ({
  setOpen,
  partners = [],
  handleRemovePartner,
}) => {
  const { userState } = useContext(UserContext);
  const { activeConnection, refetchActiveConnection } = useGetActiveConnection(userState?.email);
  const { setActiveConnectionApi } = useSetActiveConnection();

  const handleActiveConnection = async (partnerId: string, checked: boolean) => {
    await setActiveConnectionApi({email: userState.email, checked, connectionId: partnerId});
    refetchActiveConnection();
  }

  useEffect(() => {
    refetchActiveConnection();
  }, [])

  return (
    <Box>
      <Box
        onClick={() => setOpen(true)}
        sx={{ cursor: 'pointer' }}
        padding="10px"
        paddingLeft="20px"
        display="flex"
        borderRadius="10px"
        alignItems="center"
        justifyContent="flex-start"
        marginBottom="22.66px"
        marginTop="30px"
        width="183px"
        height="40px"
        border="1px solid #3381FF"
      >
        <span
          style={{ fontWeight: '400', fontSize: '14px', textAlign: 'left' }}
        ></span>
        Add connection <AddCircleOutlineIcon sx={{ marginLeft: '10px' }} />
      </Box>
      {partners.length > 0 ? (
        <Box display="flex" flexDirection="column">
          {partners.map((partner: any, index: number) => (
            <ConnectionBox
              key={index}
              user={partner}
              handleRemovePartner={handleRemovePartner}
              isActiveConnection={
                activeConnection
                  ? activeConnection == partner?.partnerId
                  : false
              }
              handleActiveConnection={handleActiveConnection}
            />
          ))}
        </Box>
      ) : (
        <Box fontSize={'12px'} fontWeight="600" marginBottom={'20px'}>
          No partners found
        </Box>
      )}
    </Box>
  );
};

export default ConnectWithPartner;
