import React from 'react';

import { toast } from 'react-toastify';

import Button from '@mui/material/Button';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShareIcon from '@mui/icons-material/Share';
import CircularProgress from '@mui/material/CircularProgress';

import { IBundle } from 'models/IBundle';

import { handleShare } from 'utils/handleShare';
import { getBundleCost } from 'utils/bundle';
import {
  useSaveBundle,
  useUnsaveBundle,
} from 'hooks/api';

interface Props {
  children?: React.ReactNode;
  bundle: IBundle;
}

const WishlistSection: React.FC<Props> = ({ bundle }) => {
  const { saveBundle, saveBundleLoading: isSaving } = useSaveBundle();
  const { unsaveBundle, isLoading: isUnsaving } = useUnsaveBundle();

  const showLoginToast = () => {
    toast.dismiss();
    toast.info('Please login to use this feature');
  };

  const price = getBundleCost(bundle);

  const handleSave = async () => {
    if (bundle && bundle.id) {
      await saveBundle({ bundle_id: bundle.id });
    }
  };

  const handleUnsave = async () => {
    if (bundle && bundle.id) {
      await unsaveBundle({ id: bundle.id });
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#F9F9F9',
        color: 'black',
        width: '100%',
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderBottom: '1px solid #E2E2E2',
      }}
    >
      <Button
        onClick={
          localStorage.getItem('tandemToken')
            ? bundle.isSaved
              ? handleUnsave
              : handleSave
            : showLoginToast
        }
        disabled={isSaving || isUnsaving}
        startIcon={ (isSaving || isUnsaving) ? <CircularProgress size={24} /> : (bundle.isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />) }
        sx={{ color: 'black' }}
      >
        {bundle.isSaved ? "Saved" : "Save"}
      </Button>
      <Button
        onClick={() => {
          handleShare({
            id: bundle.id,
            title: 'Share bundle',
            url: window.location.href,
          });
        }}
        startIcon={<ShareIcon sx={{ fontSize: 20 }} />}
        sx={{ color: 'black' }}
      >
        Share
      </Button>
      <div
        style={{
          fontWeight: '400',
          fontSize: 16,
          whiteSpace: 'nowrap',
        }}
      >
        {price}
      </div>
    </div>
  );
};

export default WishlistSection;
