/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { isSchedulePassedHandler } from "utils/isSchedulePassed";

import { toast } from 'react-toastify';
import moment from 'moment';

import Keywords from './components/Keywords/Keywords';

import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Card from 'components/Card/Card';
import useStyles from './BundleScheduleAndRating.styles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {
  IScheduleDates,
  IRatingItem,
  IBundleScheduleRating,
} from './BundleScheduleAndRating.types';
import AddDateModal from './components/AddDateModal/AddDateModal';

import { IAvailableDateTime, IDaveRatings } from 'models/IBundle';
import { COLORS } from 'constants/colors';

type Dictionary<T> = {
  [Key: string]: T;
};

const BundleScheduleAndRating = ({
  keywordsTrigger,
  getKeywords,
  getScheduledDates,
  getDaveRatings,
  bundle,
}: IBundleScheduleRating) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [scheduledDates, setScheduledDates] = useState<
    Dictionary<IAvailableDateTime>
  >(bundle ? bundle.available_date_times ?? {} : {});

  const sortDates = (dates: any) => {
    const sortedKeys = Object.keys(dates);
    let quit = false;
    while (!quit) {
      quit = true;
      for (let i = 0; i < sortedKeys.length; i++) {
        if (i !== sortedKeys.length - 1) {
          if (
            moment(sortedKeys[i], 'MM/DD/YYYY').isBefore(
              moment(sortedKeys[i + 1], 'MM/DD/YYYY')
            )
          ) {
            let temp = sortedKeys[i];
            sortedKeys[i] = sortedKeys[i + 1];
            sortedKeys[i + 1] = temp;
            quit = false;
          }
        }
      }
    }

    const sortedScheduledDatesTemp: any = {};
    sortedKeys.forEach((key: string) => {
      sortedScheduledDatesTemp[key] = dates[key];
    });
    setScheduledDates(sortedScheduledDatesTemp);
  };

  const [daveRatings, setDaveRatings] = useState<IDaveRatings>({
    Stimulation: 0,
    Cerebration: 0,
    Formality: 0,
    Physicality: 0,
  });

  useEffect(() => {
    if (!bundle) {
      setScheduledDates({});
      setDaveRatings({
        Stimulation: 0,
        Cerebration: 0,
        Formality: 0,
        Physicality: 0,
      });
      return;
    }
    sortDates(bundle.available_date_times ?? {});
    setDaveRatings({
      Stimulation: bundle?.dave_ratings?.Stimulation ?? 0,
      Cerebration: bundle?.dave_ratings?.Cerebration ?? 0,
      Formality: bundle?.dave_ratings?.Formality ?? 0,
      Physicality: bundle?.dave_ratings?.Physicality ?? 0,
    });
  }, [bundle]);

  useEffect(() => {
    if (keywordsTrigger) {
      getScheduledDates(scheduledDates ?? {});
      getDaveRatings(daveRatings);
    }
  }, [keywordsTrigger]);

  const saveDate = (datetime: string) => {
    const date: string = moment(datetime).format('MM/DD/YYYY').toString();
    const time: string = moment(datetime).format('hh:mm A').toString();
    toast.dismiss();
    if (scheduledDates && scheduledDates[date]) {
      toast.error('Date already scheduled');
    } else {
      toast.success('DateTime saved');
      const scheduledDatesTemp: any = { ...scheduledDates };
      scheduledDatesTemp[date] = {
        start_time: time,
        end_time: time,
      };
      sortDates(scheduledDatesTemp);
    }
  };

  const handleSaveTextArea = (text: string) => {
    const enteredDates = text.trim().replace(/"/g, '').split(/\r?\n/).filter(date => date.trim() !== '');
    const datesToAdd: any = {};
    let isValidInput = true;
    let dateDuplicates = 0;
  
    for (let i = 0; i < enteredDates.length; i++) {
      const datetime = enteredDates[i];
      try {
        const momentDate = moment(datetime);
        if (!momentDate.isValid()) {
          isValidInput = false;
          toast.error('Invalid date format: ' + datetime);
          continue;
        }
  
        const date: string = momentDate.format('MM/DD/YYYY').toString();
        const time: string = momentDate.format('hh:mm A').toString();
  
        if (scheduledDates && scheduledDates[date]) {
          dateDuplicates++;
        } else {
          datesToAdd[date] = {
            start_time: time,
            end_time: time,
          };
        }
      } catch (error) {
        isValidInput = false;
      }
    }
  
    if (isValidInput && Object.keys(datesToAdd).length > 0) {
      const scheduledDatesTemp: any = { ...scheduledDates, ...datesToAdd };
      setScheduledDates(scheduledDatesTemp);
      if (dateDuplicates){
        toast.error(dateDuplicates + ' dates already existed');
      }
      toast.success(enteredDates.length - dateDuplicates + ' instances added to schedule');
    } else if (!isValidInput) {
      toast.error('Parsing Error');
    } else {
      toast.error('No new dates to save.');
    }
  };
  
  const removeScheduledDate = (date: string) => {
    const scheduledDatesTemp = { ...scheduledDates };
    delete scheduledDatesTemp[date];
    setScheduledDates(scheduledDatesTemp);
  };

  const ScheduleDates = ({ date, time }: IScheduleDates) => {
    const isSchedulePassed = isSchedulePassedHandler({
      start_date: date,
      start_time: time,
    });
    return (
      <Box display={'flex'} gap={'8px'} alignItems={'center'} color={'black'}>
        <RemoveCircleOutlineIcon
          sx={{
            width: '15px',
            height: '22px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => removeScheduledDate(date)}
        />
        <Typography
          color={isSchedulePassed ? COLORS.darkGray : COLORS.black}
          fontSize={'12px'}
        >
          {date + ' ' + time}
        </Typography>
      </Box>
    );
  };

  const RatingItem = ({ heading, number }: IRatingItem) => {
    return (
      <Box display={'flex'} justifyContent={'space-between'} color={'black'}>
        <Typography fontSize={'14px'}>{heading}</Typography>

        <input
          type={'number'}
          value={number}
          style={{ width: '30px', fontSize: '14px', textAlign: 'center' }}
          onChange={(e: any) =>
            setDaveRatings((prevState) => {
              const newObject: any = {
                ...prevState,
              };
              if (e.target.value.length !== 0) {
                newObject[heading] = parseInt(e.target.value);
              } else {
                newObject[heading] = e.target.value;
              }
              return newObject;
            })
          }
        />
      </Box>
    );
  };
  return (
    <>
      <AddDateModal open={open} setOpen={setOpen} saveDate={saveDate} saveTextArea={handleSaveTextArea}/>
      <Box className={classes.container}>
        <Box className={classes.mainContainer} width={'28% !important'}>
          <Card>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              padding={'5%'}
            >
              <Typography fontWeight={'bold'}>Schedule</Typography>
              <AddCircleOutlineIcon
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => setOpen(true)}
              />
            </Box>
            <Box className={classes.scheduleDatesContainer}>
              {scheduledDates && Object.keys(scheduledDates).length > 0 ? (
                Object.keys(scheduledDates).map(
                  (key: string, index: number) => (
                    <ScheduleDates
                      key={index}
                      date={key}
                      time={scheduledDates[key]?.start_time}
                    />
                  )
                )
              ) : (
                <Typography fontSize={'14px'} color={'black'}>
                  No scheduled dates
                </Typography>
              )}
            </Box>
          </Card>
        </Box>
        <Box className={classes.mainContainer} width={'28% !important'}>
          <Card>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              padding={'5%'}
            >
              <Typography fontWeight={'bold'}>Dave Rating</Typography>
              <Tooltip
                placement="top"
                title="Please see DAV Guideline document for information on scoring."
                arrow
              >
                <HelpOutlineIcon sx={{ '&:hover': { cursor: 'pointer' } }} />
              </Tooltip>
            </Box>
            <Box className={classes.daveRatingContainer}>
              {RatingItem({
                heading: 'Stimulation',
                number: daveRatings.Stimulation,
              })}
              {RatingItem({
                heading: 'Formality',
                number: daveRatings.Formality,
              })}
              {RatingItem({
                heading: 'Physicality',
                number: daveRatings.Physicality,
              })}
              {RatingItem({
                heading: 'Cerebration',
                number: daveRatings.Cerebration,
              })}
            </Box>
          </Card>
        </Box>
        <Box className={classes.mainContainer} width={'40% !important'}>
          <Keywords
            keywordsTrigger={keywordsTrigger}
            getKeywords={getKeywords}
            bundle={bundle}
          />
        </Box>
      </Box>
    </>
  );
};

export default BundleScheduleAndRating;