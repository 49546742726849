import { useQuery, useMutation } from 'react-query';

import { toast } from 'react-toastify';

import {
  getBundleApi,
  getRecommendedBundlesApi,
  addBundleApi,
  getAdminBundlesApi,
  updateBundleApi,
  deleteBundleApi,
} from 'api';
import { IBundle } from 'models/IBundle';
import { apiErrorHandler } from 'utils/apiErrorHandler';


export const useGetBundle = (slug: string | undefined) => {
  return useQuery(
    ['bundles', slug],
    () => getBundleApi(slug || ''),
    {
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.errors?.[0] || error?.response?.data?.error
        );
        console.error('====>>> error', error?.response?.data?.errors);
      },
      enabled: !!slug,
    }
  );
};

export const useGetRecommendedBundles = () => {
  return useQuery(
    ['bundles', 'recommended'],
    () => getRecommendedBundlesApi(),
    {
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.errors?.[0] || error?.response?.data?.error
        );
        console.error('====>>> error', error?.response?.data?.errors);
      },
      enabled: !!localStorage.getItem('tandemToken'),
    }
  );
};


export const useGetAdminBundles = () => {
  const { data, error, isLoading, refetch } = useQuery(
    ['bundles', 'admin'],
    () => getAdminBundlesApi(),
    {
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.errors?.[0] || error?.response?.data?.error
        );
        console.error('====>>> error', error?.response?.data?.errors);
      },
    }
  );
  return {
    isLoading,
    bundles: data?.data?.bundles,
    error,
    refetchAdminBundles: refetch,
  };
};

export const useAddBundle = () => {
  const { mutateAsync: addBundle, isLoading } = useMutation(
    (bundle: IBundle) => addBundleApi(bundle),
    {
      onSuccess: ({ data }) => {
        toast.dismiss();
        toast.success('Bundle added successfully.');
      },
      onError: (error: any) => {
        toast.dismiss();
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    addBundle,
  };
};

export const useUpdateBundle = () => {
  const { mutateAsync: updateBundle, isLoading } = useMutation(
    (bundle: IBundle) => updateBundleApi(bundle),
    {
      onSuccess: ({ data }) => {
        toast.dismiss();
        toast.success('Bundle updated successfully.');
      },
      onError: (error: any) => {
        toast.dismiss();
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    updateBundle,
  };
};

export const useDeleteBundle = () => {
  const { mutateAsync: deleteBundle, isLoading } = useMutation(
    (bundleId: number) => deleteBundleApi(bundleId),
    {
      onSuccess: ({ data }) => {
        toast.dismiss();
        toast.success(data?.message);
      },
      onError: (error: any) => {
        toast.dismiss();
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    deleteBundle,
  };
};
