import axios from 'axios';
import { IBundle } from 'models/IBundle';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getRecommendedBundlesApi = async () => {
  return axios(`${BASE_URL}/api/bundles/recommended`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
  }).then((response) => response);
};

export const getAdminBundlesApi = async () => {
  return axios(`${BASE_URL}/api/bundles/get-admin-bundles`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
  }).then((response) => response);
};

export const addBundleApi = async (bundle: IBundle) => {
  return axios(`${BASE_URL}/api/bundles/`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'POST',
    data: bundle,
  }).then((response) => response);
};

export const updateBundleApi = async (bundle: IBundle) => {
  return axios(`${BASE_URL}/api/bundles/${bundle.id}`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'PUT',
    data: bundle,
  }).then((response) => response);
};

export const deleteBundleApi = async (bundleId: number) => {
  return axios(`${BASE_URL}/api/bundles/${bundleId}`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'DELETE',
  }).then((response) => response);
};

export const getBundleApi = async (bundleSlug: string) => {
  return axios(`${BASE_URL}/api/bundles/${bundleSlug}`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
  }).then((response) => response);
};
