import { useEffect, useState } from 'react';
export const usePushPermissionRequest = () => {
  const [permission, setPermission] = useState<string | null>(null);

  // ===== Receiving push notification status ======
  useEffect(() => {
    const permissionEventListner = (message: any) => {
      if (message && message.detail) {
        switch (message.detail) {
          case 'notDetermined':
            setPermission('notDetermined');
            break;
          case 'denied':
            setPermission('denied');
            break;
          case 'authorized':
          case 'ephemeral':
          case 'provisional':
          case 'granted':
            setPermission('granted');
            break;
          case 'unknown':
          default: // something went wrong getting permission
            setPermission('unknown');
            alert('some this went wrong getting permission');
            break;
        }
      }
    };
    window.addEventListener('push-permission-request', permissionEventListner);
    return () => {
      window.removeEventListener(
        'push-permission-request',
        permissionEventListner
      );
    };
  }, []);

  // ====== Asking Request For Push Notification =====
  const sendPushPermissionRequest = () => {
    const webkit = (window as any).webkit;
    if (
      webkit &&
      webkit.messageHandlers &&
      webkit.messageHandlers['push-permission-request']
    ) {
      webkit.messageHandlers['push-permission-request'].postMessage(
        'push-permission-request'
      );
    }
  };

  return {
    sendPushPermissionRequest,
    permission,
  };
};

export default usePushPermissionRequest;
