// imports
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ToastContainer, Flip } from 'react-toastify';

// styles
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
// Required for Material UI's default typography
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import App from './App';

import { lightTheme } from './theme';

import { ContextProvider } from './context';
import { UserContextProvider } from './context/User';

// PWA Service Worker
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <ContextProvider>
        <UserContextProvider>
          <ToastContainer
            position="bottom-left"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
            transition={Flip}
          />
          <App />
        </UserContextProvider>
      </ContextProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);

// PWA Service Worker Registration
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
