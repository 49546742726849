import { IBundle } from 'models/IBundle';
import { Dispatch, SetStateAction } from 'react';

export type IAdminHeader = {
  setBundleDetailsTrigger: Dispatch<SetStateAction<boolean>>;
  setKeywordsTrigger: Dispatch<SetStateAction<boolean>>;
  setComponentsTrigger: Dispatch<SetStateAction<boolean>>;
  setBundleTitle: Dispatch<SetStateAction<string | undefined>>;
  bundle: IBundle | undefined;
  setCurrentBundle: Dispatch<SetStateAction<IBundle | undefined>>;
  mode: 1 | 2;
  setCurrentMode: Dispatch<SetStateAction<1 | 2>>;
  refetchAdminBundles: Function;
};

export const HEADER_TOOLTIP_TEXTS = {
  bundle_title:
    'The title for the multi-part activity experience. The formula consists of the Activity 1 title + Activity 2 title + the neighbourhood.',
};
