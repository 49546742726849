import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Container, Typography } from '@mui/material';

import Header from '../../components/Header';

import { buttonStyle, containerstyle, Content } from './styles';

const Success: React.FC = () => {
  const navigate = useNavigate();
  const handleBtnClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container sx={containerstyle}>
      <Header />
      <Content>
        <Typography variant="h6" textAlign="center">
          Congratulations! You're now a member of Tandem Experiences{' '}
          <b>Toronto</b>.
        </Typography>
        <Button onClick={handleBtnClick} sx={buttonStyle} variant="contained">
          Start browsing dates
        </Button>
      </Content>
    </Container>
  );
};

export default Success;
