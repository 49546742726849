import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";
import moment from 'moment';

import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DiscountIcon from '@mui/icons-material/Discount';

import { debounce } from 'throttle-debounce';

import { logEvent } from 'helpers/firebase';
import { searchBundles } from 'api';
import { ISearchResults } from 'models/ISearch';
import Calendar from './components/Calendar';
import SearchResults from './components/SearchResults';


// Debounce handling discussed in: https://www.developerway.com/posts/debouncing-in-react

interface SearchFunctionProps {
  searchTerm: string;
  selectedDate: moment.Moment | null;
  onlyDeals: boolean;
  setSearchResults: any;
}

const Search: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ISearchResults | null>(null);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [onlyDeals, setOnlyDeals] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const search = ({ searchTerm, selectedDate, onlyDeals, setSearchResults }: SearchFunctionProps) => {
    const q = searchTerm ? searchTerm.trim() : null;
    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;
    const deals = onlyDeals ? '1' : '0';
    if (q) {
      searchParams.set('q', q);
    } else {
      searchParams.delete('q');
    }
    if (date) {
      searchParams.set('date', date);
    } else {
      searchParams.delete('date');
    }
    if (onlyDeals) {
      searchParams.set('deals', deals);
    } else {
      searchParams.delete('deals');
    }
    setSearchParams(searchParams, { replace: true });
    if (!q && !selectedDate && !onlyDeals) {
      setSearchResults(null);
      setIsSearching(false);
    } else {
      setIsSearching(true);
      console.log(`DEBUG: Bundle search: q=${q} date=${date} deals:${deals}`);
      const searchApiParams = {
        q: q,
        date: date,
        deals: deals,
      };
      logEvent('search', searchApiParams);
      searchBundles(searchApiParams).then(response => {
        setSearchResults(response);
        setIsSearching(false);
      });
    }
  }

  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(300, search), [])

  useEffect(() => {
    if (searchTerm || selectedDate || onlyDeals) {
      // Immediately trigger loading state rather than waiting for debounce to
      // make sure it feels responsive
      setIsSearching(true);
    }
    debouncedSearch({ searchTerm, selectedDate, onlyDeals, setSearchResults })
  }, [searchTerm, selectedDate, onlyDeals, setSearchResults, debouncedSearch, setIsSearching])

  const handleClickDateChip = (event: any) => {
    setAnchorEl(event.currentTarget);
    setCalendarOpen(true);
  };

  const handleClickDealsChip = () => {
    setOnlyDeals(!onlyDeals);
  };

  const handleCloseDatePopover = () => {
    setAnchorEl(null);
    setCalendarOpen(false);
  };

  const handleSearchTermChange = (e: any) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  }

  const handleDateSelection = (selectedDate: moment.Moment) => {
    setCalendarOpen(false);
    setSelectedDate(selectedDate);
  };

  const handleDateRemove = () => {
    setSelectedDate(null);
  };

  const initSearchFromParams = () => {
    if (searchParams && searchParams.size > 0) {
      if (searchParams.has('q')) {
        setSearchTerm(searchParams.get('q') || '');
      }
      if (searchParams.has('date')) {
        setSelectedDate(moment(searchParams.get('date')));
      }
      if (searchParams.has('deals')) {
        setOnlyDeals(parseInt(searchParams.get('deals') || '0') === 1);
      }
    }
  }


  useEffect(() => {
    // Load state and perform initial search based on URL params
    initSearchFromParams();
    // eslint-disable-next-line
  }, [])

  return (
    <Container maxWidth="sm" disableGutters>
      <Stack
        alignItems="center"
        spacing={1}
        sx={{ padding: '20px 15px 0 15px' }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label=""
          value={searchTerm}
          onChange={handleSearchTermChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search experiences by title..."
        />
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Chip
            label={ selectedDate ? selectedDate.format("MMM DD") : "Dates" }
            icon={<CalendarTodayIcon fontSize="small" />}
            variant={ selectedDate ? "filled" : "outlined" }
            color="primary"
            onClick={ handleClickDateChip }
            onDelete={ selectedDate ? handleDateRemove : undefined }
          />
          <Chip
            label="Offers"
            icon={<DiscountIcon fontSize="small" />}
            variant={ onlyDeals ? "filled" : "outlined" }
            color="primary"
            onClick={ handleClickDealsChip }
          />
        </Stack>

        <Divider flexItem />

        <SearchResults results={searchResults} isLoading={isSearching} onDateSelected={handleDateSelection} />

        <Popover
          open={isCalendarOpen}
          anchorEl={anchorEl}
          onClose={handleCloseDatePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Calendar date={selectedDate} onDateSelected={handleDateSelection} />
        </Popover>
      </Stack>
    </Container>
  );
};

export default Search;
