import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from '@emotion/styled';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CrossIcon from 'assets/Cross';

const drawerBleeding = 56;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  zIndex: 9999,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

export default function IOSPwaPopup({ open, onOpen, onClose }: Props) {
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `190px`,
            overflow: 'visible',
          },
        }}
      />
      <Box sx={{ textAlign: 'center', pt: 1 }}></Box>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: 9999 }}
      >
        {open && (
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Box display="flex" justifyContent="center">
              <Typography sx={{ textAlign: 'center', mt: 4 }}>
                Add to Home Screen
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              position="relative"
              onClick={onClose}
            >
              <Box position="absolute" top="-24px" right="15px">
                <CrossIcon />
              </Box>
            </Box>
            <Typography sx={{ p: 2, color: 'black', mt: 2 }}>
              To install this app on your device, open in Safari, tap the Menu
              button, and then 'Add to Home screen' button.
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              gap="5px"
              justifyContent="center"
              alignItems="center"
              width="100%"
              position="relative"
              top="-10px"
            >
              <FileUploadOutlinedIcon fontSize="large" />
              <ArrowForwardIcon fontSize="large" />
              <AddBoxOutlinedIcon fontSize="large" />
            </Box>
          </StyledBox>
        )}
        <Box
          position="absolute"
          top="8px"
          width="100%"
          height="1px"
          borderTop="1px solid"
          borderColor="#D3D3D3"
        ></Box>
        <Box
          position="absolute"
          bottom="38px"
          width="100%"
          height="1px"
          borderTop="1px solid"
          borderColor="#D3D3D3"
        ></Box>
      </SwipeableDrawer>
    </Root>
  );
}
