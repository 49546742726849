import axios from "axios";

import {
  IRegisterUserProps,
  ISignInProps,
  IUpdateUserProps,
  IForgotPasswordProps,
  IResetPasswordProps,
  IUpdatePasswordProps,
  ICodeConfirmationProps,
  ICreateFeedbackProps,
  IAddPartnerProps,
  IGetPartnerProps,
  IRemovePartnerProps,
  ISetActiveConnection,
  ISetUserProfileImage,
  ISocialAuthProps,
  IUpdatePostalCodeProps,
  IUpdateDeviceKeys,
  ISendNotification,
  ISendNotificationToAllUser,
  IDeleteUserProps,
} from "hooks/api";
import { toast } from "react-toastify";
import { logEvent } from 'helpers/firebase';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const registerApi = async (data: IRegisterUserProps) => {
  return axios(`${BASE_URL}/api/user/create`, {
    method: "POST",
    data,
  }).then((response) => response);
};

export const registerSocialSignup = async (data: ISocialAuthProps) => {
  return axios(`${BASE_URL}/api/user/google-auth`, {
    method: "POST",
    data,
  }).then((response) => response);
};

export const socialSignin = async (data: ISocialAuthProps) => {
  return axios(`${BASE_URL}/api/user/google-auth`, {
    method: "POST",
    data,
  }).then((response) => response);
};

export const signInApi = async (data: ISignInProps) => {
  return axios(`${BASE_URL}/api/user/sign-in`, {
    method: "POST",
    data,
  }).then((response) => response);
};

export const deleteUserApi = async (deleteReasons: IDeleteUserProps) => {
  return axios(`${BASE_URL}/api/user/delete-user`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data: deleteReasons,
  }).then((response) => {
    logEvent('DeleteAccount', deleteReasons);
    return response;
  });
};

export const getUserApi = async (token?: string) => {
  return axios(`${BASE_URL}/api/user`, {
    headers: {
      Authorization: token ? token : localStorage.getItem("tandemToken") || "",
    },
  }).then((response) => response);
};

export const updateUserApi = (data: IUpdateUserProps) => {
  return axios(`${BASE_URL}/api/user`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "PUT",
    data,
  }).then((response) => response);
};

export const forgotPasswordApi = async (data: IForgotPasswordProps) => {
  return axios(`${BASE_URL}/api/user/forgot-password`, {
    method: "POST",
    data,
  }).then((response) => response);
};

export const resetPasswordApi = async (data: IResetPasswordProps) => {
  return axios(`${BASE_URL}/api/user/reset-password`, {
    method: "POST",
    data,
  }).then((response) => response);
};

export const updatePasswordApi = async (data: IUpdatePasswordProps) => {
  return axios(`${BASE_URL}/api/user/update-password`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "PUT",
    data,
  }).then((response) => response);
};

export const codeConfirmationApi = async (data: ICodeConfirmationProps) => {
  return axios(`${BASE_URL}/api/user/email-verify`, {
    method: "POST",
    data,
  }).then((response) => response);
};

export const createFeedbackApi = async (data: ICreateFeedbackProps) => {
  return axios(`${BASE_URL}/api/user/feedback`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const getBundleFeedbackApi = async (bundle_id: number) => {
  return axios(`${BASE_URL}/api/user/getTodayFeedback`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data: { bundle_id },
  }).then((response) => response);
};

export const addPartnerApi = async (data: IAddPartnerProps) => {
  return axios(`${BASE_URL}/api/user/connect-with-partner`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const addConnection = async (data: any) => {
  return axios(
    `${BASE_URL}/api/user/add-partner?token=${data?.token}&id=${data?.id}`,
    {
      method: "POST",
    }
  ).then((response) => response);
};

export const getPartners = async (data: IGetPartnerProps) => {
  return axios(`${BASE_URL}/api/user/get-partners`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const removePartner = async (data: IRemovePartnerProps) => {
  return axios(`${BASE_URL}/api/user/remove-partner`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => {
    if (response.status === 200) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.error);
    }
  });
};

export const getActiveConnection = async (data: IGetPartnerProps) => {
  return axios(`${BASE_URL}/api/user/get-active-connection`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const getActiveConnectionInfo = async (data: IGetPartnerProps) => {
  return axios(`${BASE_URL}/api/user/get-active-connection-info`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const setActiveConnection = async (data: ISetActiveConnection) => {
  return axios(`${BASE_URL}/api/user/set-active-connection`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const setUserProfileImage = async (data: ISetUserProfileImage) => {
  return axios(`${BASE_URL}/api/user/set-profile-image`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const getUserProfileImage = async (data: IGetPartnerProps) => {
  return axios(`${BASE_URL}/api/user/get-profile-image`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const updatePostalCodeApi = async (data: IUpdatePostalCodeProps) => {
  return axios(`${BASE_URL}/api/user/update-postal-code`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const updateDeviceKeysApi = async (data: IUpdateDeviceKeys) => {
  return axios(`${BASE_URL}/api/user/update-device-keys`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const sendNotificationApi = async (data: ISendNotification) => {
  return axios(`${BASE_URL}/api/user/test-notification`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};

export const sendNotificationToAllUserApi = async (
  data: ISendNotificationToAllUser
) => {
  return axios(`${BASE_URL}/api/user/send-to-all-user-notification`, {
    headers: {
      Authorization: localStorage.getItem("tandemToken") || "",
    },
    method: "POST",
    data,
  }).then((response) => response);
};
