import { useEffect } from "react";

export const useRedirectBrowser = () => {
  // ====== Receive Firebase Device Token ======
  useEffect(() => {
    const redirectBrowser = (data: any) => {
      if (data?.detail?.url) {
        const parts = data?.detail?.url.split("/");
        const pathAfterFirstSlash = parts.slice(3).join("/");
        window.location.href = "/" + pathAfterFirstSlash;
      }
    };
    window.addEventListener("redirect-browser", redirectBrowser);
    return () => {
      window.removeEventListener("redirect-browser", redirectBrowser);
    };
  }, []);
  return {};
};

export default useRedirectBrowser;
