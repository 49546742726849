import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { editButtonStyle } from '../styles';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { COLORS } from 'constants/colors';

const EditPassword = () => {
    const navigate = useNavigate();
    return (
        <div style={{ paddingLeft: '32px', paddingRight: '32px', paddingBottom: '5px', marginTop: 25 }}>
            <Button
              onClick={() => navigate('/update-password')}
              variant="outlined"
              size="large"
              sx={editButtonStyle}
              endIcon={<div style={{ color: COLORS.blue }}><ModeEditOutlinedIcon fontSize="small" /></div>}
            >
              <span style={{ fontWeight: 700 }}>Update Password</span>
            </Button>
        </div>
    )
}

export default EditPassword;