import { Box } from '@mui/material';
import CrossIcon from 'assets/Cross';
import { IOSSwitch } from './SwitchButton';
import moment from 'moment';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
type IConnectionBox = {
  user: any;
  handleRemovePartner: any;
  isActiveConnection: boolean;
  handleActiveConnection: any;
};

const iconStyle: any = {
  width: '44px',
  height: '44px',
  marginLeft: '14.65px',
  marginTop: '14px',
};

const ConnectionBox = ({
  user,
  handleRemovePartner,
  isActiveConnection = false,
  handleActiveConnection,
}: IConnectionBox) => {
  const connectionDate = `${moment(user?.connectionDate)?.format(
    'ddd. MMM D'
  )}`;

  return (
    <Box marginBottom="26px">
      <Box
        display="flex"
        justifyContent="flex-end"
        gap="5px"
        fontWeight="400"
        fontSize="10px"
        width="100%"
        marginBottom="9.68px"
        sx={{ cursor: 'pointer' }}
        onClick={() => handleRemovePartner(user?.partnerId)}
      >
        remove <CrossIcon width="6.42px" height="13px" />
      </Box>
      <Box
        color="#3381FF"
        border="1px solid #3381FF"
        width="100%"
        height="112.45px"
        borderRadius="10px"
      >
        <Box display="flex">
          {user?.profile_image_url ? (
            <img
              src={user.profile_image_url}
              alt="Profile"
              style={{
                ...iconStyle,
                borderRadius: 50,
              }}
            />
          ) : (
            <AccountCircleRoundedIcon
              sx={{
                ...iconStyle,
                color: 'gray',
              }}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            marginTop="9.4px"
            fontSize="14px"
          >
            <Box display="flex" marginLeft="15.65px" color="black">
              <Box fontWeight="bold">Name:</Box>
              <Box marginLeft="5px">
                {user?.first_name + ' ' + user?.last_name}
              </Box>
            </Box>
            <Box display="flex" marginLeft="15.65px" color="black">
              <Box fontWeight="bold">Email:</Box>
              <Box marginLeft="5px">{user?.email}</Box>
            </Box>
            <Box display="flex" marginLeft="15.65px" color="black">
              <Box fontWeight="bold">Connected since:</Box>
              <Box marginLeft="5px">{connectionDate}</Box>
            </Box>
          </Box>
        </Box>
        <Box
          color="black"
          height="32.8px"
          bgcolor="#D9D9D9"
          borderRadius="0px 0px 10px 10px"
          marginTop="5px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            paddingTop="4.43px"
          >
            <Box marginLeft="21px" fontWeight="bold">
              Combine preferences
            </Box>
            <Box marginRight="15px">
              <IOSSwitch
                checked={isActiveConnection}
                onChange={(e) => {
                  handleActiveConnection(user?.partnerId, e?.target?.checked);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ConnectionBox;
