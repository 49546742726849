/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useStyles from './BundleDetails.styles';

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import Card from 'components/Card/Card';

import {
  BUNDLE_DETAILS_TOOLTIP_TEXTS,
  IBundleDescription,
  IBundleDetails,
} from './BundleDetails.types';

const BundleDetails = ({
  mode,
  getBundleDetails,
  bundleDetailsTrigger,
  bundle,
}: IBundleDetails) => {
  const classes = useStyles();
  const [bundleDetails, setBundleDetails] = useState<any>({});

  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    property: string | undefined
  ) => {
    const value: boolean =
      (event.target as HTMLInputElement).value === '1' ? true : false;
    const newBundler = { ...bundleDetails };
    if (property) newBundler[property] = value;
    setBundleDetails({ ...newBundler });
  };

  useEffect(() => {
    if (!bundle) {
      setBundleDetails({
        bundle_image: '',
        bundle_summary: '',
        is_published: false,
        map_url: '',
        slug: '',
        bundle_video: '',
        duration: '',
        is_itinerary_created: false,
        is_sponsored: false,
        has_any_element_sold: false,
      });
      return;
    }
    setBundleDetails({
      bundle_image: bundle ? bundle.bundle_image : '',
      bundle_summary: bundle ? bundle.bundle_summary : '',
      is_published: bundle ? bundle.is_published : false,
      map_url: bundle ? bundle.map_url : '',
      slug: bundle ? bundle.slug : '',
      bundle_video: bundle ? bundle.bundle_video : '',
      duration: bundle ? bundle.duration : '',
      creation_credit: bundle ? bundle.creation_credit : '',
      is_itinerary_created: bundle ? bundle.is_itinerary_created : false,
      is_sponsored: bundle ? bundle.is_sponsored : false,
      has_any_element_sold: bundle ? bundle.has_any_element_sold : false,
    });
  }, [bundle]);

  useEffect(() => {
    if (bundleDetailsTrigger) {
      getBundleDetails(bundleDetails);
    }
  }, [bundleDetailsTrigger]);

  const BundleSummary = (summary: string) => {
    return (
      <Box className={classes.bundleMainContainer} key={'container'}>
        <Tooltip
          placement="top"
          arrow
          title={BUNDLE_DETAILS_TOOLTIP_TEXTS['summary'] ?? ''}
        >
          <Typography>Summary</Typography>
        </Tooltip>
        <Box
          className={`${classes.bundleDescriptionContainer} ${classes.bundleSummaryContainer}`}
        >
          <textarea
            key="summary"
            style={{
              width: '100%',
              height: '100%',
              fontFamily: 'inherit',
              fontSize: '14px',
              resize: 'none',
              wordBreak: 'break-word',
            }}
            value={bundleDetails.bundle_summary ?? ''}
            onChange={(e: any) =>
              setBundleDetails({
                ...bundleDetails,
                bundle_summary: e.target.value ?? '',
              })
            }
            placeholder={'Enter bundle summary'}
          />
        </Box>
      </Box>
    );
  };

  const BundleDescription = ({
    heading,
    text,
    property,
  }: IBundleDescription) => (
    <Box className={classes.bundleMainContainer}>
      <Tooltip
        placement="top"
        arrow
        title={BUNDLE_DETAILS_TOOLTIP_TEXTS[property ? property : ''] ?? ''}
      >
        <Typography>{heading}</Typography>
      </Tooltip>
      <input
        type={'text'}
        value={text ?? ''}
        style={{ width: '75%', fontFamily: 'inherit', fontSize: '14px' }}
        onChange={(e: any) => {
          if (property) {
            const bundleDetailsTemp = { ...bundleDetails };
            bundleDetailsTemp[property] = e.target.value;

            setBundleDetails(bundleDetailsTemp);
          }
        }}
        placeholder={`Enter ${property}`}
      />
    </Box>
  );

  const BundleIsPublished = ({
    heading,
    text,
    property,
  }: IBundleDescription) => (
    <Box className={classes.bundleIsPublishedContainer}>
      <Tooltip
        placement="top"
        arrow
        title={BUNDLE_DETAILS_TOOLTIP_TEXTS[property ? property : ''] ?? ''}
      >
        <Typography>{heading}</Typography>
      </Tooltip>

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="row-radio-buttons-group"
          onChange={(e) => {
            handleRadioButtonChange(e, property);
          }}
          row
        >
          <FormControlLabel
            value={'1'}
            checked={property && bundleDetails[property] ? true : false}
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 16,
                  },
                }}
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value={'2'}
            checked={
              property && bundleDetails && !bundleDetails[property]
                ? true
                : false
            }
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 16,
                  },
                }}
              />
            }
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );

  return (
    <div className={classes.container}>
      <Card overRideClass={classes.overRideCardContainer}>
        <Typography fontWeight={'bold'}>
          Bundle ID: {mode === 2 ? bundle?.id ?? 0 : 0}
        </Typography>
        <div className={classes.contentContainer}>
          {BundleSummary(
            'Enjoy brunch with live music and board games to boot at Bampot Tea House.'
          )}
          {BundleIsPublished({
            text: '',
            heading: 'Published?',
            property: 'is_published',
          })}
          {BundleDescription({
            text: bundleDetails.slug,
            heading: 'Slug',
            property: 'slug',
          })}
          {BundleDescription({
            text: bundleDetails.map_url,
            heading: 'Map',
            property: 'map_url',
          })}
          {BundleDescription({
            text: bundleDetails.bundle_video,
            heading: 'Video URL',
            property: 'bundle_video',
          })}
          {BundleDescription({
            text: bundleDetails.bundle_image,
            heading: 'Image URL',
            property: 'bundle_image',
          })}
          {BundleDescription({
            text: bundleDetails.creation_credit,
            heading: 'Creation Credit',
            property: 'creation_credit',
          })}
          {BundleIsPublished({
            text: '',
            heading: 'Tandem Exclusive?',
            property: 'is_itinerary_created',
          })}
          {BundleIsPublished({
            text: '',
            heading: 'Sponsored?',
            property: 'is_sponsored',
          })}
          {BundleIsPublished({
            text: '',
            heading: 'Sold out?',
            property: 'has_any_element_sold',
          })}
        </div>
      </Card>
    </div>
  );
};

export default BundleDetails;
