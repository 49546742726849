import React from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

interface Props {
  email: string;
  name: string;
}

const MAX_NAME_DISPLAY_LENGTH = 16;

const RecommendedTab: React.FC<Props> = ({ email, name }) => {

  const nameFormatted = name.length > MAX_NAME_DISPLAY_LENGTH ? name.substring(0, MAX_NAME_DISPLAY_LENGTH) + '…' : name;

  return (
    <div
      style={{
        width: '100%',
        height: '70px',
        background: 'linear-gradient(89.91deg, #071A3C 0.05%, #1B3461 99.91%)',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between', 
        zIndex: 1,
      }}
    >
      
      {/* Text Section */}
      <div style={{
        fontSize: '18px',
        color: '#FFF',
        textAlign: 'left',
        marginLeft: 20,
      }}>
        Tandem experiences for <b>{nameFormatted}</b><br />
      </div>
  
      <IconButton component={Link} to='/search' aria-label='search' sx={{ color: 'white', marginRight: 1 }}>
        <SearchIcon />
      </IconButton>
    </div>
  );
  
};

export default RecommendedTab;
