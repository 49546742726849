/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import { Box, Button, Container, Link, Typography } from '@mui/material';

import Input from 'components/Input';
import { PWD_VALID_REGEX } from 'utils/passwordRegex';
import { Form, inputContainerStyle, containerstyle } from './styles';
import HeaderImg from '../../components/Header/assets/header.png';
import ContinueWithApple from 'components/ContinueWithApple/ContinueWithApple';
// types
import { FormikErrorsType } from './types';

import { ISocialAuthProps, useRegisterUser, useSocialSignUp } from 'hooks/api';

import { Image, HeaderStyle } from 'components/Header/styles';
import QuizForm from './components/QuizForm/QuizForm';
import PWAForm from './components/PWAForm/PWAForm';

import EditPostalCode from 'pages/Signup/components/PostalCodeForm/EditPostalCode';
import { UserContext } from 'context/User';
import GoogleAuth from 'components/Auth/google/GoogleAuth';
import { logEvent } from 'helpers/firebase';

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const startSignup = searchParams.get('signup') || '';
  const { setUserState } = useContext(UserContext);
  const [tempState, setTempState] = useState<any>({});
  const [userStateTemp, setUserStateTemp] = useState<any>({});
  const [invitationData, setInvitationData] = useState<any>(null);

  //const { setUserState } = useContext(UserContext)
  const [step, setStep] = useState(1);
  const { token, id } = useParams();
  // Simple Sign up 1
  // Google Sign up 2
  // Apple Sign up 3
  const [signupType, setSignupType] = useState(1);

  const { mutateAsync } = useRegisterUser(setStep, invitationData);
  const { mutateAsync: socialSignUp } = useSocialSignUp(
    setStep,
    invitationData
  );

  const handleSubmitFormOne = (values: any) => {
    logEvent('sign_up', { method: 'password' });
    setTempState(values);
    setStep(2);
    logEvent('tutorial_begin');
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let code = params.get('code');
    const idt = params.get('idt');
    const firstName = params.get('firstName') || '';
    const lastName = params.get('lastName') || '';
    if (idt) {
      handleAppleSignup(idt, firstName, lastName);
    } else if (code && startSignup !== 'true') {
      handleGoogle({
        code: code,
      });
    }
  }, []);

  useEffect(() => {
    if (startSignup === 'true') {
      const params = new URLSearchParams(window.location.search);
      let accessToken = params.get('accessToken');
      if (accessToken) {
        const data = {
          accessToken,
        };
        handleGoogle(data);
      }
    }
  }, [startSignup]);

  const handleSubmitFormTwo = async (postalCode: string) => {
    const newTempState = { ...tempState, postal_code: postalCode };
    setTempState((prev: any) => newTempState);
    await mutateAsync({ user: newTempState, signupType }).then((data) => {
      const userTemp = {
        ...data.data?.user,
        password: data.data?.user?.password || 'Dummy123$',
      };

      const userStateObject = {
        ...userTemp,
        isAuthenticated: true,
        isInitialApiFetched: true,
        subscribedStatus: 'trial',
        daysLeft: userTemp?.trial_duration_days ?? 30,
      };
      setUserStateTemp(userStateObject);
    });
    setStep(3);
  };

  const handleSubmitFormThree = (completed: boolean = false) => {
    if (completed) {
      toast.success('Responses submitted');
    }
    setUserState(userStateTemp);
  };

  const handleAppleSignup = (
    idt: string,
    firstName: string,
    lastName: string
  ) => {
    logEvent('sign_up', { method: 'Apple' });
    setTempState({
      idt: idt,
      first_name: firstName,
      last_name: lastName,
      password: 'Dummy123$',
      email: 'email',
      postal_code: '',
    });
    setSignupType(3); // Apple sign up type
    setStep(2);
    logEvent('tutorial_begin');
  };
  const handleGoogle = async (data: any) => {
    if (data) {
      let userData = null;
      let token = null;
      let signupObject: ISocialAuthProps;
      if (data.accessToken) {
        signupObject = {
          accessToken: data.accessToken,
          code: '',
          isNew: true,
        };
      } else {
        signupObject = {
          code: data.code,
          isNew: true,
        };
      }
      const response = await socialSignUp(signupObject);
      userData = response?.data?.user;
      token = response?.data?.token;
      if (token || userData) {
        logEvent('sign_up', { method: 'Google' });
      }
      if (token) {
        localStorage.setItem('tandemToken', token);
        setUserState({
          ...userData,
          isAuthenticated: true,
          subscribedStatus: userData?.subscribedStatus,
          isInitialApiFetched: true,
          daysLeft: Math.ceil(userData.daysLeft),
        });
        return;
      }
      if (userData) {
        setTempState({
          first_name: userData.first_name,
          last_name: userData.last_name,
          password: userData.password || 'Dummy123$',
          email: userData.email,
          postal_code: '',
        });
        setStep(2);
        setSignupType(2);
        logEvent('tutorial_begin');
      }
    } else {
      toast.error('Some error occurred, please try again.');
    }
  };

  useLayoutEffect(() => {
    if (token && id) setInvitationData({ token, id });
  }, [token, id]);

  if ((step === 3 && !isMobile) || step === 4)
    return (
      <QuizForm
        handleSubmit={handleSubmitFormThree}
        userId={userStateTemp.id}
      />
    );
  if (step === 3 && isMobile) {
    return <PWAForm setStep={setStep} />;
  } else if (step === 2) {
    return (
      <EditPostalCode
        handleSubmit={handleSubmitFormTwo}
        mode={2}
        showNextButton={true}
      />
    );
  } else {
    // Show signup form
    return (
      <Container sx={containerstyle} maxWidth="sm">
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          marginTop="38px"
        >
          <Box sx={HeaderStyle}>
            <Image
              src={HeaderImg}
              alt="header"
              width="122px"
              style={{ height: '25px' }}
            />
          </Box>
        </Box>
        <Typography
          variant="body1"
          textAlign="center"
          fontWeight="400"
          fontSize="16px"
          color="#000"
          marginTop="20px"
        >
          Make planning your next date night a breeze. Signup for free today
        </Typography>
        <Formik
          enableReinitialize
          initialValues={{
            first_name: tempState?.first_name ?? '',
            last_name: tempState?.last_name ?? '',
            email: tempState?.email ?? '',
            password: tempState?.password ?? '',
            postal_code: '',
          }}
          validate={(values) => {
            const errors = {} as FormikErrorsType;
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (!values.first_name) {
              errors.first_name = 'Required';
            }
            if (!values.password) {
              errors.password = 'Required';
            } else if (!PWD_VALID_REGEX.test(values.password)) {
              errors.password =
                'Password length must be min 8 characters with at least 1 capital letter, 1 small letter and 1 digit';
            }
            return errors;
          }}
          onSubmit={handleSubmitFormOne}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form
              style={{ justifyContent: 'flex-start' }}
              onSubmit={handleSubmit}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                marginBottom="20px"
                gap="12px"
              >
                <GoogleAuth isSignIn={false} />
              </Box>
              <Box>
                <ContinueWithApple title="Sign up with Apple" />
              </Box>
              <Box
                marginBottom="20px"
                marginTop="30px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box flex="0.45" borderTop="1px solid #DCDCDC"></Box>
                <Box
                  flex="0.1"
                  fontWeight="700"
                  fontSize="12px"
                  textAlign="center"
                >
                  OR
                </Box>
                <Box flex="0.45" borderTop="1px solid #DCDCDC"></Box>
              </Box>
              <Input
                customlabel=""
                type="text"
                name="first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                // @ts-ignore
                formikerror={
                  errors.first_name && touched.first_name && errors.first_name
                }
                value={values.first_name}
                containerstyle={inputContainerStyle}
                placeholder="Your name"
              />
              <Input
                customlabel=""
                type="text"
                name="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                // @ts-ignore
                formikerror={
                  errors.last_name && touched.last_name && errors.last_name
                }
                value={values.last_name}
                containerstyle={inputContainerStyle}
                placeholder="Your last name"
              />
              <Input
                customlabel=""
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                // @ts-ignore
                formikerror={errors.email && touched.email && errors.email}
                value={values.email}
                containerstyle={inputContainerStyle}
                placeholder="Your email"
              />
              <Input
                customlabel=""
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                // @ts-ignore
                formikerror={
                  errors.password && touched.password && errors.password
                }
                value={values.password}
                containerstyle={inputContainerStyle}
                placeholder="Your password"
              />
              {/* <Input
              customlabel=""
              name="postal_code"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={postalCodeFormat(values.postal_code)}
              containerstyle={inputContainerStyle}
              placeholder="Your zip code"
            /> */}
              <Button
                sx={{ backgroundColor: '#3381FF !important', fontWeight: 900 }}
                type="submit"
                variant="contained"
              >
                GET STARTED FREE
              </Button>
              <Typography variant="body2" marginTop={1} fontSize="12px">
                By joining, you agree to abide by the Tandem Experiences{' '}
                <Link
                  href="https://www.tandemexperiences.com/terms-of-use"
                  target="_blank"
                  sx={{
                    color: '#000',
                    textDecoration: 'none',
                    fontWeight: 700,
                  }}
                >
                  terms and conditions
                </Link>{' '}
                and{' '}
                <Link
                  href="https://www.tandemexperiences.com/privacy-policy"
                  target="_blank"
                  sx={{
                    color: '#1B3461',
                    textDecoration: 'none',
                    fontWeight: 700,
                  }}
                >
                  privacy policy.
                </Link>
              </Typography>
              <Typography
                textAlign="center"
                variant="body2"
                marginTop={3}
                fontWeight="700"
                color="#000"
              >
                Already have an account?
                <Button
                  onClick={() => navigate('/')}
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: '700',
                    color: '#3381FF',
                  }}
                  variant="text"
                >
                  Sign In
                </Button>
              </Typography>
            </Form>
          )}
        </Formik>
      </Container>
    );
  }
};

export default Signup;
