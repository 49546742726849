/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import AdminContent from './components/AdminContent/AdminContent';
import AdminSidebar from './components/Sidebar/Sidebar';
import { useGetAdminBundles } from 'hooks/api';

import { IBundle } from 'models/IBundle';

const Admin = () => {
  const [currentBundle, setCurrentBundle] = useState<IBundle | undefined>();
  const { bundles, refetchAdminBundles } = useGetAdminBundles();

  // 1 for create, 2 for edit
  const [currentMode, setCurrentMode] = useState<1 | 2>(1);

  useEffect(() => {
    if (currentBundle) {
      setCurrentMode(2);
    }
  }, [currentBundle]);

  return (
    <Box height={'100%'} display={'flex'}>
      <AdminSidebar
        currentBundle={currentBundle}
        setCurrentBundle={setCurrentBundle}
        bundles={bundles}
        setCurrentMode={setCurrentMode}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        width={'calc(100% - 340px)'}
      >
        <AdminContent
          bundle={currentBundle}
          bundles={bundles}
          setCurrentBundle={setCurrentBundle}
          mode={currentMode}
          setCurrentMode={setCurrentMode}
          refetchAdminBundles={refetchAdminBundles}
        />
      </Box>
    </Box>
  );
};

export default Admin;
