/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

// context
import Context from 'context';

// components
import Input from 'components/Input';

// mui
import { Button, Container } from '@mui/material';

import { UserContext } from 'context/User';

import { useUpdatedUser } from 'hooks/api';

// styles
import { ContainerStyle, InputContainerStyle, Form } from './styles';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';

const EditProfile: React.FC = () => {
  const navigate = useNavigate();
  const updateUser = useUpdatedUser();
  const { userState } = useContext(UserContext);
  const [values] = useState({ ...userState });

  return (
    <div>
      {/* <AuthenticatedHeader back label="My Profile" /> */}
      <div
        onClick={() => navigate(-1)}
        style={{
          position: 'absolute',
          width: '32.5px',
          height: '32.5px',
          borderRadius: '50%',
          backgroundColor: '#1B3461',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          cursor: 'pointer',
          marginTop: '5%',
          marginLeft: '5%',
        }}
      >
        <ArrowBackIosNew fontSize="small" />
      </div>
      <Container sx={ContainerStyle}>
        <Formik
          initialValues={{
            first_name: values.first_name,
            last_name: values.last_name ?? '',
            email: values.email,
            birth_date: values.birth_date ?? '',
          }}
          validate={(values) => {
            const errors = {} as any;
            if (values.first_name === '') {
              errors.first_name = 'Required';
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { email, ...updatedFields } = values;
            let fields: any = { ...updatedFields };
            if (email !== userState.email) {
              fields = { ...fields, email };
            }
            // Force empty birth date to be proper form for backend
            if (fields.birth_date === '') {
              fields.birth_date = null;
            }
            await updateUser.updateUserApiHook(fields);
            setSubmitting(false);
            toast.success('profile updated');
            navigate('/profile');
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Input
                customlabel="First Name"
                type="text"
                name="first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                formikerror={
                  errors.first_name && touched.first_name && errors.first_name
                }
                value={values.first_name}
                containerstyle={InputContainerStyle}
                placeholder="Your name"
              />
              <Input
                customlabel="Last Name"
                type="text"
                name="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                formikerror={
                  errors.last_name && touched.last_name && errors.last_name
                }
                value={values.last_name}
                containerstyle={InputContainerStyle}
                placeholder="Your last name"
              />
              <Input
                customlabel="Email"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                formikerror={errors.email && touched.email && errors.email}
                value={values.email}
                containerstyle={InputContainerStyle}
                placeholder="Your email"
              />
              <Input
                customlabel="Birth Date"
                type="date"
                name="birth_date"
                onChange={handleChange}
                onBlur={handleBlur}
                formikerror={
                  errors.birth_date && touched.birth_date && errors.birth_date
                }
                value={values.birth_date}
                containerstyle={InputContainerStyle}
                placeholder="Your birth date"
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                sx={{ width: '100%' }}
              >
                Update Account
              </Button>
            </Form>
          )}
        </Formik>
        {/* <DateStyle>
          <Typography variant="subtitle2">Set your date style</Typography>
          <Button size="small" variant="contained">
            Complete
          </Button>
        </DateStyle> */}
      </Container>
    </div>
  );
};

export default EditProfile;
