import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Grid, { GridProps } from '@mui/material/Grid';

import Chip from '@mui/material/Chip';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/styles';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import LocationIcon from '@mui/icons-material/PlaceOutlined';
import PriceIcon from '@mui/icons-material/MonetizationOnOutlined';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import MenuIcon from '@mui/icons-material/MenuBookOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { COLORS } from 'constants/colors';
import { costRangeToString } from 'utils/bundle';

import RestaurantIcon from '@mui/icons-material/Restaurant';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';


interface Props {
  children?: React.ReactNode;
  item: any;
  stepLabel: string;
}

const OfferAlert = styled(Alert)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#9C27B0',
  backgroundColor: 'white',
  borderColor: '#9C27B0',
  "& .MuiAlert-icon": {
    color: '#9C27B0',
  }
}))

// PropertyLabel and PropertyText are used to lay out the component properties as a grid
const PropertyLabel: React.FC<GridProps> = styled(({ children, item=true, xs=2, ...rest }) => {
  return (
    <Grid item={item} xs={xs} {...rest}>
      {children}
    </Grid>
  )
})(({ theme }) => ({
  textAlign: 'right',
}));
const PropertyText: React.FC<GridProps> = styled(({ children, item=true, xs=10, ...rest }) => {
  return (
    <Grid item={item} xs={xs} {...rest}>
      {children}
    </Grid>
  )
})(({ theme }) => ({
  fontSize: '0.9rem',
}));

const ItineraryItem: React.FC<Props> = ({
  item,
  stepLabel,
}) => {
  const [open, setOpen] = useState(false);

  if (!item) {
    return null;
  }

  let icon = null;
  if (item.component_type === 'Food and Drink') {
    icon = (<RestaurantIcon sx={{ fontSize: 20 }} />);
  } else if (item.component_type === 'Landmark') {
    icon = (<PhotoCameraOutlinedIcon sx={{ fontSize: 20 }} />);
  } else if (item.component_type === 'Activity') {
    icon = (<LocalActivityOutlinedIcon sx={{ fontSize: 22 }} />);
  }

  const lowCost = parseInt(item['cost_per_person_low']);
  const highCost = parseInt(item['cost_per_person_high']);
  const cost = costRangeToString(lowCost, highCost);

  const handleExpand = () => {
    return setOpen((previous) => !previous);
  };

  return (
    <Card square>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: COLORS.darkBlue, width: 30, height: 30 }}>
            {stepLabel}
          </Avatar>
        }
        subheader={<strong>{item.component_title || item.component_name}</strong>}
        title={
          <Stack
            direction='row'
            alignItems="center"
            style={{ color: COLORS.blue }}
          >
            {icon}
            <span style={{ marginLeft: '5px' }}>{item.component_type}</span>
            <Stack
              direction='row'
              alignItems='center'
              // Right align (requires parent to not have 'spacing' prop set)
              sx={{ marginLeft: 'auto' }}
            >
              <TimerOutlinedIcon sx={{ fontSize: 16 }} />
              <span>{Number(item.duration)} <small>min</small></span>
            </Stack>
          </Stack>
        }
        action={
          <IconButton>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        }
        onClick={handleExpand}
      />
      <Collapse in={open}>
        <CardMedia
          component="img"
          width="100%"
          height="147px"
          image={item.image_url}
        />
        <CardContent>
          <Typography variant="h6" mb={2}>
            {item.venue.venue_name}
          </Typography>

          <Grid container spacing={1}>

            {item.component_description && (
              <>
              <PropertyLabel>
                <InfoIcon />
              </PropertyLabel>
              <PropertyText>
                {item.component_description}
              </PropertyText>
              </>
            )}

            {cost && (
              <>
              <PropertyLabel>
                <PriceIcon />
              </PropertyLabel>
              <PropertyText>
                {cost}
              </PropertyText>
              </>
            )}

            {item.venue && item.venue.venue_address && (
              <>
              <PropertyLabel>
                <LocationIcon />
              </PropertyLabel>
              <PropertyText>
                {item.venue.venue_address}
              </PropertyText>
              </>
            )}

            {item.website_url && (
              <>
              <PropertyLabel>
                <LinkIcon />
              </PropertyLabel>
              <PropertyText>
                <Link to={item.website_url} target="_blank" rel="noopener">Website</Link>
              </PropertyText>
              </>
            )}

            {item.venue && item.venue.menu_url && (
              <>
                <PropertyLabel>
                  <MenuIcon />
                </PropertyLabel>
                <PropertyText>
                  <Link to={item.venue.menu_url} target="_blank" rel="noopener">Menu</Link>
                </PropertyText>
              </>
            )}

            <PropertyLabel>
              <TicketIcon />
            </PropertyLabel>
            <PropertyText>
              {item.reservation && item.reservation.is_reservation_available
                    ? (item.reservation.reservation_description || 'Reservation suggested')
                    : 'No reservation required'}
            </PropertyText>
          </Grid>

          {item.member_offer && (
            <OfferAlert
              icon={<LocalOfferIcon fontSize="inherit" />}
              severity="info"
              variant="outlined"
              sx={{ mt: 2 }}
              >
                <AlertTitle>Offer</AlertTitle>
                {item.member_offer}
            </OfferAlert>
          )}

        </CardContent>
      </Collapse>
      <CardActions>
        {(item.reservation && item.reservation.is_reservation_available) ? (
          <Button size="small" variant="contained" component={Link} to={item.reservation.booking_url} target="_blank" rel="noopener">{item.reservation.reservation_link_title}</Button>
        ) : (
          <Button size="small" variant="outlined" onClick={handleExpand}>See details</Button>
        )}

        {/* Tags right-aligned */}
        {item.member_offer && (
          <div style={{ marginLeft: 'auto' }}>
            <Chip icon={<LocalOfferIcon sx={{ fontSize: '10px' }} />} color="secondary" label="Offer" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
          </div>
        )}
      </CardActions>
    </Card>
  );
};

export default ItineraryItem;
