/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import {
  COMPONENT_TOOLTIP_TEXTS,
  IComponentSection,
} from './ComponentSection.types';
import { IBundleDescription } from '../../BundleDetails/BundleDetails.types';

import { COLORS } from 'constants/colors';
import { IBundleComponent } from 'models/IBundleComponent';
import Tooltip from '@mui/material/Tooltip';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const buttonStyle: any = {
  fontWeight: 'bold',
  color: COLORS.white,
  backgroundColor: COLORS.blue,
  border: 'none',
  borderRadius: 5,
  padding: '5px 10px',
  cursor: 'pointer',
  fontFamily: 'inherit',
};

const ComponentSection = ({
  name,
  getComponents,
  componentsTrigger,
  components,
  setComponents,
  activeComponentIndex,
  setActiveComponentIndex,
}: IComponentSection) => {
  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value: boolean =
      (event.target as HTMLInputElement).value === '1' ? true : false;
    const componentTemp: IBundleComponent = {
      ...components[activeComponentIndex],
    };

    componentTemp.reservation.is_reservation_available = value;
    const newComponentObject: any = {};
    newComponentObject[activeComponentIndex] = componentTemp;
    setComponents({
      ...components,
      ...newComponentObject,
    });
  };

  const BundleDescription = ({
    heading,
    text,
    property,
  }: IBundleDescription) => (
    <Box display={'flex'} justifyContent={'space-between'} maxHeight={'50px'}>
      <Tooltip
        title={COMPONENT_TOOLTIP_TEXTS[property ? property : ''] ?? ''}
        arrow
        placement="top"
      >
        <Typography fontSize={'12px'}>{heading}</Typography>
      </Tooltip>
      <Box border={`1px solid ${COLORS.lightGrey}`} width={'78%'}>
        <input
          type={'text'}
          placeholder={`Enter ${property}`}
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'inherit',
            fontSize: '14px',
          }}
          value={text}
          onChange={(e: any) => {
            if (property) {
              const componentTemp: any = {
                ...components[activeComponentIndex],
              };
              componentTemp[property] = e.target.value;
              const newComponentObject: any = {};
              newComponentObject[activeComponentIndex] = componentTemp;
              setComponents({
                ...components,
                ...newComponentObject,
              });
            }
          }}
        />
      </Box>
    </Box>
  );

  const BundleDuration = ({ heading, text, property }: IBundleDescription) => (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      maxHeight={'50px'}
      height={'24px'}
      overflow={'hidden'}
      whiteSpace={'nowrap'}
    >
      <Tooltip
        title={COMPONENT_TOOLTIP_TEXTS[property ? property : ''] ?? ''}
        arrow
        placement="top"
      >
        <Typography
          fontSize={'12px'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          width={'90px'}
        >
          {heading}
        </Typography>
      </Tooltip>
      <Box border={`1px solid ${COLORS.lightGrey}`} width={'78%'}>
        <input
          type={'number'}
          placeholder={`Enter ${property}`}
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'inherit',
            fontSize: '14px',
          }}
          value={text}
          onChange={(e: any) => {
            if (property) {
              const componentTemp: any = {
                ...components[activeComponentIndex],
              };
              componentTemp[property] = e.target.value;
              const newComponentObject: any = {};
              newComponentObject[activeComponentIndex] = componentTemp;
              setComponents({
                ...components,
                ...newComponentObject,
              });
            }
          }}
        />
      </Box>
    </Box>
  );

  const BundleVenue = ({ heading, text, property }: IBundleDescription) => (
    <Box display={'flex'} justifyContent={'space-between'} maxHeight={'50px'}>
      <Tooltip
        title={COMPONENT_TOOLTIP_TEXTS[property ? property : ''] ?? ''}
        arrow
        placement="top"
      >
        <Typography fontSize={'12px'}>{heading}</Typography>
      </Tooltip>
      <Box border={`1px solid ${COLORS.lightGrey}`} width={'78%'}>
        <input
          type={'text'}
          placeholder={`Enter ${property}`}
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'inherit',
            fontSize: '14px',
          }}
          value={text}
          onChange={(e: any) => {
            if (property) {
              const componentTemp: any = {
                ...components[activeComponentIndex],
              };
              componentTemp.venue[property] = e.target.value;
              const newComponentObject: any = {};
              newComponentObject[activeComponentIndex] = componentTemp;
              setComponents({
                ...components,
                ...newComponentObject,
              });
            }
          }}
        />
      </Box>
    </Box>
  );

  const BundleReservation = ({
    heading,
    text,
    property,
  }: IBundleDescription) => (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      maxHeight={'50px'}
      height={'24px'}
      overflow={'hidden'}
      whiteSpace={'nowrap'}
    >
      <Tooltip
        title={COMPONENT_TOOLTIP_TEXTS[property ? property : ''] ?? ''}
        arrow
        placement="top"
      >
        <Typography
          fontSize={'12px'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          width={'90px'}
        >
          {heading}
        </Typography>
      </Tooltip>
      <Box border={`1px solid ${COLORS.lightGrey}`} width={'78%'}>
        <input
          type={'text'}
          placeholder={`Enter ${property}`}
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'inherit',
            fontSize: '14px',
          }}
          value={text}
          onChange={(e: any) => {
            if (property) {
              const componentTemp: any = {
                ...components[activeComponentIndex],
              };
              componentTemp.reservation[property] = e.target.value;
              const newComponentObject: any = {};
              newComponentObject[activeComponentIndex] = componentTemp;
              setComponents({
                ...components,
                ...newComponentObject,
              });
            }
          }}
        />
      </Box>
    </Box>
  );

  const BundleIsReservation = ({
    heading,
    text,
    property,
  }: IBundleDescription) => (
    <Box display={'flex'} gap={'5vw'} maxHeight={'50px'} alignItems={'center'}>
      <Tooltip
        title={COMPONENT_TOOLTIP_TEXTS[property ?? ''] ?? ''}
        arrow
        placement="top"
      >
        <Typography fontSize={'12px'}>{heading}</Typography>
      </Tooltip>

      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="row-radio-buttons-group"
          onChange={handleRadioButtonChange}
        >
          <FormControlLabel
            value={'1'}
            checked={
              components[activeComponentIndex]?.reservation
                .is_reservation_available
            }
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 16,
                  },
                }}
              />
            }
            label="Yes"
          />
          <FormControlLabel
            value={'2'}
            checked={
              !components[activeComponentIndex]?.reservation
                .is_reservation_available
            }
            control={
              <Radio
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 16,
                  },
                }}
              />
            }
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );

  const BundleSummary = ({ heading, text, property }: IBundleDescription) => (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      maxHeight={'80px'}
      overflow={'hidden'}
      whiteSpace={'normal'}
    >
      <Tooltip
        title={COMPONENT_TOOLTIP_TEXTS['component_description'] ?? ''}
        arrow
        placement="top"
      >
        <Typography fontSize={'12px'}>{heading}</Typography>
      </Tooltip>
      <Box border={`1px solid ${COLORS.lightGrey}`} width={'78%'}>
        <textarea
          key="component_summary"
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'inherit',
            fontSize: '14px',
            resize: 'none',
          }}
          placeholder={'Enter bundle summary'}
          value={text}
          onChange={(e: any) => {
            const componentTemp: any = {
              ...components[activeComponentIndex],
            };
            componentTemp.component_description = e.target.value;
            const newComponentObject: any = {};
            newComponentObject[activeComponentIndex] = componentTemp;
            setComponents({
              ...components,
              ...newComponentObject,
            });
          }}
        />
      </Box>
    </Box>
  );

  const Components = () => (
    <Box
      marginTop={'2%'}
      color="black"
      padding="0% 0% 0% 2%"
      display={'flex'}
      flexDirection={'column'}
      gap={'2px'}
    >
      {BundleDescription({
        property: 'component_title',
        heading: 'Title',
        text: components[activeComponentIndex]?.component_title ?? '',
      })}
      {BundleDescription({
        property: 'component_type',
        heading: 'Type',
        text: components[activeComponentIndex]?.component_type ?? '',
      })}
      {BundleSummary({
        heading: 'Description',
        text: components[activeComponentIndex]?.component_description ?? '',
      })}
      {BundleVenue({
        property: 'venue_name',
        heading: 'Venue',
        text: components[activeComponentIndex]?.venue?.venue_name ?? '',
      })}
      {BundleVenue({
        property: 'venue_address',
        heading: 'Address',
        text: components[activeComponentIndex]?.venue?.venue_address ?? '',
      })}
      {BundleIsReservation({
        property: 'is_reservation_available',
        heading: 'Reservation?',
        text: '',
      })}
      {BundleReservation({
        property: 'booking_url',
        heading: 'Booking URL',
        text: components[activeComponentIndex]?.reservation?.booking_url ?? '',
      })}
      {BundleReservation({
        property: 'reservation_description',
        heading: 'Reservation Description',
        text:
          components[activeComponentIndex]?.reservation
            .reservation_description ?? '',
      })}
      {BundleReservation({
        property: 'reservation_link_title',
        heading: 'Reservation Text',
        text:
          components[activeComponentIndex]?.reservation
            .reservation_link_title ?? '',
      })}
      {BundleDescription({
        property: 'member_offer',
        heading: 'Member offer',
        text: components[activeComponentIndex]?.member_offer ?? '',
      })}
      {BundleDescription({
        property: 'website_url',
        heading: 'Website URL',
        text: components[activeComponentIndex]?.website_url ?? '',
      })}
      {BundleVenue({
        property: 'menu_url',
        heading: 'Menu Url',
        text: components[activeComponentIndex]?.venue?.menu_url ?? '',
      })}
      {BundleDescription({
        property: 'image_url',
        heading: 'Image URL',
        text: components[activeComponentIndex]?.image_url ?? '',
      })}
      {BundleDescription({
        property: 'cost_per_person_low',
        heading: 'Cost per person low',
        text: components[activeComponentIndex]?.cost_per_person_low ?? '',
      })}
      {BundleDescription({
        property: 'cost_per_person_high',
        heading: 'Cost per person high',
        text: components[activeComponentIndex]?.cost_per_person_high ?? '',
      })}
      {BundleDuration({
        property: 'duration',
        heading: 'Duration (minutes)',
        text: components[activeComponentIndex]?.duration ?? '',
      })}

      {/* <BundleDescription heading={'Distance to next'} text={'0:00 hour(s)'} />
      <BundleDescription heading={'Transporation'} text={'Walk'} /> */}
    </Box>
  );

  useEffect(() => {
    getComponents(components);
  }, [componentsTrigger]);

  const handleLeftArrow = () => {
    if (activeComponentIndex !== '0') {
      setActiveComponentIndex((prevState: string) => {
        const prevIndex: number = parseInt(prevState);
        const nextIndexNumber: number = prevIndex - 1;
        const newIndex: string = nextIndexNumber.toString();

        return newIndex;
      });
    }
  };

  const handleRightArrow = () => {
    if (parseInt(activeComponentIndex) !== Object.keys(components).length - 1) {
      setActiveComponentIndex((prevState: string) => {
        const prevIndex: number = parseInt(prevState);
        const nextIndexNumber: number = prevIndex + 1;
        const newIndex: string = nextIndexNumber.toString();

        return newIndex;
      });
    }
  };

  const getNextIndex = (type: number) => {
    let currentIndexInt: number = parseInt(activeComponentIndex);
    if (type === 1) {
      if (currentIndexInt !== 0) {
        currentIndexInt -= 1;
      }
    } else {
      if (currentIndexInt !== Object.keys(components).length - 1) {
        currentIndexInt += 1;
      }
    }
    return currentIndexInt;
  };

  const replaceComponent = (
    nextIndex: number,
    nextComponent: IBundleComponent
  ) => {
    const tempComponentsObject: any = { ...components };
    const currentComponent: IBundleComponent = components[activeComponentIndex];
    tempComponentsObject[activeComponentIndex] = nextComponent;
    tempComponentsObject[nextIndex] = currentComponent;
    setComponents(tempComponentsObject);
    setActiveComponentIndex(nextIndex.toString());
  };

  const moveLeft = () => {
    const nextIndex: number = getNextIndex(1);
    const nextComponent: IBundleComponent = components[nextIndex.toString()];
    if (nextIndex.toString() !== activeComponentIndex) {
      replaceComponent(nextIndex, nextComponent);
    }
  };

  const moveRight = () => {
    const nextIndex: number = getNextIndex(2);
    const nextComponent: IBundleComponent = components[nextIndex.toString()];
    if (nextIndex.toString() !== activeComponentIndex) {
      replaceComponent(nextIndex, nextComponent);
    }
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} color="black">
        <Box display={'flex'}>
          <ArrowLeftIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={handleLeftArrow}
          />
          <Typography fontWeight={'bold'} color={COLORS.blue}>
            {name}
          </Typography>
          <ArrowRightIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={handleRightArrow}
          />
        </Box>
        <Box display={'flex'}>
          <button
            style={{ ...buttonStyle, marginRight: '8px' }}
            onClick={moveLeft}
          >
            Move Left
          </button>
          <button style={buttonStyle} onClick={moveRight}>
            Move Right
          </button>
        </Box>
      </Box>
      {Components()}
    </Box>
  );
};

export default ComponentSection;
