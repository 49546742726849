import { Box, Typography, Link } from '@mui/material';
import { COLORS } from 'constants/colors';
import React, { useState } from 'react';
import DeleteAccountConfirmDrawer from './DeleteAccountDrawer';

interface Props {
  children?: React.ReactNode;
  email: string;
}

const DeleteAccount: React.FC<Props> = ({ email }) => {
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false);
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
      border={`1px solid ${COLORS.blue}`}
      borderRadius="10px"
      marginX="32px"
      paddingX="20px"
      paddingY="20px"
    >
      <Typography fontSize="16px" lineHeight="19px" fontWeight="700">
        Delete Account
      </Typography>

      <Typography fontSize="12px" lineHeight="14px">
        <Link
          component='button'
          onClick={() => setConfirmDeleteAccount(true)}
        >
          Permanently delete my account
        </Link>
      </Typography>
      {confirmDeleteAccount && (
        <DeleteAccountConfirmDrawer
          open={confirmDeleteAccount}
          setOpen={setConfirmDeleteAccount}
          email={email}
        />
      )}
    </Box>
  );
};

export default DeleteAccount;
