/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import Context from 'context';

import { useGetBundle } from 'hooks/api';

import BackFab from 'components/BackFab';

import ShareSection from './components/ShareSection';
import ImageSection from './components/ImageSection/ImageSection';
import WishlistSection from './components/WishlistSection/WishlistSection';
import SummarySection from './components/SummarySection/SummarySection';
import JourneySection from './components/JourneySection/JourneySection';
import ItineraryComponent from './components/Itinerary';
import CalendarSection from './components/CalendarSection';

const BundleDetail: React.FC<any> = () => {
  const { setShowModal } = useContext(Context);

  const { slug } = useParams();

  const { data, isLoading } = useGetBundle(slug);
  const bundle = data?.data?.bundle;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const logisticsFlag = queryParams.get('logistics');
    if (logisticsFlag) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }, []);

  if (!slug) {
    // TODO: redirect to error page?
    console.log('BundleDetail: missing slug');
    return null;
  }

  return (
    <Container maxWidth="sm" disableGutters sx={{ position: 'relative' }}>
      <BackFab />
      {(isLoading || !bundle) ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" height="234px" />
      ) : (
        <ImageSection bundle={bundle} />
      )}
      {(isLoading || !bundle) ? (
        <Stack direction="row" spacing={1} m={1} justifyContent="space-around">
          <Skeleton animation="wave" variant="rounded" width="100px" height="40px" />
          <Skeleton animation="wave" variant="rounded" width="100px" height="40px" />
          <Skeleton animation="wave" variant="rounded" width="100px" height="40px" />
        </Stack>
      ) : (
        <WishlistSection bundle={bundle} />
      )}
      <SummarySection bundle={bundle} />
      {(isLoading || !bundle) ? (
        <>
        <Skeleton animation="wave" variant="rounded" sx={{ margin: 2 }} height="100px" />
        <Skeleton animation="wave" variant="rounded" sx={{ margin: 2 }} height="100px" />
        </>
      ) : (
        <ItineraryComponent bundle={bundle} />
      )}
      {bundle && (
        <>
          <CalendarSection bundle={bundle} />
          <JourneySection bundle={bundle} />
          <ShareSection bundle={bundle} />
        </>
      )}
    </Container>
  );
};

export default BundleDetail;
