import { useState } from 'react';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';

import Input from 'components/Input';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';

import { useUpdatePassword } from 'hooks/api';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  paper: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    padding: '32px 24px',
    paddingTop: '20px',
    boxShadow: '0px 5px 18px rgba(6, 16, 55, 0.02)',
  },
  avatar: {
    margin: 1,
    marginBottom: 10,
  },
  form: {
    width: '100%',
    marginTop: 10,
  },
  submit: {
    margin: 3,
  },
  textField: {
    paddingBottom: 20,
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabled: {
    backgroundColor: 'grey !important',
  },
});

const PWD_VALID_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export default function ResetPassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConformPassword] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();

  const { updatePassword } = useUpdatePassword();

  const onSubmit = (event) => {
    event.preventDefault();
    updatePassword({ password: currentPassword, updatedPassword: password });
  };

  const handleCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConformPassword(event.target.value);
  };

  return (
    <>
      {/*  label="Update Password" /> */}
      <div
        onClick={() => navigate(-1)}
        style={{
          width: '32.5px',
          height: '32.5px',
          borderRadius: '50%',
          backgroundColor: '#1B3461',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          cursor: 'pointer',
          marginTop: '5%',
          marginLeft: '5%',
        }}
      >
        <ArrowBackIosNew fontSize="small" />
      </div>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" gutterBottom>
          Update Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <Input
            {...{
              value: currentPassword,
              onChange: handleCurrentPassword,
              fieldName: 'currentPassword',
              required: true,
              placeholder: 'Current Password',
              type: 'password',
              containerstyle: { width: '100%' },
            }}
          />
          <Input
            {...{
              value: password,
              onChange: handlePassword,
              fieldName: 'password',
              required: true,
              placeholder: 'New Password',
              type: 'password',
              containerstyle: { width: '100%' },
            }}
          />
          <Input
            {...{
              value: confirmPassword,
              onChange: handleConfirmPassword,
              fieldName: 'confirmPassword',
              required: true,
              placeholder: 'Confirm password',
              type: 'password',
              containerstyle: { width: '100%', marginBottom: '20px' },
            }}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            className={clsx({
              [classes.submit]: true,
              [classes.disabled]: !password?.match(PWD_VALID_REGEX),
            })}
            disabled={
              !(
                password?.match(PWD_VALID_REGEX) &&
                password === confirmPassword &&
                currentPassword.match(PWD_VALID_REGEX)
              )
            }
            title="Update Password"
          >
            Update Password
          </LoadingButton>
        </form>
      </div>
    </>
  );
}
