import { Modal, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import TandemLogo from 'assets/tandem-logo';

import React, { useState } from 'react';
import CrossIcon from 'assets/Cross';
import { toast } from 'react-toastify';
import { isIOS } from 'utils/isIos';
import IOSPwaPopup from './IOSPwaPopup';
import { COLORS } from 'constants/colors';

import { isAndroid } from 'react-device-detect';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

interface PopupProps {
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: '#FFFFFF',
  borderRadius: '20px',
  height: '90%',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const buttonStyle = {
  backgroundColor: COLORS.darkBlue,
  borderRadius: '5px',
  marginTop: '25px',
  width: '268px',
  fontWeight: '900',
  fontSize: '13px',
  '&&.MuiButton-root': {
    textTransform: 'unset',
  }
};

const logoContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: isAndroid ? 'space-between' : '',
  height: isAndroid ? '100%' : '30%'
}

const skipContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: '5px',
  position: 'relative',
  left: '114px'
}

const nextContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '66%'
}

const IOSNextButtonStyle = {
  backgroundColor: `${COLORS.blue} !important`,
  width: '101.15px', 
  fontWeight: '900',
  paddingTop: '11px',
  paddingBottom: '11px',
  fontSize: '13px',
  '&&.MuiButton-root': {
    textTransform: 'unset',
  }
}

const IOSContainerStyle = {
  height: '70%'
}

let deferredPrompt: any;
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
});

const InstallPWAPopup: React.FC<PopupProps> = ({
  open = false,
  handleClose,
}) => {
  const [iosOpen, setIosOpen] = useState(false);
  const installPwaPrompt = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        deferredPrompt = null;
        handleClose()
      }
    } else {
      toast.dismiss();
      toast.info(
        "Seems like you already installed the app. If you still can't see the app on your home screen please contact us."
      );
    }
  };

  return (
    <>
    {open ? <IOSPwaPopup
        open={iosOpen && isIOS()}
        onOpen={() => setIosOpen(true)}
        onClose={() => setIosOpen(false)}
      />: null}
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box onClick={handleClose} position="absolute" right="20px" top="20px">
          <CrossIcon />
        </Box>
        <Typography
          marginTop="7.1px"
          textAlign="center"
          fontWeight={700}
          fontSize="24px"
          color={COLORS.blue}
        >
          Get the App
        </Typography>
        <Typography
          marginTop="14.41px"
          textAlign="center"
          fontWeight={400}
          fontSize="16px"
          color="#000000"
          marginBottom={'20px'}
        >
          Date night made easy, right from your homescreen.
        </Typography>
        <Box sx={logoContainerStyle}>
          <div>
            <TandemLogo width={138} height={138} />
          </div>
          {isAndroid &&
          <Button onClick={isIOS() ? () => setIosOpen(true) :installPwaPrompt} variant="contained" sx={buttonStyle}>
            Install the App
          </Button>
          }
        </Box>
        {isAndroid &&
        <Box sx={skipContainerStyle}>
          <Typography
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            variant="body2"
            color="rgba(122, 122, 122, 1)"
            fontSize="14px"
            fontWeight="700"
            onClick={() => handleClose()}
          >
            skip
          </Typography>
        </Box>
        }
        {
          !isAndroid && 
          <Box sx={IOSContainerStyle}>
            <Box
              display="flex"
              flexDirection="row"
              gap="5px"
              justifyContent="center"
              alignItems="center"
              width="100%"
              position="relative"
              top="-10px"
              marginTop='20px'
            >
              <FileUploadOutlinedIcon fontSize="large" />
              <ArrowForwardIcon fontSize="large" />
              <AddBoxOutlinedIcon fontSize="large" />
            </Box>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, textAlign: 'center', marginTop: '15px' }}>
              To install this web app on your device open in Safari then tap the Menu button and select “Add to Home Screen”
            </Typography>
            <Box sx={nextContainerStyle}>
                <Button onClick={() => handleClose()} variant="contained" sx={IOSNextButtonStyle}>Next</Button>
            </Box>
          </Box>
        }
      </Box>
    </Modal>
    </>
  );
};

export default InstallPWAPopup;
