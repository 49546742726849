import { useCallback, useEffect, useState } from 'react';
import { useIsAppInstalled } from 'hooks/useIsAppInstalled';
import InstallPWAPopup from 'components/InstalPWAPopup/InstallPWAPopup';

type IPWAForm = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const PWAForm = ({ setStep }: IPWAForm) => {
  const [open, setOpen] = useState(true);
  const { isInstalledInIos, isInstalledOnAndroid } = useIsAppInstalled();

  const handleClose = useCallback(() => {
    setOpen(false);
    setStep(4);
  }, [setStep]);
  useEffect(() => {
    if (isInstalledInIos || isInstalledOnAndroid) {
      handleClose();
    }
  }, [handleClose, isInstalledInIos, isInstalledOnAndroid]);

  return isInstalledInIos || isInstalledOnAndroid ? null : (
    <InstallPWAPopup open={open} handleClose={handleClose} />
  );
};

export default PWAForm;
