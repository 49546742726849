type IFileIcon = {
  width?: string;
  height?: string;
};

const FileSaveIcon = ({ width = '30', height = '30' }: IFileIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 62 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.3285 0H7.66181C3.90014 0 0.884033 3.05 0.884033 6.77778V54.2222C0.884033 57.95 3.90014 61 7.66181 61H55.1063C58.834 61 61.884 57.95 61.884 54.2222V13.5556L48.3285 0ZM31.384 54.2222C25.7585 54.2222 21.2174 49.6811 21.2174 44.0556C21.2174 38.43 25.7585 33.8889 31.384 33.8889C37.0096 33.8889 41.5507 38.43 41.5507 44.0556C41.5507 49.6811 37.0096 54.2222 31.384 54.2222ZM41.5507 20.3333H7.66181V6.77778H41.5507V20.3333Z"
      fill="#3381FF"
    />
  </svg>
);

export default FileSaveIcon;
