import { IBundle } from 'models/IBundle';

export type IBundleDetails = {
  mode: 1 | 2;
  getBundleDetails: Function;
  bundleDetailsTrigger: boolean;
  bundle: IBundle | undefined;
};

export type IBundleDescription = {
  text: string;
  heading: string;
  property?: string;
};

export const BUNDLE_DETAILS_TOOLTIP_TEXTS: any = {
  summary: 'Bundle Summary which describes the bundle in detail',
  slug: 'Url slug based on the start date and title.',
  is_published:
    'This turns the bundle on or off in the app. Yes means it will display for users, No means it will not display.',
  bundle_image:
    'Main marketing image for the bundle (in general use the main activity image).',
  bundle_video: 'Main marketing video for the bundle.',
  map_url:
    'The web address for the diagrammatic representation of an area of land or sea showing physical features, cities, roads, etc. for where the activity takes place.',
  duration: 'Duration for the bundle in minutes',
};
