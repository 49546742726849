import { IBundleComponent } from 'models/IBundleComponent';

export const COMPONENT_INITIAL_VALUES: IBundleComponent = {
  bundle_id: 0,
  bundle_title: '',
  component_description: '',
  component_title: '',
  component_type: '',
  cost_per_person_high: '',
  cost_per_person_low: '',
  end_datetime: '',
  id: 0,
  image_url: '',
  order: '',
  reservation: {
    booking_url: '',
    is_reservation_available: false,
    reservation_description: '',
    reservation_link_title: '',
  },
  start_datetime: '',
  venue: {
    menu_url: '',
    venue_address: '',
    venue_map_url: '',
    venue_name: '',
  },
  website_url: '',
  genre_l1_form: '',
  genre_l2_discipline: '',
  member_offer: '',
  duration: '',
};
