import { useState, useEffect } from 'react';

export const useReview = (shouldListen: boolean) => {
  const [isSuccess, setIsSuccess] = useState<Boolean | null>(null);
  useEffect(() => {
    if (!shouldListen) return;
    const isEligibleListner = (data: any) => {
      if (data.detail === 'true') {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    };
    window.addEventListener('review-page-opened-listner', isEligibleListner);
    return () => {
      window.removeEventListener(
        'review-page-opened-listnerr',
        isEligibleListner
      );
    };
  }, [shouldListen]);
  const triggerPopupForReview = () => {
    const webkit = (window as any).webkit;
    if (
      webkit &&
      webkit.messageHandlers &&
      webkit.messageHandlers['trigger-open-review']
    ) {
      webkit.messageHandlers['trigger-open-review'].postMessage(
        'trigger-open-review'
      );
    }
  };
  return {
    isSuccess,
    triggerPopupForReview,
  };
};

export default useReview;
