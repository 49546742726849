import { Box, Typography } from '@mui/material';
import { COLORS } from 'constants/colors';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TypeFormQuizForm from 'components/TypeFormQuizForm/TypeFormQuizForm';

interface Props {
  children?: React.ReactNode;
  userId: string;
}

const YourPreferences: React.FC<Props> = ({ userId }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
      border={`1px solid ${COLORS.blue}`}
      borderRadius="10px"
      marginX="32px"
      paddingX="20px"
      paddingY="20px"
    >
      <Typography fontSize="16px" lineHeight="19px" fontWeight="700">
        Your Preferences
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <CheckCircleIcon fontSize="small" htmlColor={COLORS.blue} />
        <Typography marginLeft="10px" fontSize="14px" lineHeight="16px">
          Date personality quiz completed.
          <TypeFormQuizForm
            buttonText="Retake"
            userId={userId}
            buttonStyle={{
              fontSize: '14px',
              border: 'none',
              backgroundColor: 'white',
              textDecoration: 'underline',
              cursor: 'pointer',
              fontWeight: 'bold',
              paddingLeft: 0,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default YourPreferences;
