import React from 'react';

import moment from 'moment';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

interface ICalendarSectionProps {
  bundle: any;
}

function CustomPickersDay(props: PickersDayProps<moment.Moment> & { highlightedDates?: string[] }) {
  const { highlightedDates = [], day, sx, today, selected, ...other } = props;

  const isHighlighted = day ? highlightedDates.indexOf(day.format('MM/DD/YYYY')) >= 0 : null;
  const isPast = day.isSameOrBefore(); // relative to now

  let backgroundColor = null;
  if (isHighlighted) {
    backgroundColor = '#FFBF00';
    if (isPast && !today) {
      // Shade past days in a lighter color
      backgroundColor = '#FFDF99';
    }
  }

  return (
    <PickersDay
      {...other}
      today={today}
      sx={{
        ...sx,
        // Override background color for dates the bundle is scheduled
        ...(backgroundColor ? { backgroundColor } : {}),
      }}
      day={day}
    />
  );
}

const CalendarSection: React.FC<ICalendarSectionProps> = ({
  bundle,
}) => {

  const datesToHighlight = (bundle && bundle.available_date_times) ? Object.keys(bundle.available_date_times) : [];

  return (
    <DateCalendar
      // No need to select dates
      disabled
      showDaysOutsideCurrentMonth
      disablePast
      slots={{
        day: CustomPickersDay,
      }}
      slotProps={{
        day: {
          highlightedDates: datesToHighlight,
        } as any,
      }}
    />
  );
};

export default CalendarSection;
