import { COLORS } from '../constants/colors';
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: COLORS.primary,
		},
	},
	typography: {},
});
