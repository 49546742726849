import { IBundle, IBundleSummary } from 'models/IBundle';
import moment from 'moment';

export const calculateCost = (components: any) => {
  const cost = components.reduce(
    (prev: number, component: any) =>
      prev + parseInt(component?.cost_per_person_low ?? 0),
    0
  );
  return cost;
};

export const haveMemberOffer = (components: any) => {
  return (
    components?.filter((_component: any) => _component.member_offer).length > 0
  );
};


export const costRangeToString = (lowPrice: number | undefined, highPrice: number | undefined) => {
  if (typeof lowPrice === 'undefined' || typeof highPrice === 'undefined') {
    return ''
  } else if (lowPrice <= 0 && highPrice <= 0) {
    return 'Free';
  } else if (lowPrice === highPrice) {
    return `$${lowPrice.toFixed(0)} / person`;
  } else {
    return `$${lowPrice.toFixed(0)}–${highPrice.toFixed(0)} / person`;
  }
}

/*
 * Return a string description of the cost of the bundle, or null if no cost info is available.
 * e.g., 'Free', or '$40 / person', or '$55–80 / person'
 */
export const getBundleCost = (bundle: IBundle) => {
  if (!bundle?.components) return;

  const { highestPrice, lowestPrice } = calculateHighLowCost(bundle);
  return costRangeToString(lowestPrice, highestPrice);
};

/*
 * Return a string description of the cost of the bundle summary, or null if no cost info is available.
 * e.g., 'Free', or '$40 / person', or '$55–80 / person'
 */
export const getBundleSummaryCost = (bundle: IBundleSummary) => {
  const lowestPrice = bundle.lowestPrice;
  const highestPrice = bundle.highestPrice;
  return costRangeToString(lowestPrice, highestPrice);
};

export const calculateHighLowCost = (bundle: IBundle) => {
  let lowestPrice = 0;
  let highestPrice = 0;

  Object.values(bundle && bundle.components).forEach((comp: any) => {
    if (comp['cost_per_person_high'])
      highestPrice += parseInt(comp['cost_per_person_high']);
    if (comp['cost_per_person_low'])
      lowestPrice += parseInt(comp['cost_per_person_low']);
  });

  return { lowestPrice, highestPrice };
};

export const calculateBundleStartEndTime = (
  bundle: IBundle,
  dateSelectedIso: string
) => {
  // Calculating total duration
  let totalDuration = 0;

  bundle?.components &&
    Object.values(bundle?.components).forEach(
      (_: any, idx: number) => (totalDuration += Number(_.duration))
    );

  // Converting date selected date to EST
  const dateSelected = moment(dateSelectedIso);
  const date = moment.tz(
    moment(dateSelected, 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm'),
    'America/Toronto'
  );

  // Calculating availableDate
  let startTimeTemp;
  let startDateTimeTemp;
  let endDateTimeTemp;
  let endTimeTemp;

  // Getting Start Time
  const availableDate =
    bundle &&
    bundle.available_date_times &&
    bundle.available_date_times[moment(date).format('MM/DD/YYYY')];
  const startTime = availableDate?.start_time;
  const startTimeSplit: string[] | undefined = startTime?.split(':');

  // Getting End Time if startTime available
  if (startTime && startTimeSplit) {
    let hour = parseInt(startTimeSplit[0]);
    // converting 12hour to 24 hour formate
    if (startTimeSplit[1].toLowerCase().search('p') !== -1) {
      if (hour !== 12) {
        hour += 12;
      }
    }
    startDateTimeTemp = endDateTimeTemp = moment(date)
      .set('hour', hour)
      .set('minute', parseInt(startTimeSplit[1]));

    startTimeTemp = moment(date)
      .set('hour', hour)
      .set('minute', parseInt(startTimeSplit[1]))
      .format('h:mm A')
      .toString();
    endTimeTemp = moment(date)
      .set('hour', hour)
      .set('minute', parseInt(startTimeSplit[1]))
      .add(totalDuration, 'minute')
      .format('h:mm A')
      .toString();
    endDateTimeTemp.add(totalDuration, 'minute');
  } else {
    // If start_time not available
    startDateTimeTemp = endDateTimeTemp = moment(bundle?.start_datetime);
    startTimeTemp = endTimeTemp = moment(bundle?.start_datetime)
      .format('h:mm A')
      .toString();
    endTimeTemp = moment(bundle?.start_datetime)
      .add(totalDuration, 'minute')
      .format('h:mm A')
      .toString();
    endDateTimeTemp.add(totalDuration, 'minute');
  }

  return {
    startDateTimeTemp,
    startTimeTemp,
    endTimeTemp,
    endDateTimeTemp,
  };
};
