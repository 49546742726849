import { COLORS } from 'constants/colors';
import { useNavigate } from 'react-router-dom';

const ShowMatches = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        color: 'white',
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.blue,
        borderRadius: '10px',
        height: '46px',
        fontWeight: '700',
        fontSize: '18px',
      }}
      onClick={() => {
        navigate('/');
      }}
    >
      Show Matches
    </div>
  );
};

export default ShowMatches;
