export enum ILogTypeEnum {
  BUNDLE_CLICK = "BUNDLE_CLICK"
}
export interface IBundleClickData {
  bundle_id:number,
  bundle_title:string
}
export type ILogs = {
  log_type:ILogTypeEnum
  data:IBundleClickData
};

