import React from 'react';

import moment from 'moment-timezone';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import BundleSummary from 'components/BundleSummary';
import { IBundleSummary } from 'models/IBundle';
import { ISearchResults } from 'models/ISearch';

interface Props {
  results: ISearchResults | null;
  isLoading: boolean;
  onDateSelected: (date: moment.Moment) => void;
};

const SearchResults: React.FC<Props> = ({ results, isLoading, onDateSelected }) => {
  const handleShowThisWeekend = () => {
    const thisSaturday = moment();
    if (thisSaturday.isoWeekday() <= 6) {
      // This saturday
      thisSaturday.isoWeekday(6);
    } else {
      // Next saturday
      thisSaturday.isoWeekday(6 + 7);
    }
    return onDateSelected(thisSaturday);
  }

  const renderInitialState = () => {
    const saturdayLabel = (moment().isoWeekday() === 6) ? "today" : "this Saturday";

    return (
      <>
        <Typography variant="h5" align="center" sx={{ marginTop: '2em', fontWeight: 300 }}>
          <p>Hey there!</p>
          <p>Find Toronto experiences by title or date.</p>
        </Typography>
        <Button
          variant="outlined"
          onClick={handleShowThisWeekend}
        >
          Show me experiences for {saturdayLabel}
        </Button>
      </>
    );
  }

  const renderNoResults = () => {
    return (
      <>
        <Typography variant="h5" align="center" sx={{ marginTop: '2em', fontWeight: 300 }}>
          Sorry, no experiences exactly matched your search.
        </Typography>
      </>
    );
  }

  const renderLoading = () => {
    return (
      <>
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
      </>
    );
  }

  if (isLoading) {
    return renderLoading();
  } else if (!results) {
    return renderInitialState();
  } else if (!results.results || results.results.length === 0) {
    return renderNoResults();
  } else {
    // We have at least one result
    return (
      <Stack spacing={1} sx={{ margin: 1 }}>
        {results.results.map((bundle: IBundleSummary, idx: number) => (
          <BundleSummary bundle={bundle} key={idx} />
        ))}
      </Stack>
    )
  }
};



export default SearchResults;
