import { COLORS } from "constants/colors";

export const boxStyle = {
  padding: 2,
};
export const buttonStyle = {
  marginY: 1,
  textTransform: 'capitalize',
};
export const editButtonStyle = {
  marginY: 1,
  textTransform: 'capitalize',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: COLORS.black,
  borderRadius: '10px',
  border: '1px solid ' + COLORS.blue,
};
export const saveTandemBoxStyle = {
  width: '100%',
  backgroundColor: '#3381FF',
  height: 52,
  borderRadius: 2,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  marginTop: '8px',
  '&: hover': {
    cursor: 'pointer',
  },
};
export const logoDivStyle = {
  backgroundColor: 'white',
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '30px',
  marginRight: '17px',
};
export const getTandemAppText = {
  fontWeight: 'bold',
  color: 'white',
  fontSize: 15,
  marginTop: '3px',
};

export const cameraIconStyleContainer = {
  marginTop: 11.6,
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer'
}

export const cameraIconStyle = {
  width: '86.67px',
  height: '88.01px',
  backgroundColor: '#7A7A7A',
  borderRadius: 50,
}
