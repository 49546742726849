import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: '295px',
    marginTop: '2%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainContainer: {
    width: '31%',
    backgroundColor: 'white',
    borderRadius: '30px',
  },
  scheduleDatesContainer: {
    display: 'flex',
    paddingLeft: '4%',
    flexDirection: 'column',
    marginTop: '5%',
    height: '73%',
    overflow: 'auto',
    gap: '8px',
  },
  daveRatingContainer: {
    marginTop: '5%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 5%',
    marginRight: '5%',
    gap: '15px',
  },
}));

export default useStyles;
