import { useEffect, useState } from 'react';

export const useDeviceTokenRequest = () => {
  const [token, setToken] = useState();
  // ====== Receive Firebase Device Token ======
  useEffect(() => {
    const receiveDeviceToken = (data: any) => {
      if (data?.detail?.token) {
        const t = data?.detail?.token;
        setToken(t);
      }
    };
    window.addEventListener('firebase-device-token', receiveDeviceToken);
    return () => {
      window.removeEventListener('firebase-device-token', receiveDeviceToken);
    };
  }, []);

  const sendDeviceTokenRequest = () => {
    const webkit = (window as any).webkit;
    if (
      webkit &&
      webkit.messageHandlers &&
      webkit.messageHandlers['firebase-device-token']
    ) {
      webkit.messageHandlers['firebase-device-token'].postMessage(
        'firebase-device-token'
      );
    }
  };
  return { sendDeviceTokenRequest, token };
};

export default useDeviceTokenRequest;
