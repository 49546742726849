import { makeStyles } from '@mui/styles';
import { COLORS } from 'constants/colors';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.white,
    color: COLORS.blue,
    width: '100%',
    height: '100%',
    boxShadow: '12px 12px 50px rgba(15, 42, 86, 0.15)',
    borderRadius: '20px',
    padding: '5%',
  },
}));

export default useStyles;
