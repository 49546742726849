// imports
import React, { PropsWithChildren } from 'react';
import { useLocation, Link } from 'react-router-dom';

// types
import { Props } from './types';

// mui
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from '@mui/material';

// styles
import { BoxStyle, NavContainerStyle, BottomNavStyle } from './styles';

// assets
import BookmarkIcon from '@mui/icons-material/Bookmark';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';

const Layout: React.FC<PropsWithChildren<Props>> = ({ children, show }) => {
  // context
  const location = useLocation();
  const active = location.pathname

  console.log(`DEBUG: Layout tab ${active}`);

  const navigationItemStyle: React.CSSProperties = {
    // Override default of 60px to allow narrower buttons
    minWidth: '45px',
  };

  return (
    <Box
      sx={BoxStyle}
      style={{
        paddingBottom: show ? 96 : 0,
      }}
    >
      {children}
      {show && (
        <Paper sx={NavContainerStyle} elevation={3}>
          <BottomNavigation showLabels value={active} sx={BottomNavStyle}>
            <BottomNavigationAction
              component={Link}
              to='/'
              value='/'
              label='Home'
              icon={
                <HomeIcon />
              }
              style={navigationItemStyle}
            />
            <BottomNavigationAction
              component={Link}
              to='/search'
              value='/search'
              label="Search"
              icon={
                <SearchIcon />
              }
              style={navigationItemStyle}
            />
            <BottomNavigationAction
              component={Link}
              to='/saved'
              value='/saved'
              label="Saved"
              icon={
                <BookmarkIcon />
              }
              style={navigationItemStyle}
            />
            <BottomNavigationAction
              component={Link}
              to='/profile'
              value='/profile'
              label="Settings"
              icon={
                <SettingsIcon />
              }
              style={navigationItemStyle}
            />
          </BottomNavigation>
        </Paper>
      )}
    </Box>
  );
};

export default Layout;
