/* eslint-disable react-hooks/exhaustive-deps */
// imports
import React, { useEffect, useContext, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import { UserContext } from 'context/User';

import {
  useCancelSubscription,
  useGetUserProfileImage,
  useReActiveSubscription,
} from 'hooks/api';
import { getCheckoutSession, setUserProfileImage } from 'api';
import { useLogout } from 'hooks/api';

// mui
import { Box } from '@mui/system';
import { Container, Menu, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import { boxStyle, cameraIconStyle, cameraIconStyleContainer } from './styles';

import CancelSubscriptionConfirmation from './CancelSubscriptionConfirmation';
import IOSPwaPopup from 'components/InstalPWAPopup/IOSPwaPopup';
import NeedSupport from './components/NeedSupport';
import GetTandemApp from './components/GetTandemApp';
import EditNameEmail from './components/EditNameEmail';
import EditPassword from './components/EditPassword';
import EditPostalCode from './components/EditPostalCode';
import YourPreferences from './components/YourPreferences';

import LogoutIcon from '@mui/icons-material/Logout';
import CameraIcon from 'assets/camera-icon';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useNavigate } from 'react-router-dom';
import { useIsAppInstalled } from 'hooks/useIsAppInstalled';
import DeleteAccount from './components/DeleteAccount';

const Profile: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const imageUploadRef: any = useRef(null);
  const { logoutUser } = useLogout();
  const { isInstalledInIos, isInstalledOnAndroid } = useIsAppInstalled();

  const { cancelSubscription, cancelSubscriptionLoading } =
    useCancelSubscription();
  const { reActiveSubscription, activeSubscriptionLoading } =
    useReActiveSubscription();
  const { userState, setUserState, appState } =
    useContext(UserContext);
  const { profileImageUrl, refetchProfileImage } = useGetUserProfileImage(
    userState.email
  );
  const navigate = useNavigate();

  useEffect(() => {
    refetchProfileImage();
  }, [refetchProfileImage]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelSubscription = async () => {
    if (userState?.subscribedStatus === 'trial') {
      subscribeApp();
      return;
    }
    try {
      if (userState?.subscribedStatus === 'active') {
        setOpenConfirmationModal(true);
      } else {
        await reActiveSubscription();
        toast.success('subscription activated');
        setUserState({ ...userState, subscribedStatus: 'active' });
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.errors || error?.response?.data?.error
      );
    }
  };

  const subscribeApp = async () => {
    try {
      setLoading(true);
      const data = await getCheckoutSession();
      const url = data?.data?.url;
      window.location.href = url;
    } catch (err) {
      toast.error('internal server error.');
    }
    setLoading(false);
  };

  const onConfirmation = async () => {
    try {
      if (userState?.subscribedStatus === 'active') {
        await cancelSubscription();
        toast.success('subscription canceled');
        setUserState({ ...userState, subscribedStatus: 'canceled' });
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.errors || error?.response?.data?.error
      );
    }
  };

  const onSuccess = async (res: any) => {
    const imageUrl = res.url;
    const email = userState.email;
    const data = { email, imageUrl };
    await setUserProfileImage(data);
    toast.success('Image uploaded successfully');
    refetchProfileImage();
  };

  return (
    <Container maxWidth="sm" disableGutters>
      <CancelSubscriptionConfirmation
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        onConfirmation={onConfirmation}
      />
      <div>
        {['active', 'canceled', 'trial'].includes(
          userState?.subscribedStatus
        ) &&
          appState.isSubscriptionActive && (
            <Box sx={boxStyle}>
              <Typography variant="h6">
                <b>Manage Subscription</b>
              </Typography>
              <Typography variant="subtitle2">
                Email: {userState?.email}
              </Typography>
              {userState?.daysLeft > 0 && (
                <Typography variant="subtitle1">
                  Days left in free trial: {userState?.daysLeft}
                </Typography>
              )}
              {userState?.daysLeft <= 0 &&
                userState?.subscribedStatus !== 'active' && (
                  <Typography variant="subtitle1">
                    Your free trial ended.
                  </Typography>
                )}
              <LoadingButton
                sx={{
                  backgroundColor:
                    userState?.subscribedStatus === 'active'
                      ? 'red'
                      : '#2196f3',
                  marginY: 1,
                  textTransform: 'capitalize',
                }}
                variant="contained"
                onClick={handleCancelSubscription}
                disabled={
                  cancelSubscriptionLoading || activeSubscriptionLoading
                }
                loading={loading}
              >
                {userState?.subscribedStatus === 'active'
                  ? 'Cancel Subscription'
                  : 'Subscribe'}
              </LoadingButton>
            </Box>
          )}
        {open ? (
          <IOSPwaPopup
            open={open}
            onOpen={() => {}}
            onClose={() => setOpen(false)}
          />
        ) : null}
        <Box
          fontWeight="900"
          fontSize="19px"
          lineHeight="22px"
          padding="32px"
          paddingBottom="10px"
          textAlign="center"
        >
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div>Settings</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'relative',
            }}
          >
            <div
              onClick={handleClick}
              style={{ position: 'absolute', top: -24 }}
            >
              <MenuOutlinedIcon style={{ cursor: 'pointer' }} />
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {userState.role &&
                (userState.role === 'admin' ||
                  userState.role === 'superadmin') && (
                  <MenuItem onClick={handleClose}>
                    <Box onClick={() => navigate('/admin')} display={'flex'}>
                      <SupervisorAccountIcon />
                      <span style={{ marginLeft: '10px' }}>Admin</span>
                    </Box>
                  </MenuItem>
                )}
              <MenuItem onClick={handleClose}>
                <Box display={'flex'} onClick={logoutUser}>
                  <LogoutIcon />
                  <span style={{ marginLeft: '10px' }}>Logout</span>
                </Box>
              </MenuItem>
            </Menu>
          </div>
          <div style={cameraIconStyleContainer}>
            <div style={cameraIconStyle}>
              <div
                style={{ marginTop: profileImageUrl ? 0 : 30 }}
                onClick={() => {
                  if (imageUploadRef?.current) {
                    imageUploadRef?.current?.click();
                  }
                }}
              >
                {profileImageUrl ? (
                  <div>
                    <img
                      src={profileImageUrl}
                      style={cameraIconStyle}
                      alt={'Profile'}
                    />
                  </div>
                ) : (
                  <CameraIcon />
                )}
                <ImageUpload
                  imageUploadRef={imageUploadRef}
                  onSuccess={onSuccess}
                  folderName={'User_Profile_Pictures'}
                />
              </div>
            </div>
          </div>
        </Box>
        <EditNameEmail />
        <EditPassword />
        <EditPostalCode mode={1} />
        <YourPreferences userId={userState.id} />
        {isInstalledInIos || isInstalledOnAndroid ? null : (
          <GetTandemApp setOpen={setOpen} />
        )}
        <NeedSupport />
        <DeleteAccount email={userState.email} />
      </div>
    </Container>
  );
};

export default Profile;
