import { useMutation } from 'react-query';

import {
  createSubscriptionApi,
  cancelSubscriptionApi,
  reActiveSubscriptionApi,
} from 'api';

export interface ICancelSubscription {
  subscriptionId: any;
}

export const useCreateSubscription = () => {
  const { mutateAsync: subscribeApi, isLoading: saveBundleLoading } =
    useMutation(() => createSubscriptionApi(), {
      onError: (error: any) => {
        console.error('====>>> error', error?.response?.data?.errors);
      },
    });
  return {
    saveBundleLoading,
    subscribeApi,
  };
};

export const useCancelSubscription = () => {
  const { mutateAsync: cancelSubscription, isLoading } = useMutation(
    () => cancelSubscriptionApi(),
    {
      onError: (error: any) => {
        console.error('====>>> error', error?.response?.data?.errors);
      },
    }
  );
  return {
    cancelSubscriptionLoading: isLoading,
    cancelSubscription,
  };
};

export const useReActiveSubscription = () => {
  const { mutateAsync: reActiveSubscription, isLoading } = useMutation(
    () => reActiveSubscriptionApi(),
    {
      onError: (error: any) => {
        console.error('====>>> error', error?.response?.data?.errors);
      },
    }
  );
  return {
    activeSubscriptionLoading: isLoading,
    reActiveSubscription,
  };
};
