/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Tooltip,
} from '@mui/material';
import Card from 'components/Card/Card';
import { COLORS } from 'constants/colors';
import { toast } from 'react-toastify';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { IKeywords } from './Keywords.types';

const Keywords = ({ keywordsTrigger, getKeywords, bundle }: IKeywords) => {
  const [keywordsInput, setKeywordsInput] = useState('');
  const [internalKeywordsInput, setInternalKeywordsInput] = useState('');
  const [keywords, setKeywords] = useState<any>({});
  const [internalKeywords, setInternalKeywords] = useState<any>({});

  useEffect(() => {
    if (!bundle || bundle.keywords === '') {
      setKeywords({});
      return;
    }
    const keywordSplit = bundle.keywords?.split(',');
    const internalKeywordSplit = bundle.internal_keywords?.split(',');
    const keywordsTemp: any = {};
    const internalKeywordsTemp: any = {};

    keywordSplit?.forEach((keyword: string) => {
      keywordsTemp[keyword] = true;
    });
    internalKeywordSplit?.forEach((keyword: string) => {
      internalKeywordsTemp[keyword] = true;
    });

    setKeywords(keywordsTemp);
    if (bundle.internal_keywords !== '') {
      setInternalKeywords(internalKeywordsTemp);
    }
  }, [bundle]);

  useEffect(() => {
    if (keywordsTrigger) {
      let keywordsTemp = '';
      let internalKeywordsTemp = '';
      Object.keys(keywords).forEach((key: string, index: number) => {
        if (index !== Object.keys(keywords).length - 1) {
          keywordsTemp += key + ',';
        } else {
          keywordsTemp += key;
        }
      });
      Object.keys(internalKeywords).forEach((key: string, index: number) => {
        if (index !== Object.keys(internalKeywords).length - 1) {
          internalKeywordsTemp += key + ',';
        } else {
          internalKeywordsTemp += key;
        }
      });
      getKeywords(keywordsTemp, internalKeywordsTemp);
    }
  }, [keywordsTrigger]);

  const addKeyword = (keyword: string, type: number) => {
    if (keyword === '') {
      toast.dismiss();
      toast.info('Keyword cannot be empty');
      return;
    }
    let keywordTemp;
    if (type === 1) {
      keywordTemp = { ...internalKeywords };
    } else {
      keywordTemp = { ...keywords };
    }
    if (!keywordTemp[keyword]) {
      keywordTemp[keyword] = true;
      if (type === 1) {
        setInternalKeywords(keywordTemp);
        setInternalKeywordsInput('');
      } else {
        setKeywords(keywordTemp);
        setKeywordsInput('');
      }
    } else {
      toast.dismiss();
      toast.info('Keyword already added');
    }
  };

  const removeKey = (keyword: string, type: number) => {
    let keywordTemp;
    if (type === 1) {
      keywordTemp = { ...internalKeywords };
    } else {
      keywordTemp = { ...keywords };
    }
    delete keywordTemp[keyword];
    if (type === 1) {
      setInternalKeywords(keywordTemp);
    } else {
      setKeywords(keywordTemp);
    }
  };

  const KeywordContainer = (headingText: string, type: number) => (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginTop={type === 2 ? '6%' : '0'}
      >
        <Typography fontWeight={'bold'}>{headingText}</Typography>
        {type === 1 && (
          <Tooltip
            placement="top"
            title="Please see DAV Guideline document for information on keywords."
            arrow
          >
            <HelpOutlineIcon
              sx={{ '&:hover': { cursor: 'pointer' }, marginRight: '5%' }}
            />
          </Tooltip>
        )}
      </Box>
      <Box marginTop={'10%'}>
        <TextField
          id={`input-with-icon-textfield_${type}`}
          onChange={(e: any) => {
            if (type === 1) {
              setInternalKeywordsInput(e.target.value);
            } else {
              setKeywordsInput(e.target.value);
            }
          }}
          value={type === 1 ? internalKeywordsInput : keywordsInput}
          onKeyDownCapture={(e) => {
            // @ts-ignore
            if (e.keyCode === 13) {
              const keyword =
                type === 1 ? internalKeywordsInput : keywordsInput;
              addKeyword(keyword, type);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          variant="standard"
          sx={{
            width: '96%',
            border: `1px solid ${COLORS.blue}`,
            borderRadius: 50,
            padding: '10px 10px 10px 20px',
          }}
          placeholder={'Enter keyword'}
        />
      </Box>

      <Grid
        container
        marginTop={'10%'}
        paddingLeft={'2%'}
        columnGap={'10px'}
        rowGap={'6px'}
      >
        {Object.keys(type === 1 ? internalKeywords : keywords).map(
          (key: string, index: number) => (
            <Box
              key={index}
              display={'flex'}
              alignItems={'center'}
              border={`1px solid ${COLORS.lightGrey}`}
              padding={'0px 10px'}
              overflow={'hidden'}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => removeKey(key, type)}
            >
              <Typography>{key}</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CloseIcon
                  sx={{
                    width: '15px',
                    height: '15px',
                    marginLeft: '2px',
                  }}
                />
              </div>
            </Box>
          )
        )}
      </Grid>
    </>
  );

  return (
    <Card>
      <Box padding={'5%'}>
        {KeywordContainer('Internal Keywords', 1)}
        {KeywordContainer('External Keywords', 2)}
      </Box>
    </Card>
  );
};

export default Keywords;
