/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { useLogout } from 'hooks/api';

import { UserContext } from 'context/User';

const Canceled = () => {
  const navigate = useNavigate();
  const { logoutUser } = useLogout();

  const { userState } = useContext(UserContext);

  useEffect(() => {
    if (
      userState.isAuthenticated &&
      ['active', 'canceled', 'trial'].includes(userState.subscribedStatus)
    ) {
      navigate('/');
    }
  }, [userState.isAuthenticated, userState?.subscribedStatus]);

  return (
    <Box>
      <Button
        onClick={logoutUser}
        sx={{ position: 'absolute', top: '10px', right: '20px', color: '#000' }}
      >
        Logout
      </Button>
      <Typography
        sx={{ paddingTop: '100px', textAlign: 'center', color: 'red' }}
      >
        Subscription Cancelled
      </Typography>
    </Box>
  );
};

export default Canceled;
