import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import ShareIcon from '@mui/icons-material/Share';

import { handleShare } from 'utils/handleShare';

interface IShareSectionProps {
  bundle: any;
}

const ShareSection: React.FC<IShareSectionProps> = ({
  bundle,
}) => {

  return (
    <Container>
      <Typography variant="body1" mt={3} mb={1}>
        It takes two to tango! Share this date idea with a guest.
      </Typography>
      <Button
        startIcon={<ShareIcon />}
        size="large"
        fullWidth
        variant="contained"
        onClick={() => {
          handleShare({
            id: bundle.id,
            title: 'Share bundle',
            url: window.location.href,
          });
        }}
      >
        Share
      </Button>

      {bundle.keywords && bundle.keywords.length > 0 && (
        <Typography variant="body2" mt={5} mb={3}>
          <strong>Top tags: </strong>
          {bundle.keywords.split(',').join(', ')}
        </Typography>
      )}
    </Container>
  );

};

export default ShareSection;
