import React, { useState } from 'react';

import moment from 'moment';

// import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

interface Props {
  children?: React.ReactNode;
  date?: moment.Moment | null;
  onDateSelected?: (date: moment.Moment) => void;
}

const Calendar: React.FC<Props> = ({ date, onDateSelected }) => {
  const [selectedDate, setDate] = useState<moment.Moment>(date || moment());
  const today = moment()

  return (
    <DateCalendar
      referenceDate={today}
      value={selectedDate}
      disablePast
      onChange={(val) => {
          if (val) {
              setDate(val);
              if (onDateSelected) onDateSelected(val);
          }
      }}
      showDaysOutsideCurrentMonth
    />
);

};

export default Calendar;
