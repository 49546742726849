import { FC } from 'react';

import { IBundle } from 'models/IBundle';
import ItineraryItem from './ItineraryItem';

interface IItineraryComponentProps {
  bundle: IBundle;
}

const ItineraryComponent: FC<IItineraryComponentProps> = ({
  bundle,
}) => {

  if (!bundle || !bundle.components) {
    return null;
  }

  const nonTravelComponents = Object.values(
      bundle.components
    ).filter((component) => (component.component_type !== 'Travel'))

  return (
    <div>
      {nonTravelComponents.map((item: any, index: number) => (
        <ItineraryItem
          key={index}
          item={item}
          stepLabel={String.fromCharCode('A'.charCodeAt(0) + index)}
        />
      ))}
    </div>
  );
};

export default ItineraryComponent;
