import { containerstyle, Content } from './styles';

import { useNavigate } from 'react-router-dom'

import { Container, Typography, Button } from '@mui/material';

import Header from '../../components/Header';

const NotAvailable = () => {
  const navigate = useNavigate()
  return (
    <Container sx={containerstyle}>
      <Header back />
      <Content>
        <Typography sx={{ mb: 4, mt: 8 }} textAlign="center" variant="h5">
          <b>Sorry, your home city isn't available yet!</b>
        </Typography>
        <Typography textAlign="center" variant="h6">
          Nearest Location to you:
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 4, height: '45px', fontWeight: 600 }}
          onClick={() => {
            navigate('/discover')
          }}
        >
          Tandem Toronto
        </Button>
      </Content>
    </Container>
  );
};

export default NotAvailable;
