// imports
import styled from '@emotion/styled';

// styles components
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
`;
