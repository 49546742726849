/* eslint-disable react-hooks/exhaustive-deps */
// imports
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// context
import Layout from '../layout';

import { UserContext } from 'context/User';

import {
  IUpdateDeviceKeys,
  useGetUser,
} from 'hooks/api';

import AuthenticatedRoutes from './AuthenticatedRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';

import { getFirebaseDeviceKey, setUserId } from 'helpers/firebase';
import { updateDeviceKeysApi } from 'api';
import { useDeviceTokenRequest } from '../hooks/webkit/useDeviceTokenRequest';
import { usePushPermissionRequest } from '../hooks/webkit/usePushPermissionRequest';
import { logEvent } from 'helpers/firebase';

const MainRouter = () => {
  const location = useLocation();
  const { sendPushPermissionRequest, permission } = usePushPermissionRequest();
  const { sendDeviceTokenRequest, token } = useDeviceTokenRequest();

  useGetUser();
  const { userState, setUserState, appState } =
    useContext(UserContext);

  // ===== Uploading The Token To the server =====
  const getFirebaseKey = async (device?: string) => {
    console.log("DEBUG: GetFirebaseKey");
    let deviceKey = '';
    if (device) {
      deviceKey = device;
    } else {
      deviceKey = (await getFirebaseDeviceKey()) ?? '';
      console.log("DEBUG: GetFirebaseKey DEVICE_KEY",deviceKey)
    }

    if (deviceKey) {
      const userDeviceKeys = userState.device_keys ?? [];
      const email = userState.email;

      if (!userDeviceKeys.includes(deviceKey) && email.length > 0) {
        console.log("DEBUG: sending device key", deviceKey);
        const updatedDeviceKeys = [...userDeviceKeys, deviceKey];
        const payload: IUpdateDeviceKeys = {
          email,
          device_keys: updatedDeviceKeys,
        };
        await updateDeviceKeysApi(payload);
        logEvent('AddFCMToken', { 'tokenCount': updatedDeviceKeys.length });
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('tandemToken')) {
      setUserState({ ...userState, isInitialApiFetched: true });
    }
  }, []);

  // Set analytics user id once logged in to deduplicate across interfaces
  // See: https://firebase.google.com/docs/analytics/userid
  useEffect(() => {
    if (setUserId && userState.id) {
      console.log(`DEBUG: logged in with user id: ${userState.id}`);
      setUserId(userState.id);
    }
  }, [setUserId, userState.id]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let fcm = params.get('fcm')
    if(!fcm){
      fcm = localStorage.getItem('fcm')
    }
    if (localStorage.getItem('tandemToken') && fcm) {
      getFirebaseKey(fcm);
    }else{
      getFirebaseKey();
    }
  }, [userState.email]);

  // ===== Calling for notification permission ======
  useEffect(() => {
    if (userState.isAuthenticated) {
      sendPushPermissionRequest();
    }
  }, [userState.isAuthenticated]);

  

  // ===== Requesting Device Token and uploading =====
  useEffect(() => {
    if (permission === 'granted') {
      logEvent('AllowNotifications');
      sendDeviceTokenRequest();
    }
  }, [permission]);

  // ===== Get firebase key whenever token changes =====
  useEffect(() => {
    if (token) {
      getFirebaseKey(token);
    }
  }, [token]);

  useEffect(()=>{
    const params = new URLSearchParams(window.location.search);
    const fcm = params.get('fcm')
    if(fcm){
      localStorage.setItem('fcm',fcm)
    }
  },[])

  if (!userState.isInitialApiFetched) {
    return null;
  }

  return (
    <Layout
      show={
        // Condition under which bottom navigation buttons are shown
        !location.pathname.startsWith('/admin') &&
        !location.pathname.startsWith('/join-beta') &&
        !location.pathname.startsWith('/booknow') &&
        !location.pathname.startsWith('/bundles') &&
        userState.isAuthenticated &&
        (['active', 'canceled', 'trial'].includes(userState.subscribedStatus) ||
          appState.isSubscriptionActive === false)
      }
    >
      {userState.isAuthenticated ? (
        <AuthenticatedRoutes />
      ) : (
        <UnauthenticatedRoutes />
      )}
    </Layout>
  );
};

export default MainRouter;
