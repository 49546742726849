/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@mui/material';

import { useSignInUser, useSocialSignin } from 'hooks/api';

import { PWD_VALID_REGEX } from 'utils/passwordRegex';

import Header from 'components/Header';
import Input from 'components/Input';
import { Form } from './styles';

import { FormikErrorsType } from './types';
import GoogleAuth from 'components/Auth/google/GoogleAuth';
import ContinueWithApple from 'components/ContinueWithApple/ContinueWithApple';
import { toast } from 'react-toastify';
import { useReview } from '../../hooks/webkit/useReview';
import { logEvent } from 'helpers/firebase';

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
};

const Signin: React.FC = () => {
  let navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  useReview(true);

  const { mutateAsync } = useSignInUser();
  const { mutateAsync: socialSignin } = useSocialSignin();

  useEffect(() => {
    let isFirstOpen = params.get('firstOpen');
    if (isFirstOpen) {
      navigate('/join-beta', { replace: true });
    }
  }, [])

  useEffect(() => {
    let code = params.get('code');
    if (code) {
      handleGoogle({
        code: code,
        isNew: false,
      });
    }
  }, []);

  // handle sign in button click
  const handleSignInClick = useCallback(() => {
    navigate('/join-beta');
  }, []);

  // handle forgot password button click
  const handleForgotPasswordCLick = useCallback(() => {
    navigate('/forgot-password');
  }, []);
  // ====== handle apple sign in ======
  useEffect(() => {
    let apple_auth = params.get('access');
    if (!apple_auth) return;
    const token = apple_auth;
    localStorage.setItem('tandemToken', token);
    logEvent('login', { method: 'Apple' });
  }, []);
  useEffect(() => {
    let error = params.get('error');
    if (!error) return;
    toast.error(error);
  }, []);
  const handleGoogle = async (data: any) => {
    await socialSignin({
      code: data.code,
      isNew: false,
    });
  };

  return (
    <Container maxWidth="sm">
      <Header />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        marginTop="20px"
        marginBottom="20px"
        gap="12px"
      >
        <GoogleAuth />
      </Box>
      <Box>
        <ContinueWithApple title="Sign in with Apple" />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="20px"
        marginTop="30px"
      >
        <Box flex="0.45" borderTop="1px solid #DCDCDC"></Box>
        <Box
          flex="0.1"
          fontWeight="700"
          fontSize="12px"
          textAlign="center"
        >
          OR
        </Box>
        <Box flex="0.45" borderTop="1px solid #DCDCDC"></Box>
      </Box>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {} as FormikErrorsType;
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          if (!values.password) {
            errors.password = 'Required';
          } else if (!PWD_VALID_REGEX.test(values.password)) {
            errors.password =
              'Password length must be min 8 characters with at least 1 capital letter, 1 small letter and 1 digit';
          }
          // if (values.password !== values.confirmPassword) {
          //   errors.confirmPassword = 'Passwords do not match';
          // }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await mutateAsync(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
          >
            <Input
              customlabel=""
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              formikerror={errors.email && touched.email && errors.email}
              value={values.email}
              placeholder="Email"
            />
            <Input
              customlabel=""
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              formikerror={
                errors.password && touched.password && errors.password
              }
              value={values.password}
              placeholder="Password"
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
            >
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
      <div>
        <Button
          onClick={handleForgotPasswordCLick}
          variant="text"
          fullWidth
        >
          Forget your password?
        </Button>
        <Typography variant="body2" mt={3} align="center">
          Don't have an account yet?
        </Typography>
        <Button
          onClick={handleSignInClick}
          sx={{
            marginTop: 1,
            fontWeight: 900,
          }}
          variant="outlined"
          fullWidth
        >
          Create new account
        </Button>
      </div>
    </Container>
  );
};

export default Signin;
