import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiErrorHandler } from "utils/apiErrorHandler";

import {
  registerApi,
  signInApi,
  getUserApi,
  updateUserApi,
  forgotPasswordApi,
  resetPasswordApi,
  updatePasswordApi,
  codeConfirmationApi,
  getBundleFeedbackApi,
  createFeedbackApi,
  socialSignin,
  addPartnerApi,
  addConnection,
  getPartners,
  getActiveConnection,
  setActiveConnection,
  getActiveConnectionInfo,
  getUserProfileImage,
  registerSocialSignup,
  updatePostalCodeApi,
  deleteUserApi,
  sendNotificationApi,
  sendNotificationToAllUserApi,
} from "api";
import { UserContext, UserInitialValues } from "context/User";
import { logEvent } from 'helpers/firebase';

export interface IRegisterUserProps {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    postal_code: string;
  };
  signupType: number;
}

export interface ISocialAuthProps {
  code: string;
  isNew: boolean;
  accessToken?: string;
}

export interface ISignInProps {
  email: string;
  password: string;
}

export interface IUpdateUserProps {
  first_name?: string;
  last_name?: string;
  email?: string;
  tandem_outside_alert?: boolean;
}

export interface IUpdatePostalCodeProps {
  email: string;
  postal_code: string;
}

export interface IUpdateDeviceKeys {
  email: string;
  device_keys: string[];
}

export interface ISendNotification {
  email: string;
  title: string;
  message: string;
  url: string;
}

export interface ISendNotificationToAllUser {
  title: string;
  message: string;
  url: string;
}

export interface IForgotPasswordProps {
  email: string;
}

export interface IResetPasswordProps {
  token: string;
  password: string;
}

export interface IUpdatePasswordProps {
  password: string;
  updatedPassword: string;
}

export interface ICodeConfirmationProps {
  token: string;
}

export interface ICreateFeedbackProps {
  rating?: number;
  feedback?: string;
  attended?: boolean;
  bundle_id: number;
  createdAt?: Date | undefined;
  componentsLength?: number | null;
}

export interface IAddPartnerProps {
  firstName: string;
  email: string;
}

export interface IGetPartnerProps {
  email: string;
}

export interface IRemovePartnerProps {
  email: string;
  connectionId: string;
}

export interface ISetActiveConnection {
  email: string;
  connectionId: string;
  checked: boolean;
}

export interface ISetUserProfileImage {
  imageUrl: string;
  email: string;
}

export interface IDeleteUserProps {[key: string]: any}

export const useRegisterUser = (setStep: any, invitationData?: any) => {
  const { mutateAsync, isLoading } = useMutation(
    (values: IRegisterUserProps) => registerApi(values),
    {
      onSuccess: async ({ data }) => {
        try {
          if (invitationData?.token && invitationData?.id) {
            await addConnection({
              token: invitationData?.token,
              id: invitationData?.id,
            });
            toast.success("Partner added successfully");
          }
        } catch (error: any) {
          toast.error(apiErrorHandler(error));
          console.error("====>>> error", error?.response?.data?.errors);
        }
        localStorage.setItem("tandemToken", data?.token);
        localStorage.removeItem("tandem-trial-alert");
        localStorage.removeItem("tandem-outside-alert");
        // setUserState({
        //   ...data.user,
        //   isAuthenticated: true,
        //   isInitialApiFetched: true,
        //   subscribedStatus: 'trial',
        //   daysLeft: data?.user?.trial_duration_days ?? 30,
        // });
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        console.error("====>>> error", error?.response?.data?.errors);
        setStep(1);
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
  };
};

export const useSocialSignUp = (setStep: any, invitationData?: any) => {
  const { mutateAsync, isLoading } = useMutation(
    (values: ISocialAuthProps) => registerSocialSignup(values),
    {
      onSuccess: async ({ data }) => {},
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        console.error("====>>> error", error?.response?.data?.errors);
        setStep(1);
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
  };
};

export const useSocialSignin = () => {
  const navigate = useNavigate();
  const { setUserState } = useContext(UserContext);
  const { mutateAsync, isLoading } = useMutation(
    (values: ISocialAuthProps) => socialSignin(values),
    {
      onSuccess: ({ data }) => {
        if (data?.token) {
          localStorage.setItem("tandemToken", data?.token);
          setUserState({
            ...data.user,
            isAuthenticated: true,
            subscribedStatus: data?.subscribedStatus,
            isInitialApiFetched: true,
            daysLeft: Math.ceil(data.daysLeft),
          });
          logEvent('login', { method: 'Google' });
          const queryParams = new URLSearchParams(window.location.search);
          const bundleId = queryParams
            .get("bundle")
            ?.replace(new RegExp(" ", "g"), "+");
          const logisticsFlag = queryParams.get("logistics");
          if (bundleId) {
            navigate(
              `/bundles/${bundleId}${
                logisticsFlag ? "?logistics=" + logisticsFlag : ""
              }`
            );
          } else {
            navigate(`/`);
          }
        } else {
          navigate(`/join-beta?signup=true&accessToken=${data.accessToken}`);
        }
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.validateUserErrorMessage?.includes("deletion")
        )
          toast.error(error?.response?.data?.validateUserErrorMessage);
        else {
          navigate("/join-beta?signup=true");
        }
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
  };
};

export const useSignInUser = () => {
  const navigate = useNavigate();
  const { setUserState } = useContext(UserContext);
  const { mutateAsync, isLoading } = useMutation(
    (values: ISignInProps) => signInApi(values),
    {
      onSuccess: ({ data }) => {
        localStorage.setItem("tandemToken", data?.token);
        setUserState({
          ...data.user,
          isAuthenticated: true,
          subscribedStatus: data?.subscribedStatus,
          isInitialApiFetched: true,
          daysLeft: Math.ceil(data.daysLeft),
        });
        logEvent('login', { method: 'password' });
        const queryParams = new URLSearchParams(window.location.search);
        const bundleId = queryParams
          .get("bundle")
          ?.replace(new RegExp(" ", "g"), "+");
        const logisticsFlag = queryParams.get("logistics");
        if (bundleId) {
          navigate(
            `/bundles/${bundleId}${
              logisticsFlag ? "?logistics=" + logisticsFlag : ""
            }`
          );
        } else {
          navigate(`/`);
        }
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        console.error("====>>> error", error?.response?.data?.errors);
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
  };
};

export const useGetUser = () => {
  const { userState, setUserState } = useContext(UserContext);
  const { data, error, isLoading } = useQuery("user", () => getUserApi(), {
    onSuccess: ({ data }) => {
      setUserState({
        ...data.user,
        isAuthenticated: true,
        isInitialApiFetched: true,
        subscribedStatus: data?.subscribedStatus,
        daysLeft: Math.ceil(data.daysLeft),
      });
    },
    onError: (error: any) => {
      console.error("====>>> error", error?.response?.data?.errors);
      setUserState({
        ...userState,
        isInitialApiFetched: true,
      });
    },
    enabled: !!localStorage.getItem("tandemToken"),
  });
  return {
    isLoading,
    data,
    error,
  };
};

export const useUpdatedUser = () => {
  const { userState, setUserState } = useContext(UserContext);
  const { mutateAsync: updateUserApiHook, isLoading } = useMutation(
    (values: IUpdateUserProps) => updateUserApi(values),
    {
      onSuccess: ({ data }) => {
        setUserState({ ...userState, ...data.user });
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        console.error("====>>> error", error?.response?.data?.errors);
      },
    }
  );
  return {
    isLoading,
    updateUserApiHook,
  };
};

export const useLogout = () => {
  const navigate = useNavigate();
  const { setUserState } = useContext(UserContext);
  const logoutUser = () => {
    localStorage.removeItem("tandemToken");
    setUserState({ ...UserInitialValues, isInitialApiFetched: true });
    logEvent('Logout');
    navigate("/");
  };
  return {
    logoutUser,
  };
};

export const useForgotPassword = () => {
  const { mutateAsync: forgotPassword, isLoading } = useMutation(
    (values: IForgotPasswordProps) => forgotPasswordApi(values),
    {
      onSuccess: ({ data }) => {
        toast.info(data.message);
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    forgotPassword,
  };
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const { mutateAsync: resetPassword, isLoading } = useMutation(
    (values: IResetPasswordProps) => resetPasswordApi(values),
    {
      onSuccess: ({ data }) => {
        toast.info(data.message);
        navigate("/");
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    resetPassword,
  };
};

export const useUpdatePassword = () => {
  const navigate = useNavigate();
  const { setUserState } = useContext(UserContext);
  const { mutateAsync: updatePassword, isLoading } = useMutation(
    (values: IUpdatePasswordProps) => updatePasswordApi(values),
    {
      onSuccess: ({ data }) => {
        toast.info(data.message);
        localStorage.removeItem("tandemToken");
        setUserState({ ...UserInitialValues, isInitialApiFetched: true });
        navigate("/");
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    updatePassword,
  };
};

export const useCodeConfirmation = () => {
  const navigate = useNavigate();
  const { setUserState, userState } = useContext(UserContext);
  const { mutateAsync: emailVerifyApi, isLoading } = useMutation(
    (values: ICodeConfirmationProps) => codeConfirmationApi(values),
    {
      onSuccess: () => {
        toast.success("Account verification completed");
        setUserState({ ...userState, is_email_verified: true });
        navigate("/");
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        navigate("/");
      },
    }
  );
  return {
    emailVerificationLoading: isLoading,
    emailVerifyApi,
  };
};

export const useCreateFeedback = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  refetchFeedback?: () => any
) => {
  const { mutateAsync: createFeedback, isLoading } = useMutation(
    (values: ICreateFeedbackProps) => createFeedbackApi(values),
    {
      onSuccess: () => {
        toast.success("Feedback submitted successfully");
        refetchFeedback?.();
        setOpen(false);
      },
      onError: (error) => {
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isCreating: isLoading,
    createFeedback,
  };
};

export const useGetFeedback = (bundle_id: number) => {
  const { data, isLoading, refetch } = useQuery(
    [`bundle-feedback-${bundle_id}`, bundle_id],
    () => getBundleFeedbackApi(bundle_id),
    {
      enabled: !!bundle_id && !!localStorage.getItem("tandemToken"),
    }
  );
  return {
    feedback: data,
    isGettingFeedback: isLoading,
    refetchFeedback: refetch,
  };
};

export const useAddPartner = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: IAddPartnerProps) => addPartnerApi(data),
    {
      onSuccess: () => {
        toast.dismiss();
        toast.success(`Invitation email was sent to your partner`);
      },
      onError: (error) => {
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    addPartner: mutateAsync,
    isAddingPartner: isLoading,
  };
};

export const useGetPartners = (email: string) => {
  const { data, isLoading, refetch } = useQuery("get-partners", () =>
    getPartners({ email })
  );
  return {
    partners: data,
    isGettingFeedback: isLoading,
    refetchPartners: refetch,
  };
};

export const useGetActiveConnection = (email: string) => {
  const { data, isLoading, refetch } = useQuery("get-active-connection", () =>
    getActiveConnection({ email })
  );
  return {
    activeConnection: data?.data?.data,
    isGettingFeedback: isLoading,
    refetchActiveConnection: refetch,
  };
};

export const useGetActiveConnectionInfo = (email: string) => {
  const { data, isLoading, refetch } = useQuery(
    "get-active-connection-info",
    () => getActiveConnectionInfo({ email })
  );
  return {
    activeConnectionInfo: data?.data?.data,
    isGettingFeedback: isLoading,
    refetchActiveConnectionInfo: refetch,
  };
};

export const useSetActiveConnection = () => {
  const { mutateAsync: setActiveConnectionApi, isLoading } = useMutation(
    (values: ISetActiveConnection) => setActiveConnection(values),
    {
      onSuccess: ({ data }) => {
        toast.dismiss();
        toast.success(data.message);
      },
      onError: (error: any) => {
        toast.dismiss();
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    setActiveConnectionApi,
  };
};

export const useGetUserProfileImage = (email: string) => {
  const { data, isLoading, refetch } = useQuery("get-profile-image", () =>
    getUserProfileImage({ email })
  );
  return {
    profileImageUrl: data?.data?.data,
    isGettingFeedback: isLoading,
    refetchProfileImage: refetch,
  };
};

export const useUpdateUserPostalCode = () => {
  const { userState, setUserState } = useContext(UserContext);
  const { mutateAsync: updatePostalCode, isLoading } = useMutation(
    (values: IUpdatePostalCodeProps) => updatePostalCodeApi(values),
    {
      onSuccess: ({ data }) => {
        setUserState({ ...userState, ...data.user });
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        console.error("====>>> error", error?.response?.data?.errors);
      },
    }
  );
  return {
    isLoading,
    updatePostalCode,
  };
};

export const useValidatePassword = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<{ message: string } | null>(null);
  const { mutateAsync, isLoading } = useMutation(
    (values: ISignInProps) => signInApi(values),
    {
      onSuccess: ({ data }) => {
        if (data?.token) {
          setIsValid(true);
        } else {
          setError({ message: "Invalid Password" });
        }
      },
      onError: (error: any) => {
        setIsValid(false);
        setError({ message: "Invalid Password" });
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
    isValid,
    error,
    setError,
  };
};

export const useDeleteUser = () => {
  const { setUserState } = useContext(UserContext);
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutation(
    (deleteReasons: IDeleteUserProps) => deleteUserApi(deleteReasons),
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        localStorage.removeItem("tandemToken");
        setUserState({ ...UserInitialValues, isInitialApiFetched: true });
        navigate("/");
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
  };
};

export const useSendNotification = () => {
  const { mutateAsync, isLoading } = useMutation(
    (values: ISendNotification) => sendNotificationApi(values),
    {
      onSuccess: async ({ data }) => {
        try {
          toast.success("Notification Send");
        } catch (error: any) {
          if (error.response.data.message)
            toast.error(error.response.data.message);
        }
      },
      onError: (error: any) => {
        if (error.response.data.message)
          toast.error(error.response.data.message);
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
  };
};

export const useSendToAllUserNotification = () => {
  const { mutateAsync, isLoading } = useMutation(
    (values: ISendNotificationToAllUser) =>
      sendNotificationToAllUserApi(values),
    {
      onSuccess: async ({ data }) => {
        try {
          toast.success("Notification Send");
        } catch (error: any) {
          if (error.response.data.message)
            toast.error(error.response.data.message);
        }
      },
      onError: (error: any) => {
        if (error.response.data.message)
          toast.error(error.response.data.message);
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
  };
};
