import { makeStyles } from '@mui/styles';
import { COLORS } from 'constants/colors';

const useStyles = makeStyles(() => ({
  bundleNameContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bundleText: {
    margin: 0,
    color: COLORS.blue,
    fontWeight: 'bold',
    fontSize: '18px',
    marginRight: '7px',
    width: '70%',
  },
  bundleTextContainer: {
    display: 'flex',
    color: COLORS.blue,
    overflow: 'hidden',
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editIcon: {
    width: '20px',
    height: '20px',
    marginTop: '3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  overrideCard: {
    paddingTop: '1%',
    paddingBottom: '2.5%',
  },
  addComponentContainer: {
    marginTop: '1%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2%',
    border: `2px solid ${COLORS.black}`,
    width: '5vw',
    marginBottom: '6.2%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
