/* eslint-disable react-hooks/exhaustive-deps */

// imports
import { useState, useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';

// screens
import Home from '../pages/Home';
import SavedBundles from '../pages/SavedBundles';
import Profile from '../pages/Profile';
import EditProfile from '../pages/EditProfile';
import BundleDetail from '../pages/BundleDetail';
import Success from '../pages/Success';
import UpdatedPassword from 'pages/UpdatePassword';
import CheckoutCanceled from '../pages/Checkout/canceled';
import CodeConfirmation from 'pages/CodeConfirmation';
import Search from '../pages/Search'; // Import the Search component

import Checkout from 'pages/Checkout';

import { UserContext } from 'context/User';
import Preferences from 'pages/Preferences/Preferences';
import QuizForm from 'pages/Signup/components/QuizForm/QuizForm';
import Admin from 'pages/Admin/Admin';

const AuthenticatedRoutes = () => {
  const location = useLocation();
  const { userState, appState } = useContext(UserContext);
  const [openCheckoutModal, setOpeCheckoutModal] = useState(false);
  const [displayNotification, setDisplayNotification] = useState(
    localStorage.getItem('tandem-trial-alert') || 'true'
  );

  useEffect(() => {
    if (
      userState.isAuthenticated &&
      !['active', 'canceled', 'trial'].includes(userState?.subscribedStatus) &&
      appState.isSubscriptionActive &&
      location.pathname === '/'
    ) {
      setOpeCheckoutModal(true);
    }
  }, [userState]);

  const onSubscribe = () => {
    setOpeCheckoutModal(true);
  };

  return (
    <>
      <Checkout openModal={openCheckoutModal} setOpen={setOpeCheckoutModal} />
      {userState.isAuthenticated &&
        userState.subscribedStatus === 'trial' &&
        displayNotification === 'true' &&
        appState.isSubscriptionActive && (
          <Alert
            severity="info"
            onClose={() => {
              setDisplayNotification('false');
              localStorage.setItem('tandem-trial-alert', 'false');
            }}
          >
            {userState?.daysLeft > 0 &&
              `Your free trial will expire in ${userState?.daysLeft} days. Click `}
            {userState?.daysLeft <= 0 && `Your free trial ended. Click `}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={onSubscribe}
            >
              here
            </span>{' '}
            to upgrade.
          </Alert>
        )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/saved" element={<SavedBundles />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/bundles/:slug" element={<BundleDetail />} />
        <Route path="/success" element={<Success />} />
        <Route
          path="/preferences"
          element={<QuizForm userId={userState.id} />}
        />
        <Route path="/connections" element={<Preferences />} />
        <Route path="/checkout/canceled" element={<CheckoutCanceled />} />
        <Route path="/update-password" element={<UpdatedPassword />} />
        <Route path="/confirm" element={<CodeConfirmation />} />
        <Route path="/search" element={<Search />} />
        <Route path="*" element={<Navigate replace to="/" />} />
        {userState.role &&
          (userState.role === 'admin' || userState.role === 'superadmin') && (
            <Route path="/admin" element={<Admin />} />
          )}
      </Routes>
    </>
  );
};

export default AuthenticatedRoutes;
