/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import BundleSummary from 'components/BundleSummary';
import Header from './components/Header';
import Seperator from './components/Seperator';

import { useGetSavedBundles } from 'hooks/api';
import { IBundleSummary } from 'models/IBundle';

const SavedBundles: React.FC = () => {
  const { dates, isLoading } = useGetSavedBundles();
  const [ activeTab, setActiveTab ] = useState(0);

  const upcomingBundles = dates ? dates.filter((bundle: IBundleSummary) => (bundle && bundle.nextDate)) : [];
  const pastBundles = dates ? dates.filter((bundle: IBundleSummary) => (bundle && !bundle.nextDate)) : [];

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    console.log(`DEBUG: Changing tab to ${newTab}`);
    setActiveTab(newTab);
  };

  const renderLoading = () => {
    return (
      <Stack spacing={1} sx={{ margin: 1 }}>
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
        <Skeleton variant="rounded" height={100} width='100%' />
      </Stack>
    );
  }

  const renderEmptyState = () => {
    return (
      <Typography component='p' variant='body1' align="center" sx={{ padding: '20px 0', fontWeight: 300 }}>
        Visit your
        {' '}<Link to="/">home</Link>{' '}
        page to find something you'll love.
      </Typography>
    );
  }

  const renderBundleTab = (tab: number) => {
    const bundles = tab === 0 ? upcomingBundles : pastBundles;

    if (isLoading) {
      return renderLoading();
    } else if (bundles && bundles.length > 0) {
      return (
        <Stack spacing={1} sx={{ margin: 1 }}>
          {bundles.map((date: IBundleSummary, idx: number) => (
            <BundleSummary bundle={date} key={idx} />
          ))}
        </Stack>
      );
    } else {
      return renderEmptyState();
    }
  }

  return (
    <Container maxWidth="sm" disableGutters>
      <Header />
      <Seperator />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
        >
          <Tab label="Upcoming" />
          <Tab label="Past" />
        </Tabs>
      </Box>
      {renderBundleTab(activeTab)}
    </Container>
  );
};

export default SavedBundles;
