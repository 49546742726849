import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useGetRecommendedBundles } from 'hooks/api';
import { IBundleSummary } from 'models/IBundle';
import RecommendedTab from './components/RecommendedTab';
import BundleSummary from 'components/BundleSummary';
import { UserContext } from 'context/User';

const Home: React.FC = () => {
  const { userState } = useContext(UserContext);

  const { data, isLoading } = useGetRecommendedBundles();

  const bundles = data?.data?.bundles;

  const renderLoading = () => {
    return (
      <Stack spacing={1} sx={{ margin: 1 }}>
        <Skeleton variant="rounded" height={90} width='100%' />
        <Skeleton variant="rounded" height={90} width='100%' />
        <Skeleton variant="rounded" height={90} width='100%' />
        <Skeleton variant="rounded" height={90} width='100%' />
        <Skeleton variant="rounded" height={90} width='100%' />
        <Skeleton variant="rounded" height={90} width='100%' />
      </Stack>
    );
  }

  const renderBundles = (bundles: IBundleSummary[] | null) => {
    if (bundles) {
      return (
        <>
          <Stack spacing={1} sx={{ margin: 1 }}>

            <Card
              variant="outlined"
              sx={{
                color: "rgb(1, 67, 97)",
                borderColor: "rgb(1, 67, 97)",
              }}
            >
              <CardActionArea
                component={Link}
                to="https://tandemexperiences.com/membership"
              >
                <CardContent>
                  <OpenInNewIcon sx={{ float: 'right' }} fontSize="small" />
                  <Typography variant="body2" color="inherit" sx={{ pr: '25px' }}>
                    Become a Tandem+ Insider to unlock exclusive offers and events
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

          {bundles.map((bundle: IBundleSummary, idx: number) => (
            <BundleSummary bundle={bundle as IBundleSummary} key={idx} />
          ))}

            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              fontStyle="italic"
              sx={{ pt: 5 }}
            >
              Check back next week for more experiences&hellip;
            </Typography>
          </Stack>

        </>
      );
    }
  }

  return (
    <Container maxWidth="sm" disableGutters>
      <RecommendedTab
        email={userState.email}
        name={userState.first_name}
      />
      {
        isLoading ?
        renderLoading() :
        renderBundles(bundles)
      }
    </Container>
  );
};

export default Home;
