// imports
import React from 'react';

// mui
import {
  FormControl,
  FormHelperText,
  Input as MUIInput,
  InputLabel,
  InputProps,
} from '@mui/material';

interface Props extends InputProps {
  customlabel: string;
  formikerror?: string | false | undefined;
  containerstyle?: {};
}

const Input: React.FC<Props> = (props) => {
  const random = Math.round(Math.random() * 1000000);
  return (
    <FormControl
      error={props.formikerror ? true : false}
      variant="standard"
      sx={props.containerstyle}
    >
      {props.customlabel ? (
        <InputLabel shrink htmlFor={`${props.customlabel}-input-${random}`}>
          {props.customlabel}
        </InputLabel>
      ) : null}
      <MUIInput id={`${props.customlabel}-input-${random}`} {...props} />
      {props.formikerror && (
        <FormHelperText id={`${props.customlabel}-error-text`}>
          {props.formikerror}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Input;
