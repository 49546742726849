/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import Grow from '@mui/material/Grow';
import { isDesktop } from 'react-device-detect';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface Props {
  children?: React.ReactNode;
  url: string;
  selected?: number;
  currentIndex?: number;
  videoHeight?: string;
  bundle_image?:string
}

const VideoItem: React.FC<Props> = ({
  url,
  selected,
  currentIndex,
  videoHeight,
  bundle_image
}) => {
  const [paused, setIsPaused] = useState(false);
  const [showPause, setShowPause] = useState(false);
  const [showPauseIcon, setShowPauseIcon] = useState(false);
  const vidRef = useRef<HTMLVideoElement | null>(null);

  const onPlay = () => {
    const player: any = vidRef.current;
    if (player) {
      player.muted = false;
    }

    vidRef.current?.play();
    setShowPause(true);
    setIsPaused(false);
    setTimeout(() => setShowPause(false), 1000);
  };

  const onPause = () => {
    vidRef.current?.pause();
    setIsPaused(true);
  };

  useEffect(() => {
    if (selected !== currentIndex) {
      vidRef.current?.pause();
      setIsPaused(true);
    } else {
      vidRef.current?.pause();
      setIsPaused(true);
    }
  }, [selected, currentIndex]);

  return (
    <div
      style={{
        position: 'relative',
        height: 'calc(100vh - 56px - 48px)',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        onClick={paused ? onPlay : onPause}
        style={{
          position: 'absolute',
          color: 'white',
          width: '50px',
          height: '50px',
          left: videoHeight ? '47%' : '50%',
          right: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          zIndex: 1,
          display: 'gird',
          placeItems: 'center',
        }}
      >
        <Grow in={paused}>
          <div
            style={{
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              border: '4px solid white',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'grid',
              placeItems: 'center',
              margin: 'auto',
            }}
          >
            <PlayArrowIcon />
          </div>
        </Grow>
      </div>
      {showPause ? (
        <div
          onClick={!paused ? onPause : undefined}
          style={{
            position: 'absolute',
            color: 'white',
            width: '50px',
            height: '50px',
            left: videoHeight ? '47%' : '50%',
            right: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 1,
            display: 'gird',
            placeItems: 'center',
          }}
        >
          <Grow in={showPause}>
            <div
              style={{
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                border: '4px solid white',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'grid',
                placeItems: 'center',
                margin: 'auto',
              }}
            >
              <PauseIcon />
            </div>
          </Grow>
        </div>
      ) : null}
      {isDesktop && showPauseIcon && !paused && !showPause ? (
        <div
          onMouseEnter={() => setShowPauseIcon(true)}
          onMouseLeave={() => setShowPauseIcon(false)}
          onClick={onPause}
          style={{
            position: 'absolute',
            color: 'white',
            width: '50px',
            height: '50px',
            left: videoHeight ? '47%' : '50%',
            right: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 1,
            display: 'gird',
            placeItems: 'center',
          }}
        >
          <Grow in={showPauseIcon && !paused && !showPause}>
            <div
              style={{
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                border: '4px solid white',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'grid',
                placeItems: 'center',
                margin: 'auto',
              }}
            >
              <PauseIcon />
            </div>
          </Grow>
        </div>
      ) : null}
      {paused && bundle_image ? 
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            height: 'calc(100vh - 56px - 48px)',
            backgroundImage: `url(${bundle_image})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />:
        <div>
          <video
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
            onClick={!paused ? onPause : onPlay}
            ref={vidRef}
            loop
            controls={false}
            onMouseEnter={() => setShowPauseIcon(true)}
            onMouseLeave={() => setShowPauseIcon(false)}
            muted
            autoPlay
            playsInline
          >
            <source src={`${url}#t=0.1`}></source>
          </video>
        </div>
      }
      </div>
  );
};

export default VideoItem;
