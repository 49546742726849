import { useEffect, useState } from "react";

import useStyles from "./Header.styles";

import { Box, Tooltip } from "@mui/material";

import { COLORS } from "constants/colors";

import FileIcon from "assets/file-icon";
import FileSaveIcon from "assets/file-save-icon";
import PublishIcon from "assets/publish-icon";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { HEADER_TOOLTIP_TEXTS, IAdminHeader } from "./Header.types";
import { useDeleteBundle } from "hooks/api";
import UserConfirmationDialog from "components/UserConfirmationDialog/UserConfirmationDialog";

const AdminContentHeader = ({
  setBundleDetailsTrigger,
  setKeywordsTrigger,
  setComponentsTrigger,
  setBundleTitle: setTitle,
  bundle,
  setCurrentBundle,
  mode,
  setCurrentMode,
  refetchAdminBundles,
}: IAdminHeader) => {
  const classes = useStyles();
  const [bundleTitle, setBundleTitle] = useState(
    bundle ? bundle.bundle_title : ""
  );
  const { deleteBundle } = useDeleteBundle();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    if (bundle) {
      setBundleTitle(bundle ? bundle.bundle_title : "");
    } else {
      setBundleTitle("");
    }
  }, [bundle]);

  const handleMakeBundleCopy = () => {
    setCurrentMode(1);
  };

  const handleDeleteBundle = async () => {
    setOpenConfirmationDialog(true);
  };

  const handlePreviewBundle = () => {
    const url = `/bundles/${bundle?.slug ?? ""}?admin_preview=true`;
    window.open(url);
  };

  const handleConfirmationClick = async () => {
    if (bundle?.id) {
      await deleteBundle(bundle?.id);
      refetchAdminBundles();
      setCurrentMode(1);
      setCurrentBundle(undefined);
    }
  };

  return (
    <>
      <UserConfirmationDialog
        headerText={"Are you sure?"}
        paragraphText={"Clicking Yes will delete the bundle permanently."}
        confirmationText={"Yes"}
        cancelText={"No"}
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        handleConfirmationClick={handleConfirmationClick}
      />
      <div className={classes.bundleNameContainer}>
        <div className={classes.bundleTextContainer}>
          <Tooltip
            arrow
            placement="bottom"
            title={HEADER_TOOLTIP_TEXTS.bundle_title}
          >
            <p
              style={{
                margin: "auto",
                paddingRight: "20px",
              }}
            >
              Bundle Title:{"  "}
            </p>
          </Tooltip>
          <Box display={"flex"}>
            <input
              type={"text"}
              value={bundleTitle}
              onChange={(e: any) => setBundleTitle(e.target.value)}
              placeholder={"Enter bundle_title"}
              style={{
                fontFamily: "inherit",
                width: "40vw",
                height: "30px",
              }}
            />
          </Box>
        </div>
        <div style={{ display: "flex", gap: "40px" }}>
          {bundle && mode === 2 && (
            <>
              <div
                className={classes.fileContainer}
                style={{ color: COLORS.blue }}
                onClick={handlePreviewBundle}
              >
                <VisibilityIcon />
                <p style={{ margin: 0 }}>Preview</p>
              </div>
              <div
                className={classes.fileContainer}
                style={{ color: COLORS.blue }}
                onClick={handleDeleteBundle}
              >
                <DeleteIcon />
                <p style={{ margin: 0 }}>Delete Bundle</p>
              </div>
              <div
                className={classes.fileContainer}
                onClick={handleMakeBundleCopy}
              >
                <FileIcon />
                <p style={{ margin: 0, color: COLORS.blue }}>Make a Copy</p>
              </div>
            </>
          )}
          <div className={classes.fileContainer} style={{ display: "none" }}>
            <FileSaveIcon />
            <p style={{ margin: 0, color: COLORS.blue }}>Save Draft</p>
          </div>
          <div
            className={classes.fileContainer}
            onClick={async () => {
              setBundleDetailsTrigger(true);
              setKeywordsTrigger(true);
              setComponentsTrigger(true);
              setTitle(bundleTitle);
            }}
          >
            <PublishIcon />
            <p style={{ margin: 0, color: COLORS.blue }}>
              {mode === 1 ? "Save" : "Update"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminContentHeader;
