import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createSubscriptionApi = async () => {
  return axios(`${BASE_URL}/api/subscription`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'POST',
  }).then((response) => response);
};

export const cancelSubscriptionApi = async () => {
  return axios(`${BASE_URL}/api/subscription`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'DELETE',
  }).then((response) => response);
};

export const getCheckoutSession = async () => {
  return axios(`${BASE_URL}/api/subscription/checkout/session`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
  }).then((response) => response);
};

export const reActiveSubscriptionApi = async () => {
  return axios(`${BASE_URL}/api/subscription/re-active`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'POST',
  }).then((response) => response);
};
