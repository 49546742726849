import styled from '@emotion/styled';
export const ContainerStyle = {
  padding: '82px 20px 20px',
};
export const InputContainerStyle = {
  width: '100%',
  marginBottom: '40px',
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin-top: 32px;
  flex: 1;
`;
