/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import moment from 'moment';

import { IBundleItem, ISidebar } from "./Sidebar.types";
import useStyles from "./Sidebar.styles";

import { Box } from "@mui/system";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { COLORS } from "constants/colors";
import InputWithIcon from "components/InputWithIcon/InputWithIcon";
import UploadFileIcon from "assets/upload-file-icon";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Typography } from "@mui/material";
import { IBundle } from "models/IBundle";
import { SendNotification } from "../SendNotification/SendNotification";

const AdminSidebar = ({
  currentBundle,
  setCurrentBundle,
  bundles = [],
  setCurrentMode,
}: ISidebar) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [filteredBundles, setFilteredBundles] = useState(bundles);
  const [query, setQuery] = useState("");
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();

  const getSortedBundles = (bundlesTemp: IBundle[]) => {
    bundlesTemp.sort((a: IBundle, b: IBundle) => {
      const aFutureDates = a.available_date_times ? Object.keys(a.available_date_times).filter((date: string) => {
        return moment(date, "MM/DD/YYYY").isSameOrAfter(moment(), 'day');
      }).length : 0;
  
      const bFutureDates = b.available_date_times ? Object.keys(b.available_date_times).filter((date: string) => {
        return moment(date, "MM/DD/YYYY").isSameOrAfter(moment(), 'day');
      }).length : 0;
  
      if (aFutureDates !== bFutureDates) {
        return bFutureDates - aFutureDates;  
      }
  
      if (a.bundle_title.toLowerCase() < b.bundle_title.toLowerCase()) {
        return -1;
      }
  
      if (a.bundle_title.toLowerCase() > b.bundle_title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  
    setFilteredBundles(bundlesTemp);
  };

useEffect(() => {
  let bundlesToSort;
  
  if (query === "") {
    bundlesToSort = [...bundles];
  } else {
    //regex pattern that matches any word in the query string.
    const regex = new RegExp(query.split(" ").map(word => `(?=.*${word})`).join(""), "i");
    bundlesToSort = bundles.filter(
      (bundle: IBundle) => regex.test(bundle.bundle_title)
    );
  }

  getSortedBundles(bundlesToSort);
}, [query, bundles]); 



  const Header = () => (
    <svg
      width="200"
      height="40"
      viewBox="0 0 545 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_64_13)">
        <path
          d="M53.7755 87.6425H32.9993L32.8571 21.4174H4.88525V2.02051H81.7758V21.4174H53.7897V87.6425H53.7755Z"
          fill="#3381FF"
        />
        <path
          d="M139.525 72.9669H104.344L98.1152 87.5145H77.6092L77.4669 85.7085L114.597 1.89258H130.808L166.274 85.7085L166.417 87.5145H145.214L139.525 72.9669ZM112.094 53.9824H131.761L122.063 30.1488L112.094 53.9824Z"
          fill="#3381FF"
        />
        <path
          d="M196.877 42.0655V87.5002H176.229V2.02051H188.146L229.143 45.379V2.02051H249.507V87.5002H239.396L196.877 42.0655Z"
          fill="#3381FF"
        />
        <path
          d="M265.576 87.5146V2.03491H302.009C326.113 2.03491 341.628 22.1285 341.628 44.6966C341.628 69.355 324.591 87.5004 298.681 87.5004H265.576V87.5146ZM286.082 20.8771V68.6724H297.302C313.371 68.6724 322.657 57.3102 322.657 45.1232C322.657 32.3815 316.287 20.8771 297.587 20.8771H286.082Z"
          fill="#3381FF"
        />
        <path
          d="M516.626 45.1232L491.825 87.5146H482.966L461.351 44.853L457.326 87.5146H437.93L444.997 2.03491H460.654L489.052 55.7886L520.494 2.03491H534.345L541 87.5146H519.385L516.626 45.1232Z"
          fill="#3381FF"
        />
      </g>
      <path
        d="M371.377 56.3715C375.359 64.8612 385.626 70.9334 395.225 68.971C405.094 66.9516 411.578 62.1166 417.935 55.3192L427.69 69.9522C422.258 78.5272 411.095 85.3103 398.254 87.9411C376.61 92.3637 356.019 82.6084 350.003 53.115C344.102 24.162 357.782 6.30097 380.094 1.75039C405.25 -3.38323 421.149 10.2827 427.946 44.9667L371.377 56.3715ZM383.009 20.1944C375.032 21.8298 369.969 28.7694 369.03 37.9843L403.928 30.8598C402.008 25.6124 396.135 17.5067 383.009 20.1944Z"
        fill="#3381FF"
      />
      <defs>
        <filter
          id="filter0_d_64_13"
          x="0.885254"
          y="1.89258"
          width="544.115"
          height="93.75"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_64_13"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_64_13"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  const BundleItem = ({ name, bundleId, bundle }: IBundleItem) => {
    
    const futureDates = bundle.available_date_times ? Object.keys(bundle.available_date_times).filter((date: string) => {
      return moment(date, "MM/DD/YYYY").isSameOrAfter(moment(), 'day');
    }) : [];

    const availableDateTimesCount = futureDates.length;
  
    return (
      <Box display={"flex"} gap={"15px"}>
        <CalendarTodayIcon sx={{ height: "18px", width: "18px" }} />
        <p
          className={classes.bundleText}
          onClick={() => setCurrentBundle(bundle)}
          style={{
            color: currentBundle?.id === bundleId ? COLORS.blue : bundle.is_published ? "" : "red",
          }}
        >
          {availableDateTimesCount ? `[${availableDateTimesCount}] ${name}` : name}
        </p>
      </Box>
    );
  };
  
  

  const handleTextChange = (text: string) => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      setQuery(text);
    }, 300);
    setTimer(newTimer);
  };

  const Bundles = () => (
    <Box>
      <h6 className={classes.bundlesHeader}>Bundles</h6>
      <InputWithIcon placeholder="Search" handleChange={handleTextChange} />
      <div className={classes.bundlesContainer}>
        {filteredBundles &&
          filteredBundles.map((bundle, index) => (
            <BundleItem
              key={index}
              name={bundle?.bundle_title ?? ""}
              bundleId={bundle?.id ?? -1}
              bundle={bundle}
            />
          ))}
      </div>
    </Box>
  );

  const SearchAndUpload = () => {
    return (
      <Box marginTop={"8%"}>
        <Box
          className={classes.createBundleContainer}
          onClick={() => {
            setCurrentBundle(undefined);
            setCurrentMode(1);
          }}
        >
          <AddCircleOutlineIcon
            sx={{
              marginRight: "6%",
              color: COLORS.blue,
              height: "30px",
              width: "30px",
            }}
          />
          <p className={classes.buttonText}>Create a new Bundle</p>
        </Box>
        <Box className={classes.searchAndUploadContainer}>
          <UploadFileIcon width="30px" height="30px" />
          <p className={classes.uploadText}>Upload Spreadsheet</p>
        </Box>
      </Box>
    );
  };

  const GoToApp = () => (
    <Box className={classes.gotoAppContainer}>
      <Box
        className={classes.gotoAppInnerContainer}
        onClick={() => navigate("/")}
      >
        <ArrowCircleLeftOutlinedIcon
          sx={{ color: COLORS.blue, width: "30px" }}
        />
        <Typography fontSize={"20px"} color={COLORS.blue}>
          Go to App
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box className={classes.container}>
      <Header />
      {Bundles()}
      <SearchAndUpload />
      <SendNotification />
      <GoToApp />
    </Box>
  );
};

export default AdminSidebar;
