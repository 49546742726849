// imports
import styled from '@emotion/styled';

export const containerstyle = {
  padding: '24px 32px',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflowY: 'auto',
};

// styles components
export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
