import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  gotoAppInnerContainer: {
    display: 'flex',
    marginTop: '5%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
