import { useEffect, useState } from 'react';

export const useIsAppInstalled = () => {
  const [isInstalledOnAndroid, setIsInstalledOnAndroid] = useState(false);
  const [isInstalledInIos, setIsInstalledInIos] = useState(false);
  useEffect(() => {
    const windowRef: any = window;
    if (
      windowRef.navigator?.userAgentData?.mobile &&
      windowRef.navigator.userAgentData.platform &&
      windowRef.navigator.userAgentData.platform.toLowerCase() === 'android'
    ) {
      setIsInstalledOnAndroid(true);
    } else {
      setIsInstalledOnAndroid(false);
    }
  }, []);
  useEffect(() => {
    const windowRef: any = window;
    if (windowRef.navigator.standalone) {
      setIsInstalledInIos(true);
    } else {
      setIsInstalledInIos(false);
    }
  }, []);
  return {
    isInstalledOnAndroid,
    isInstalledInIos,
  };
};
