import { useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { LoadingButton } from "@mui/lab";

import Input from "components/Input";

import { useResetPassword } from "hooks/api";

const useStyles = makeStyles({
	container: {
		backgroundColor: "#F9FAFB",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		height: "100%",
		minHeight: "100vh",
		padding: "50px 0",
		color: "#252525",
	},
	paper: {
		marginTop: 8,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		borderRadius: 3,
		backgroundColor: "#ffffff",
		padding: "32px 24px",
		boxShadow: "0px 5px 18px rgba(6, 16, 55, 0.02)",
	},
	avatar: {
		margin: 1,
		marginBottom: 10,
	},
	form: {
		width: "100%",
		marginTop: 10,
	},
	submit: {
		margin: 3,
	},
	textField: {
		paddingBottom: 20,
	},
	link: {
		color: "#0264E1",
	},
	errorMessage: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	disabled: {
		backgroundColor: "grey !important",
	},
});

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const PWD_VALID_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export default function ResetPassword() {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConformPassword] = useState("");
	const classes = useStyles();
	const query = useQuery();

	const { resetPassword } = useResetPassword();

	const onSubmit = (event) => {
		event.preventDefault();
		const token = query.get("token");
		if (token) {
			resetPassword({ password, token });
		}
	};

	const handlePassword = (event) => {
		setPassword(event.target.value);
	};

	const handleConfirmPassword = (event) => {
		setConformPassword(event.target.value);
	};

	return (
		<>
			<Container component="main" maxWidth="xs" className={classes.container}>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5" gutterBottom>
						Reset Password
					</Typography>
					<form className={classes.form} noValidate onSubmit={onSubmit}>
						<Input
							{...{
								value: password,
								onChange: handlePassword,
								fieldName: "password",
								required: true,
								placeholder: "New Password",
								type: "password",
								containerstyle: { width: "100%" },
							}}
						/>
						<Input
							{...{
								value: confirmPassword,
								onChange: handleConfirmPassword,
								fieldName: "confirmPassword",
								required: true,
								placeholder: "Confirm password",
								type: "password",
								containerstyle: { width: "100%", marginBottom: "20px" },
							}}
						/>

						<LoadingButton
							type="submit"
							fullWidth
							variant="contained"
							className={clsx({
								[classes.submit]: true,
								[classes.disabled]: !password?.match(PWD_VALID_REGEX),
							})}
							disabled={
								!(
									password?.match(PWD_VALID_REGEX) &&
									password === confirmPassword
								)
							}
							title="Save Password">
							Save Password
						</LoadingButton>
						<Grid container>
							<Grid item xs>
								<Link href="/" variant="body2">
									Sign In
								</Link>
							</Grid>
							<Grid item>
								<Link href="/sign-up" variant="body2">
									Don't have an account? Sign Up
								</Link>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
		</>
	);
}
