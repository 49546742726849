type TandemLogoType = {
  width?: number | string;
  height?: number | string;
  color?: string;
  backgroundColor?: string;
};

const TandemLogo = ({ width=28, height=27, color='#ffffff', backgroundColor='#3381ff' }: TandemLogoType) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1080 1080"
      width={width}
      height={height}
      preserveAspectRatio="xMinYMin slice"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        style={{
          fill: `${backgroundColor}`,
          stroke: 'none',
          fillOpacity: 1,
        }}
        id="path461"
        cx="540"
        cy="540"
        r="425" />
        <path
          d="M 729.2662,481.04496 V 417.38544 H 604.83725 v 63.65952 h -62.94024 c -34.72632,0 -52.80552,-23.8788 -52.80552,-49.91544 0,-36.89712 27.48816,-53.53128 60.04368,-53.53128 15.18912,0 28.21392,2.89008 28.21392,2.89008 l 28.9332,-99.10512 c 0,0 -36.89712,-8.6832 -74.51352,-8.6832 -86.08681,0 -170.72856,39.06792 -170.72856,139.61808 0,83.916 60.76944,111.41064 60.76944,111.41064 v 1.43856 c -49.19617,20.25648 -82.47096,70.17192 -82.47096,123.70968 0.006,88.25112 74.52,158.42304 192.43007,158.42304 126.59328,0 197.49096,-75.2328 197.49096,-167.832 V 589.55256 H 793.645 V 481.04496 Z M 604.83725,632.23632 c 0,34.00056 -25.31736,60.0372 -73.062,60.0372 -35.4456,0 -62.94024,-23.14656 -62.94024,-51.36048 0,-39.06144 31.82976,-51.354 65.10456,-51.354 h 70.89768 z"
          id="path43"
          style={{
            fill: `${color}`,
            stroke: 'none',
            strokeWidth: 1,
            fillOpacity: 1,
          }} />
    </svg>
  );
};

export default TandemLogo;
