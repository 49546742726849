// imports
import styled from '@emotion/styled';

// styles
export const HeaderStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
};
export const ButtonStyle = {
	position: 'absolute',
	left: '-30px',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer'
}

// styles components
export const Image = styled.img`
	height: 35px;
`;
