type IFileIcon = {
  width?: string;
  height?: string;
};

const PublishIcon = ({ width = '30', height = '30' }: IFileIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 74 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_69_97)">
      <path
        d="M54.183 28.987L32.6785 50.4916L19.7818 37.5949L24.0705 33.3062L32.6785 41.9141L49.8943 24.6983L54.183 28.987ZM12.6339 36.4999C12.6339 29.4128 15.7364 23.0558 20.603 18.6149L27.8422 25.8541V7.6041H9.5922L16.2839 14.2958C10.3222 19.8316 6.55054 27.7095 6.55054 36.4999C6.55054 52.2862 18.5651 65.2437 33.9255 66.7645V60.6204C21.9414 59.1299 12.6339 48.8795 12.6339 36.4999ZM67.3839 36.4999C67.3839 20.7137 55.3693 7.75618 40.0089 6.23535V12.3795C51.993 13.8699 61.3005 24.1203 61.3005 36.4999C61.3005 43.587 58.198 49.9441 53.3314 54.3849L46.0922 47.1458V65.3958H64.3422L57.6505 58.7041C63.6122 53.1683 67.3839 45.2903 67.3839 36.4999Z"
        fill="#3381FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_69_97">
        <rect
          width="73"
          height="73"
          fill="white"
          transform="translate(0.467285)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PublishIcon;
