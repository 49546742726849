import { makeStyles } from '@mui/styles';
import { COLORS } from 'constants/colors';

const useStyles = makeStyles(() => ({
  bundlesHeader: {
    color: COLORS.blue,
    fontWeight: '700',
    fontSize: '28px',
    margin: 0,
    marginTop: '0.5%',
    marginBottom: '3%',
  },
  container: {
    width: '340px',
    padding: '20px',
    height: '100%',
    background: '#fff',
    boxShadow: '5px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 2,
  },
  bundleText: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: 0,
    height: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    lineHeight: '21px',
  },
  bundlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    height: '45vh',
    overflow: 'auto',
    width: '100%',
    paddingTop: '15px',
  },
  searchAndUploadContainer: {
    paddingLeft: '12px',
    marginTop: '6%',
    display: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    justifyContent: 'flex-end',
  },
  uploadText: {
    margin: 0,
    fontSize: 20,
    color: COLORS.blue,
    marginLeft: '5%',
  },
  buttonText: {
    margin: 0,
    color: COLORS.blue,
    fontSize: '20px',
  },
  createBundleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gotoAppInnerContainer: {
    display: 'flex',
    marginTop: '5%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gotoAppContainer: {
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
