import { IBundleComponent } from 'models/IBundleComponent';
import { Dispatch, SetStateAction } from 'react';

type Dictionary<T> = {
  [Key: string]: T;
};

export type IComponentSection = {
  name: string;
  componentsTrigger: boolean;
  getComponents: Function;
  components: Dictionary<IBundleComponent>;
  setComponents: Dispatch<SetStateAction<Dictionary<IBundleComponent>>>;
  activeComponentIndex: string;
  setActiveComponentIndex: Dispatch<SetStateAction<string>>;
};

export const COMPONENT_TOOLTIP_TEXTS: any = {
  component_type:
    'The category of the component, Food and Drink, Travel, Activity or Landmark',
  component_title:
    'The name used for the specific event element and describes the format and name of the work. For example, "brunch at x restaurant" or "Feist in concert"',
  component_description:
    'A short marketing summary which describes what will occur during the activity.',
  venue_name: 'The title used for a specific location or building.',
  venue_address: 'The particulars of a location or building.',
  is_reservation_available:
    'A yes indicates that advance table bookings are taken, whereas a no indicates no advance reservations are taken.',
  booking_url:
    "The booking_url is the web address for an component's ticket or reservation system.",
  reservation_description:
    'A short text description that describes whether or not the restaurant takes reservations. E.g. "Restaurant does not take reservations".',
  reservation_text:
    'A short description that tells user where the button link will take them. Eg. Book at Ticketmaster, or Reserve at OpenTable',
  reservation_link_title:
    'Reservation link title that appears on the booking button',
  member_offer:
    'Text description of how subscribed members can get a special feature. This could be a discount code, free product or VIP offering.',
  website_url: 'The website_url is the web address for the main activity.',
  menu_url: 'The menu_url is the menu address for the component',
  image_url:
    "Must have a .jpg or .png ending to display in browsers. The web address for the activity's primary marketing image. This image should be free from marketing and promotional text.",
  cost_per_person_low:
    'The lowest price available for a user to attend the activity. Dinner plus a drink per person.',
  cost_per_person_high:
    'The highest price available for a user to attend the activity. Dinner + drinks per person.',
  duration: 'Duration of the component in minutes',
};
