/* eslint-disable react-hooks/exhaustive-deps */
import useStyles from './AdminContent.styles';

import { IAdminContent } from './AdminContent.types';
import BundleDetails from './components/BundleDetails/BundleDetails';
import AdminContentHeader from './components/Header/Header';
import BundleScheduleAndRating from './components/BundleScheduleAndRating/BundleScheduleAndRating';
import BundleComponents from './components/BundleComponents/BundleComponents';
import { useEffect, useState } from 'react';
import { IBundle, IDaveRatings } from 'models/IBundle';
import { IBundleComponent } from 'models/IBundleComponent';
import { useAddBundle, useUpdateBundle } from 'hooks/api';
import moment from 'moment';

type Dictionary<T> = {
  [Key: string]: T;
};

const AdminContent = ({
  bundle,
  mode,
  setCurrentMode,
  refetchAdminBundles,
  bundles,
  setCurrentBundle,
}: IAdminContent) => {
  const classes = useStyles();
  const [newBundle, setNewBundle] = useState<IBundle>({
    bundle_image: '',
    bundle_summary: '',
    bundle_title: '',
    components: {},
    end_datetime: moment().toISOString(),
    is_published: false,
    keywords: '',
    map_url: '',
    slug: '',
    start_datetime: moment().toISOString(),
    bundle_video: '',
    available_date_times: {},
    duration: '',
    creation_credit: '',
    is_itinerary_created: false,
    is_sponsored: false,
    has_any_element_sold: false,
  });
  const [bundleDetailsTrigger, setBundleDetailsTrigger] = useState(false);
  const [keywordsTrigger, setKeywordsTrigger] = useState(false);
  const [componentsTrigger, setComponentsTrigger] = useState(false);
  const [keywords, setKeywords] = useState<any>();
  const [internalKeywords, setInternalKeywords] = useState<any>();
  const [scheduledDates, setScheduledDates] = useState<any>();
  const [bundleDetails, setBundleDetails] = useState<any>();
  const [daveRatings, setDaveRatings] = useState<IDaveRatings | undefined>();
  const [components, setComponents] = useState<any>();
  const [bundleTitle, setBundleTitle] = useState<string | undefined>();
  const { addBundle } = useAddBundle();
  const { updateBundle } = useUpdateBundle();

  const addOrUpdateBundle = async (bundleObject: IBundle) => {
    if (mode === 1) {
      await addBundle(bundleObject);
    } else {
      await updateBundle(bundleObject);
    }
    refetchAdminBundles();
  };

  useEffect(() => {
    if (mode === 1 && bundles && newBundle.slug !== '') {
      for (let i = 0; i < bundles.length; i++) {
        const bundleTemp: IBundle = bundles[i];
        if (newBundle.slug === bundleTemp.slug) {
          setCurrentBundle(bundleTemp);
          break;
        }
      }
    }
  }, [bundles]);

  useEffect(() => {
    if (
      !keywordsTrigger &&
      !componentsTrigger &&
      !bundleDetailsTrigger &&
      bundleDetails !== undefined &&
      keywords !== undefined &&
      components !== undefined &&
      scheduledDates !== undefined
    ) {
      const newBundleObject: IBundle = { ...newBundle };
      newBundleObject.bundle_title = bundleTitle ?? '';
      newBundleObject.bundle_image = bundleDetails.bundle_image;
      newBundleObject.bundle_summary = bundleDetails.bundle_summary;
      newBundleObject.bundle_video = bundleDetails.bundle_video;
      newBundleObject.duration = bundleDetails.duration;
      newBundleObject.is_published = bundleDetails.is_published;
      newBundleObject.map_url = bundleDetails.map_url;
      newBundleObject.slug = bundleDetails.slug;
      newBundleObject.components = components;
      newBundleObject.keywords = keywords;
      newBundleObject.internal_keywords = internalKeywords;
      newBundleObject.available_date_times = scheduledDates ?? {};
      newBundleObject.dave_ratings = daveRatings ?? {
        Stimulation: 0,
        Formality: 0,
        Cerebration: 0,
        Physicality: 0,
      };
      newBundleObject.creation_credit = bundleDetails.creation_credit;
      newBundleObject.is_itinerary_created = bundleDetails.is_itinerary_created;
      newBundleObject.is_sponsored = bundleDetails.is_sponsored;
      newBundleObject.has_any_element_sold = bundleDetails.has_any_element_sold;
      setNewBundle({
        ...newBundle,
        ...newBundleObject,
      });

      setBundleDetails(undefined);
      setKeywords(undefined);
      setComponents(undefined);
      setBundleTitle(undefined);
      setScheduledDates(undefined);
      setDaveRatings(undefined);
      if (bundle && bundle.id !== undefined) {
        newBundleObject.id = bundle.id;
      }
      addOrUpdateBundle(newBundleObject);
    }
  }, [keywordsTrigger, componentsTrigger, bundleDetailsTrigger]);

  const getBundleDetails = (details: any) => {
    setBundleDetails(details);
    setBundleDetailsTrigger(false);
  };

  const getKeywords = (keywords: string, internalKeywordsTemp: string) => {
    setKeywords(keywords);
    setInternalKeywords(internalKeywordsTemp);
    setKeywordsTrigger(false);
  };

  const getComponents = (components: Dictionary<IBundleComponent>) => {
    setComponents(components);
    setComponentsTrigger(false);
  };

  const getScheduledDates = (dates: any) => {
    setScheduledDates(dates);
  };

  const getDaveRatings = (ratings: IDaveRatings) => {
    setDaveRatings(ratings);
  };

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <AdminContentHeader
          setBundleDetailsTrigger={setBundleDetailsTrigger}
          setKeywordsTrigger={setKeywordsTrigger}
          setComponentsTrigger={setComponentsTrigger}
          setBundleTitle={setBundleTitle}
          bundle={bundle}
          setCurrentBundle={setCurrentBundle}
          mode={mode}
          setCurrentMode={setCurrentMode}
          refetchAdminBundles={refetchAdminBundles}
        />
        <div style={{ display: 'flex', width: '100%' }}>
          <div>
            <BundleDetails
              mode={mode}
              getBundleDetails={getBundleDetails}
              bundleDetailsTrigger={bundleDetailsTrigger}
              bundle={bundle}
            />
            <BundleScheduleAndRating
              keywordsTrigger={keywordsTrigger}
              getKeywords={getKeywords}
              getScheduledDates={getScheduledDates}
              bundle={bundle}
              getDaveRatings={getDaveRatings}
            />
          </div>
          <BundleComponents
            getComponents={getComponents}
            componentsTrigger={componentsTrigger}
            bundle={bundle}
            mode={mode}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminContent;
