import { useState } from 'react';
import { IAddDateModal } from './AddDateModal.types';
import { Box, Typography } from '@mui/material';
import { COLORS } from 'constants/colors';
import Modal from '@mui/material/Modal';
import moment from 'moment';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  height: 600,
};

const buttonStyle = {
  backgroundColor: COLORS.blue,
  color: COLORS.white,
  width: '40%',
  border: `1px solid ${COLORS.blue}`,
  height: 30,
  fontFamily: 'inherit',
  fontSize: '16px',
  fontWeight: '500',
  cursor: 'pointer',
};

const AddDateModal = ({ open, setOpen, saveDate, saveTextArea }: IAddDateModal) => {
  const [date, setDate] = useState(moment().toISOString());
  const [textAreaValue, setTextAreaValue] = useState('');
  const [showWarning, setShowWarning] = useState(false);

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value.replace(/(\r\n|\r|\n){3,}/g, '$1$1').replace(/"/g, '');
    const textLines = newValue
      .trim().replace(/"/g, '')
      .split(/\r?\n/)
      .filter((date) => date.trim() !== '');
    const hasInvalidDate = textLines.some((dateString) => !moment(dateString).isValid());
    setShowWarning(hasInvalidDate);
    setTextAreaValue(newValue);
  };

  const handleSaveTextArea = () => {
    setOpen(false);
    saveTextArea(textAreaValue);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          color={COLORS.blue}
          fontWeight={'bold'}
          textAlign={'center'}
          fontSize={'24px'}
        >
          Select DateTime
        </Typography>
        <input
          id="datetime-local"
          type="datetime-local"
          style={{ width: 220, fontFamily: 'inherit', padding: '10px' }}
          value={date}
          onChange={(e: any) => setDate(e.target.value)}
        />
        <button
          onClick={() => {
            setOpen(false);
            saveDate(date);
          }}
          style={buttonStyle}
        >
          Save Date
        </button>
        <textarea
          rows={25}
          cols={40}
          value={textAreaValue}
          onChange={handleTextAreaChange}
          placeholder="Paste your list of date-times here. Using a generator spreadsheet is advised."
        />
        {showWarning && (
          <Typography variant="body2" color="error">
            Warning: Invalid entries
          </Typography>
        )}
        <button onClick={handleSaveTextArea} style={buttonStyle}>
          Save List
        </button>
      </Box>
    </Modal>
  );
};

export default AddDateModal;
