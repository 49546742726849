import React, { useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import VideoItem from 'components/VideoItem';

interface Props {
  children?: React.ReactNode;
  bundle: any;
}

const ImageSection: React.FC<Props> = ({ bundle }) => {
  const [selected, setSelected] = useState<number>(0);

  const divStyle: React.CSSProperties = {
    height: '234px',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  };

  return (
    <>
      <div style={divStyle}>
        <Carousel
          autoFocus
          swipeable
          infiniteLoop
          interval={4000}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={selected}
          onChange={(index) => setSelected(index)}
        >
          {bundle?.bundle_video
            ? [1, 2].map((num, index) =>
                num === 2 ? (
                  <div style={{ height: '100%' }} key={index}>
                    <VideoItem
                      url={bundle?.bundle_video}
                      videoHeight="234px"
                      selected={selected}
                      currentIndex={index}
                    />
                  </div>
                ) : (
                  <div key={index}>
                    <img
                      src={bundle?.bundle_image}
                      alt="bundle_image"
                      width="100%"
                      height="100%"
                    />
                  </div>
                )
              )
            : [1].map((key) => (
                <div key={key} style={{ minHeight: '234px' }}>
                  <img
                    style={{ minHeight: '234px' }}
                    src={bundle?.bundle_image}
                    alt="bundle_image"
                    width="100%"
                    height="100%"
                  />
                </div>
              ))}
        </Carousel>
      </div>
    </>
  );
};

export default ImageSection;
