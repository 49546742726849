import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import Fab from '@mui/material/Fab';

const backButtonStyle: React.CSSProperties = {
  backgroundColor: 'white',
  color: '#1C3461',
  position: 'fixed',
  marginLeft: '10px',
  marginTop: '10px',
};

const BackFab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Fab
      size="medium"
      aria-label="back"
      onClick={() => {
        const doesAnyHistoryEntryExist = location.key !== "default";
        if (doesAnyHistoryEntryExist) {
          navigate(-1);
        } else {
          navigate('..');
        }
      }}
      sx={backButtonStyle}
    >
      <ArrowBackIcon />
    </Fab>
  );
};

export default BackFab;
