/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { UserContext } from 'context/User';
import { IUpdatePostalCodeProps, useUpdateUserPostalCode } from 'hooks/api';
import { getUserApi } from 'api';
import { Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { toast } from 'react-toastify';
import { SelectChangeEvent } from '@mui/material/Select';
import { COLORS } from 'constants/colors'; 

type IEditPostalCode = {
  handleSubmit?: Function;
  mode: number;
  showNextButton?: boolean;
};

const EditPostalCode = ({
  handleSubmit,
  mode,
  showNextButton = false,
}: IEditPostalCode) => {
  const { userState, setUserState } = useContext(UserContext);
  const { updatePostalCode } = useUpdateUserPostalCode();
  const [city, setCity] = useState('Toronto');
  const [lastCity, setLastCity] = useState('Toronto');

  const handleCityChange = (event: SelectChangeEvent<string>) => {
    setCity(event.target.value as string);
    submitCity();
  };
  
  const submitCity = async () => {
    if (city !== '') {
      const apiData: IUpdatePostalCodeProps = {
        email: userState.email,
        postal_code: city,
      };
      await updatePostalCode(apiData).then((data) => {
        toast.success(
          data?.data?.message ?? 'Operation completed successfully.'
        );
      });
      await getUserApi().then((data: any) => {
        const user = data.data.user;

        setUserState({
          ...user,
          isAuthenticated: true,
          isInitialApiFetched: true,
          subscribedStatus: data?.subscribedStatus,
          daysLeft: Math.ceil(data.daysLeft),
        });
      });
      setLastCity(city);
    } else {
      toast.dismiss();
      toast.error('City cannot be empty.');
    }
  };

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'center'}}
      >
      </div>
      <div
        style={{
          paddingLeft: '32px',
          paddingRight: '32px',
          paddingBottom: '5px',
          margin: '16px 0px',
        }}
      >
        <FormControl 
        fullWidth 
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '.MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.blue, borderRadius: '10px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.blue, borderRadius: '10px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.blue, borderRadius: '10px',
            },
          },
        }}
      >
        <InputLabel id="city-select-label">City</InputLabel>
        <Select
          labelId="city-select-label"
          id="city-select"
          value={city}
          label="City"
          onChange={handleCityChange}
        >
          <MenuItem value="Toronto">Toronto</MenuItem>
          <MenuItem value="Chicago (coming soon)" disabled>Chicago (coming soon)</MenuItem>
          <MenuItem value="Montreal (coming soon)" disabled>Montreal (coming soon)</MenuItem>
          <MenuItem value="New York (coming soon)" disabled>New York (coming soon)</MenuItem>
          <MenuItem value="Seattle (coming soon)" disabled>Seattle (coming soon)</MenuItem>
          <MenuItem value="Vancouver (coming soon)" disabled>Vancouver (coming soon)</MenuItem>
        </Select>
      </FormControl>
      </div>
      {showNextButton && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '32px',
          }}
        >
          <Button
            onClick={() => {
              handleSubmit && handleSubmit(lastCity);
            }}
            variant="contained"
            disabled={lastCity?.length === 0}
            sx={{
              backgroundColor: '#3381FF !important',
              width: '101.15px',
              fontWeight: '900',
              paddingTop: '11px',
              paddingBottom: '11px',
              fontSize: '13px',
              '&.Mui-disabled': {
                backgroundColor: '#3a3a3a !important',
                color: 'lightgrey',
              },
            }}
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
};
export default EditPostalCode;
