import moment from "moment";

export const isSchedulePassedHandler = ({
  start_time,
  start_date,
}: {
  start_time: string;
  start_date: string;
}) => {
  const date = moment(start_date + " " + start_time, "MM/DD/YYYY hh:mm a");
  const isSchedulePass = moment(date).isBefore(moment());
  return isSchedulePass;
};
