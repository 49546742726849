import { useMemo } from 'react';
import './style.css';
import GoogleIcon from 'assets/google-icon';
import { useGoogleLogin } from '@react-oauth/google';

type IGoogleAuthType = {
  isSignIn?: boolean;
};

const GoogleAuth = ({ isSignIn = true }: IGoogleAuthType) => {
  const login = useGoogleLogin({
    ux_mode: 'redirect',
    flow: 'auth-code',
  });

  const socialButtonStyle: React.CSSProperties = useMemo(
    () => ({
      padding: '5px 15px 5px 10px',
      border: '1px solid #7A7A7A',
      backgroundColor: '#FFF',
      borderRadius: '5px',
      outline: 'none',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '16px',
      cursor: 'pointer',
      height: '40px',
      gap: '10px',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    }),
    []
  );

  return (
    <button type="button" style={socialButtonStyle} onClick={() => login()}>
      <GoogleIcon /> {isSignIn ? 'Sign in' : 'Sign up'} with Google
    </button>
  );
};

export default GoogleAuth;
