import React from 'react';

import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

interface Props {
  children?: React.ReactNode;
  bundle: any;
}

const SummarySection: React.FC<Props> = ({ bundle }) => {
  return (
    <Container sx={{ my: 3 }}>
      <Typography component='h1' variant='h5' sx={{ my: 2 }}>
        {bundle?.bundle_title || (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Typography>
      {bundle?.creation_credit && (
        <Typography variant='subtitle2'>
          Curated by {bundle?.creation_credit}
        </Typography>
      )}
      <Typography variant='body2' color="text.secondary">
        {bundle?.bundle_summary || (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Typography>
    </Container>
  );
};

export default SummarySection;
