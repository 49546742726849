import { useState } from "react";
import { Modal, Button } from "@mui/material";
import { Input as MUIInput, Box, Typography } from "@mui/material";
import { useSendNotification, useSendToAllUserNotification } from "hooks/api";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { COLORS } from "constants/colors";
import useStyle from "./NotificationStyle";
import { toast } from "react-toastify";
import UserConfirmationDialog from "../../../../components/UserConfirmationDialog/UserConfirmationDialog";

export const SendNotification = () => {
  const [open, setOpen] = useState(false);
  const [warning, setWarning] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const { mutateAsync } = useSendNotification();
  const { mutateAsync: sendNotifuicationToAllUserMutation } =
    useSendToAllUserNotification();
  const [sendingType, setSendingType] = useState<"email" | "all_users">(
    "email"
  );
  const classes = useStyle();
  const getFormattedUrl=(url:string)=>{
    let formatted_url:string | null=null
    if(url && url.trim().length>0){
      const currentURL = window.location.href;
      const parts = currentURL.split('/');
      const baseURL = parts.slice(0, 3).join('/') + '/';
      if(!url.startsWith(baseURL)){
        toast.error("Invalid url");
        return null
      }
      const remainingPath = url.substring(baseURL.length);
      formatted_url=remainingPath
      if((formatted_url || "").length<1 ){
        toast.error("url can not be default url");
        return null
      }
    }
    return formatted_url
  }
  const sendNotificationConfirm = async () => {
    let formatted_url = ""
    if(url && url.trim().length>0){
      const short_url = getFormattedUrl(url)
      if(!short_url) return
      formatted_url=short_url
    }
    

    try {
      await sendNotifuicationToAllUserMutation({ message, title, url:formatted_url });
      setWarning(false);
    } catch {
      setWarning(false);
    }
    setMessage("");
    setEmail("");
    setTitle("");
    setUrl("");
    setWarning(false);
  };
  const SendNotificationHandler = async () => {
    let formatted_url = ""
    if(url && url.trim().length>0){
      const short_url = getFormattedUrl(url)
      if(!short_url) return
      formatted_url=short_url
    }

    if (sendingType === "all_users") {
      setOpen(false);
      setWarning(true);
      return;
    }
   
    try {
      await mutateAsync({ email, message, title, url:formatted_url });
      setOpen(false);
    } catch {
      setOpen(false);
    }
    setMessage("");
    setEmail("");
    setTitle("");
    setUrl("");
  };
  return (
    <Box>
      <UserConfirmationDialog
        open={warning}
        setOpen={setWarning}
        handleConfirmationClick={sendNotificationConfirm}
        cancelText="cancel"
        confirmationText="send to all users"
        headerText="Warning"
        paragraphText="You are going to send notification to all of your user. please confirm before proceeding."
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: 500,
            minHeight: 300,
            backgroundColor: "white",
            padding: 10,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "10%",
          }}
        >
          <Typography
            style={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}
          >
            Send Notification
          </Typography>
          {sendingType === "email" && (
            <MUIInput
              value={email}
              placeholder="Email"
              style={{ width: "100%", marginTop: 20 }}
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
          )}
          <MUIInput
            value={title}
            placeholder="Title"
            style={{ width: "100%", marginTop: 20 }}
            onChange={(event) => setTitle(event.currentTarget.value)}
          />
          <MUIInput
            value={message}
            placeholder="Message"
            style={{ width: "100%", marginTop: 20 }}
            onChange={(event) => setMessage(event.currentTarget.value)}
          />
          <MUIInput
            value={url}
            placeholder="Redirect Url"
            style={{ width: "100%", marginTop: 20 }}
            onChange={(event) => setUrl(event.currentTarget.value)}
          />
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#000" }}
              onClick={SendNotificationHandler}
              disabled={
                title.length === 0 ||
                message.length === 0 ||
                (sendingType === "email" && email.length === 0)
              }
            >
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ccc" }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <div
        onClick={() => {
          setOpen(true);
          setSendingType("all_users");
        }}
      >
        <Box className={classes.gotoAppInnerContainer}>
          <ArrowCircleLeftOutlinedIcon
            sx={{ color: COLORS.blue, width: "30px" }}
          />
          <Typography fontSize={"20px"} color={COLORS.blue}>
            Send Notification To All User
          </Typography>
        </Box>
      </div>

      <div
        onClick={() => {
          setOpen(true);
          setSendingType("email");
        }}
      >
        <Box className={classes.gotoAppInnerContainer}>
          <ArrowCircleLeftOutlinedIcon
            sx={{ color: COLORS.blue, width: "30px" }}
          />
          <Typography fontSize={"20px"} color={COLORS.blue}>
            Send Test Notification
          </Typography>
        </Box>
      </div>
    </Box>
  );
};
