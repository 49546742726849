// imports
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// assets
import HeaderImg from './assets/header.png';

// mui components
import { Box, Button, Typography } from '@mui/material';

// styles
import { ButtonStyle, HeaderStyle, Image } from './styles';

// icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// types
import { Props } from './types';

const Header: React.FC<Props> = ({ back }) => {
  // navigate
  const navigate = useNavigate();

  // handle back click
  const handleBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box width="100%" display="flex" flexDirection="column" marginTop="38px">
      <Box sx={HeaderStyle}>
        {back && (
          <Button onClick={handleBackClick} sx={ButtonStyle}>
            <ArrowBackIosNewIcon fontSize="small" /> Back
          </Button>
        )}
        <Image
          src={HeaderImg}
          alt="header"
          width="122px"
          style={{ height: '25px' }}
        />
      </Box>
      <Typography
        marginTop="2px"
        fontSize="18px"
        fontWeight={400}
        textAlign="center"
      >
        Date Night Made Easy
      </Typography>
    </Box>
  );
};

export default Header;
