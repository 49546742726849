import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CancelSubscriptionConfirmation = ({
  open,
  setOpen,
  onConfirmation,
}: any) => {
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = async () => {
    setLoading(true);
    await onConfirmation();
    setOpen(false);
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      {loading && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <DialogTitle id="responsive-dialog-title" style={{ fontWeight: 800 }}>
        {'Cancel Subscription?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel your subscription?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          variant="contained"
          style={{ textTransform: 'capitalize' }}
        >
          No
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
          variant="contained"
          style={{ backgroundColor: 'red', textTransform: 'capitalize' }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionConfirmation;
