import { IKUpload, IKContext } from 'imagekitio-react';
import { toast } from 'react-toastify';

type IImageUpload = {
  imageUploadRef: any;
  onSuccess: any;
  folderName: string;
};

const ImageUpload = ({
  imageUploadRef,
  onSuccess,
  folderName,
}: IImageUpload) => {
  const onError = (err: any) => toast.error('Something went wrong');

  return (
    <IKContext
      publicKey="public_65daLrjPfwv90uYhxohONj+1fEA="
      urlEndpoint="https://ik.imagekit.io/tandemexperiences"
      authenticationEndpoint={`${process.env.REACT_APP_BASE_URL}/api/user/upload-image-auth`}
    >
      <IKUpload
        fileName="profile_pic.png"
        folder={folderName}
        onError={onError}
        onSuccess={onSuccess}
        inputRef={imageUploadRef}
        style={{ display: 'none' }}
      />
    </IKContext>
  );
};

export default ImageUpload;
