import React from 'react';
import { isIOS } from 'utils/isIos';
import { isAndroid } from 'utils/isAndroid';
import { Box, Typography } from '@mui/material';
import TandemLogo from 'assets/tandem-logo';
import { toast } from 'react-toastify';
import { COLORS } from 'constants/colors';

interface Props {
  children?: React.ReactNode;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

let deferredPrompt: any = null;
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
});

const GetTandemApp: React.FC<Props> = ({ setOpen }) => {
  const installPwaPrompt = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        deferredPrompt = null;
      }
    } else {
      toast.dismiss();
      toast.info(
        "Seems like you already installed the app. If you still can't see the app on your home screen please contact us."
      );
    }
  };

  return isAndroid() || isIOS() ? (
    <>
      <Box
        marginTop="20px"
        display="flex"
        flexDirection="column"
        gap="10px"
        border={`1px solid ${COLORS.blue}`}
        borderRadius="10px"
        marginX="32px"
        paddingX="20px"
        paddingY="20px"
      >
        <Typography fontSize="16px" lineHeight="19px" fontWeight="700">
          Get the app
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <TandemLogo width={20} height={20} />
          <Typography
            marginLeft="10px"
            fontSize="14px"
            lineHeight="16px"
            onClick={isIOS() ? () => setOpen(true) : () => installPwaPrompt()}
          >
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              Save to Home Screen
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  ) : null;
};

export default GetTandemApp;
