import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './navigation/MainRouter';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { GoogleOAuthProvider } from '@react-oauth/google';
import moment from 'moment';
import useRedirectBrowser from "hooks/webkit/useRedirect";

import 'moment-timezone';
moment.tz && moment.tz.setDefault('America/Toronto');

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 10,  // 10 min
      },
    },
  });

  const isWebView = /WebView/.test(navigator.userAgent);
  useRedirectBrowser();

  // Initialize Firebase (nothing to do yet though)
  import('helpers/firebase');

  return (
    <GoogleOAuthProvider
      clientId={
        (isWebView
          ? process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID
          : process.env.REACT_APP_GOOGLE_CLIENT_ID) || ""
      }
    >
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <BrowserRouter>
          <QueryClientProvider client={queryClient} contextSharing>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MainRouter />
            </LocalizationProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </CacheBuster>
    </GoogleOAuthProvider>
  );
};

export default App;
