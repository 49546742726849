import { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import BackIcon from 'assets/back-icon';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { LoadingButton } from '@mui/lab';
import { COLORS } from 'constants/colors';
import { useNavigate } from 'react-router-dom';

import { isValidEmail } from 'utils/email';

import Input from 'components/Input';

import { useForgotPassword } from 'hooks/api';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#F9FAFB',
    height: '100%',
    minHeight: '100vh',
    padding: '50px 0',
    color: '#252525',
  },
  paper: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    padding: '32px 24px',
    boxShadow: '0px 5px 18px rgba(6, 16, 55, 0.02)',
  },
  avatar: {
    margin: 1,
    marginBottom: 10,
  },
  form: {
    width: '100%',
    marginTop: 10,
  },
  submit: {
    margin: 3,
  },
  link: {
    color: '#0264E1',
  },
  emailInput: {
    marginBottom: '20px',
  },
  backIconContainerStyle: {
    width: '100%',
  },
  backIconStyle: {
    backgroundColor: COLORS.grey,
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();
  const { forgotPassword } = useForgotPassword();

  const onSubmit = (event: any) => {
    event.preventDefault();
    forgotPassword({ email });
  };

  const handleChange = (event: any) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.backIconContainerStyle}>
            <div onClick={() => navigate(-1)} className={classes.backIconStyle}>
              <BackIcon />
            </div>
          </div>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" gutterBottom>
            Forgot Password
          </Typography>
          <Typography component="p" gutterBottom>
            Enter your email address below and we'll send you a link to reset
            your password
          </Typography>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <Input
              customlabel="Email"
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
              placeholder="Your email"
              className={classes.emailInput}
              containerstyle={{ width: '100%' }}
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!isValidEmail(email)}
            >
              Reset Password
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2" className={classes.link}>
                  Sign In
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
