type ICrossIcon = {
  width?: string;
  height?: string;
};

const CrossIcon = ({ width = '25', height = '25' }: ICrossIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4453 6.63672L6.44531 18.6367"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.44531 6.63672L18.4453 18.6367"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
