import { useState, createContext } from 'react';

import {
  IUserStateProps,
  IContextProviderProps,
  IContext,
  IAppState,
} from './types';
import {
  UserInitialValues,
  AppInitialValues,
  ContextInitialValues,
} from './initialValues';

export const UserContext = createContext<IContext>(ContextInitialValues);

export const UserContextProvider = ({ children }: IContextProviderProps) => {
  const [userState, setUserState] = useState<IUserStateProps>({
    ...UserInitialValues.userState,
  });
  const [appState, setAppState] = useState<IAppState>({
    ...AppInitialValues.appState,
  });
  return (
    <UserContext.Provider
      value={{ userState, setUserState, appState, setAppState }}
    >
      {children}
    </UserContext.Provider>
  );
};
