export const COLORS = {
  primary: '#2196f3',
  secondary: '#e3f2fd',
  white: '#ffffff',
  black: '#000000',
  transparentYellow: 'rgba(255,255,0, 0.2)',
  grey: '#DADADA',
  blue: '#3381FF',
  darkBlue: '#1B3461',
  darkGray: '#7A7A7A',
  blue900: '#1A3362',
  lightBlue: '#cbe3f0',
  lightblue: '#cbe3f0',
  lightGrey: '#D9D9D9',
  red: 'red',
};
