/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { UserContext } from 'context/User';
import { useGetPartners } from 'hooks/api';
import React, { useContext, useLayoutEffect, useState } from 'react';
import ConnectModal from './components/ConnectModal';
import ConnectWithPartner from './components/ConnectWithPartner';
import Header from './components/Header';
import ShowMatches from './components/ShowMatches';
import { removePartner } from 'api';


import "./styles/index.css";

interface Props {
    children?: React.ReactNode;
}

const Preferences: React.FC<Props> = () => {
    const [open, setOpen] = useState(false);
    const { userState } = useContext(UserContext);
    const { partners, refetchPartners } = useGetPartners(userState.email)

    useLayoutEffect(() => {
        refetchPartners()
    }, [refetchPartners])

    const handleRemovePartner = async (partnerId: string) => {
        await removePartner({ email: userState.email, connectionId: partnerId })
        refetchPartners()
    }

    const Description = () => {
        return (
        <div className='connections-description'>
            Connect with a partner or friend to get recommendations optimized for your combined interests. 
        </div>
        );
    }

    return (
        <Box display="flex" flexDirection="column" padding="24px">
            <Header />
            <Description />
            <ConnectWithPartner setOpen={setOpen} partners={partners?.data?.data} handleRemovePartner={handleRemovePartner} />
            <ShowMatches />
            <ConnectModal open={open} setOpen={setOpen} />
        </Box>
    )
}

export default Preferences;
