import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { toast } from "react-toastify";
import moment from 'moment-timezone';

import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import SvgIcon from '@mui/material/SvgIcon';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import { COLORS } from 'constants/colors';
import TandemLogo from 'assets/tandem-logo';

import { IBundleSummary } from 'models/IBundle'
import { ILogTypeEnum } from 'models/Logs';
import { getBundleSummaryCost } from 'utils/bundle';
import { useSaveBundle, useUnsaveBundle } from 'hooks/api';
import { useCreateLog } from 'hooks/api/logs';

interface Props {
  bundle: IBundleSummary;
}

const getBundleThumbnailUrl = (imageUrl: string, property: string, value: string) => {
  const url = new URL(imageUrl);
  url.searchParams.set(property, value);
  return url.toString();
}

const BundleSummary: React.FC<Props> = ({
  bundle,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { saveBundle, saveBundleLoading: isSaving } = useSaveBundle();
  const { unsaveBundle, isLoading: isUnsaving } = useUnsaveBundle();
  const isLoading = isSaving || isUnsaving;
  const { addLog } = useCreateLog();

  const handleImageLoaded = () => {
    setIsImageLoaded(true);
  }

  const handleBundleClick = () => {
    if (bundle && bundle.id && bundle.bundle_title) {
      try {
        addLog({
          log_type: ILogTypeEnum.BUNDLE_CLICK,
          data: {
            bundle_id: bundle.id,
            bundle_title: bundle.bundle_title
          }
        })
      } catch(e) {
        console.log(e)
      }
    }
  }

  const handleSaveClick = (event: any) => {
    console.log("DEBUG: clicked saved for bundle:", bundle);
    // Ensure click doesn't bubble up to card
    event.stopPropagation();

    if (!bundle) {
      return;
    }
    if (bundle.isSaved) {
      // Unsaving
      unsaveBundle({ id: bundle.id }).catch((err) => {
        toast.error("Error unsaving experience");
      })
    } else {
      // Saving
      saveBundle({ bundle_id: bundle.id }).catch((err) => {
        toast.error("Error saving experience");
      })
    }
  };

  if (!bundle) {
    return null;
  }

  // Custom rendering for featured/Tandem events
  if (bundle.is_itinerary_created) {
    return (
      <Card
        raised={true}
        sx={{ borderRadius: '10px', position: 'relative' }}
      >
        <CardActionArea
          component={Link}
          to={`/bundles/${bundle.slug}`}
          onClick={handleBundleClick}
        >
          {!isImageLoaded &&
            <Skeleton
              variant="rectangular"
              sx={{ width: "100px", height: "100%", minWidth: '100px', minHeight: '350px' }}
            />
          }
          <CardMedia
            component="img"
            sx={{ width: "100%", display: isImageLoaded ? 'block' : 'none' }}
            // Get images with dynamic image resizing from imagekit: smaller size, lower quality
            image={getBundleThumbnailUrl(bundle.bundle_image, 'tr', 'w-600,h-350,q-40')}
            onLoad={handleImageLoaded}
            alt={`Image for bundle ${bundle.bundle_title}`}
          />
          <CardContent
            sx={{
              padding: '10px',
              // Avoid excessive bottom padding
              "&:last-child": {
                paddingBottom: '10px',
              }
            }}
          >
            <Typography variant="h6" sx={{
              fontSize: '1.0rem',
              lineHeight: 1.3,
              // Limit to two lines of text
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              {bundle.bundle_title} ({moment(bundle.nextDate).format('ddd, MMM D')})
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" sx={{ fontSize: '14px', marginBottom: '3px' }}>
              {getBundleSummaryCost(bundle)}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{
              fontSize: '14px',
              // Limit to 3 lines of text
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // Make room for tags button
              marginBottom: '10px',
            }}>
              {bundle.bundle_summary}
            </Typography>
            <Stack direction="row" spacing={0.5}>
              {bundle.is_itinerary_created && (
                <Chip color="primary" label="Tandem Exclusive" size="small" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px', color: COLORS.blue }} />
              )}
              {bundle.hasDiscount && (
                <Chip icon={<LocalOfferIcon sx={{ fontSize: '10px' }} />} color="secondary" label="Offer" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
              )}
              {bundle.is_sponsored && (
                <Chip color="primary" label="Sponsored" size="small" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
              )}
              {bundle.has_any_element_sold && (
                <Chip color="primary" label="Sold out" size="small" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
        <IconButton
          color="primary"
          onClick={handleSaveClick}
          disabled={isLoading}
          sx={{ position: 'absolute', top: 2, right: 2 }}
        >
          { isLoading ? <CircularProgress size={24} /> : (bundle.isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />) }
        </IconButton>
        {bundle.is_itinerary_created && (
          <SvgIcon sx={{ position: 'absolute', top: 2, left: 2, fontSize: 48 }}>
            <TandemLogo width={24} height={24} />
          </SvgIcon>
        )}
      </Card>
    )
  }

  return (
    <Card
      raised={true}
      sx={{ borderRadius: '10px', position: 'relative' }}
    >
      <CardActionArea
        component={Link}
        to={`/bundles/${bundle.slug}`}
        onClick={handleBundleClick}
      >
        <Stack direction="row" alignItems="stretch" sx={{ minWidth: 0 }}>
            {!isImageLoaded &&
              <Skeleton
                variant="rectangular"
                sx={{ width: "100px", height: "100%", minWidth: '100px', minHeight: '100px' }}
              />
            }
            <CardMedia
              component="img"
              sx={{ width: "100px", display: isImageLoaded ? 'block' : 'none' }}
              // Get images with dynamic image resizing from imagekit: smaller size, lower quality
              image={getBundleThumbnailUrl(bundle.bundle_image, 'tr', 'w-200,h-200,q-20')}
              onLoad={handleImageLoaded}
              alt={`Image for bundle ${bundle.bundle_title}`}
            />
          <CardContent
            sx={{
              padding: '10px',
              // Avoid excessive bottom padding
              "&:last-child": {
                paddingBottom: '10px',
              }
            }}
          >
            <Typography variant="h6" sx={{
              fontSize: '1.0rem',
              lineHeight: 1.3,
              // Limit to two lines of text
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // Make room for save button
              paddingRight: '40px',
            }}>
              {bundle.bundle_title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" sx={{ fontSize: '14px', marginBottom: '3px' }}>
              {getBundleSummaryCost(bundle)}
            </Typography>
            <Stack direction="row" spacing={0.5}>
              {bundle.hasDiscount && (
                <Chip icon={<LocalOfferIcon sx={{ fontSize: '10px' }} />} color="secondary" label="Offer" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
              )}
              {bundle.is_itinerary_created && (
                <Chip color="primary" label="Featured" size="small" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
              )}
              {bundle.is_sponsored && (
                <Chip color="primary" label="Sponsored" size="small" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
              )}
              {bundle.has_any_element_sold && (
                <Chip color="primary" label="Sold out" size="small" variant="outlined" sx={{ fontSize: "11px", padding: '1px', height: '22px' }} />
              )}
            </Stack>
          </CardContent>
        </Stack>
      </CardActionArea>
      <IconButton
        color="primary"
        onClick={handleSaveClick}
        disabled={isLoading}
        sx={{ position: 'absolute', top: 2, right: 2 }}
      >
        { isLoading ? <CircularProgress size={24} /> : (bundle.isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />) }
      </IconButton>
    </Card>
  )
};

export default BundleSummary;
