type IFileIcon = {
  width?: string;
  height?: string;
};

const FileIcon = ({ width = '30', height = '30' }: IFileIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.7375 0.166504H6.64666C3.24666 0.166504 0.464844 2.94832 0.464844 6.34832V49.621H6.64666V6.34832H43.7375V0.166504ZM40.6466 12.5301L59.1921 31.0756V61.9846C59.1921 65.3846 56.4102 68.1664 53.0102 68.1664H18.9794C15.5794 68.1664 12.8285 65.3846 12.8285 61.9846L12.8594 18.7119C12.8594 15.3119 15.6103 12.5301 19.0103 12.5301H40.6466ZM37.5557 34.1665H54.5557L37.5557 17.1665V34.1665Z"
      fill="#3381FF"
    />
  </svg>
);

export default FileIcon;
