// imports
import styled from '@emotion/styled';

export const BoxStyle = {};
export const NavContainerStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  // Add padding to ensure we don't overlap safe areas
  paddingBottom: 'env(safe-area-inset-bottom, 0)',
};
// export const NavContainerStyle = {};
// export const BottomNavStyle = { paddingBottom: 'env(safe-area-inset-bottom, 0)' };
export const BottomNavStyle = {};

// styles components
export const Image = styled.img`
  height: 35px;
`;
