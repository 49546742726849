import { PopupButton } from '@typeform/embed-react';
import { ITypeFormQuizForm } from './TypeFormQuizForm.types';

const TypeFormQuizForm = ({
  userId,
  buttonText,
  buttonStyle,
  handleSubmit,
}: ITypeFormQuizForm) => {
  const typeformlink = `https://form.typeform.com/to/wGAnBRLN#user_id=${userId}`;
  return (
    <PopupButton
      id={typeformlink}
      style={{ ...buttonStyle }}
      className="my-button"
      onSubmit={() => handleSubmit && handleSubmit()}
    >
      {buttonText}
    </PopupButton>
  );
};

export default TypeFormQuizForm;
