// imports
import React, { useState, createContext } from 'react';

// initial values
import { InitialValues } from './initialValues';

// types
import { InitialValuesTypes, Props } from './types';

const Context = createContext<InitialValuesTypes>(InitialValues);

export function ContextProvider({ children }: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Context.Provider
      value={{
        showModal,
        setShowModal,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
