import { useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { toast } from 'react-toastify';

import { UserContext } from 'context/User';
import { apiErrorHandler } from 'utils/apiErrorHandler';

import {
  getSavedBundlesApi,
  saveBundleApi,
  unsaveBundleApi,
} from 'api';

export interface ISaveBundleProps {
  bundle_id: number;
}

export interface IUnsaveBundleProps {
  id: number;
}

export const useGetSavedBundles = () => {
  const { userState } = useContext(UserContext);
  const { data, error, isLoading, refetch } = useQuery(
    ['bundles', 'saved'],
    () => getSavedBundlesApi(),
    {
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        console.error('====>>> error', error?.response?.data?.errors);
      },
      enabled: userState.isAuthenticated,
    }
  );
  return {
    isLoading,
    dates: data?.data?.bundles,
    error,
    refetchUserBundles: refetch,
  };
};

export const useSaveBundle = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: saveBundle, isLoading: saveBundleLoading } =
    useMutation((values: ISaveBundleProps) => saveBundleApi(values), {
      onSuccess: () => {
        // Invalidate bundle data and bundle lists
        // Return promise to keep loading until full refresh
        // see: https://stackoverflow.com/questions/67751693/how-to-show-spinner-during-mutation-and-refetching
        return queryClient.invalidateQueries({
          queryKey: ['bundles'],
        })
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
      },
    });
  return {
    saveBundleLoading,
    saveBundle,
  };
};

export const useUnsaveBundle = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: unsaveBundle, isLoading } = useMutation(
    (values: IUnsaveBundleProps) => unsaveBundleApi(values),
    {
      onSuccess: () => {
        // Invalidate bundle data and bundle lists
        // Return promise to keep loading until full refresh
        // see: https://stackoverflow.com/questions/67751693/how-to-show-spinner-during-mutation-and-refetching
        return queryClient.invalidateQueries({
          queryKey: ['bundles'],
        })
      },
      onError: (error: any) => {
        toast.error(apiErrorHandler(error));
        console.error('====>>> error', error?.response?.data?.errors);
      },
    }
  );
  return {
    isLoading,
    unsaveBundle,
  };
};
