/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import { UserContext } from 'context/User';

import Box from '@mui/material/Box';
import { Modal, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import { getCheckoutSession } from 'api';

import { useStyles } from './Checkout.styles';

export const Checkout: React.FC<any> = ({ openModal, setOpen }) => {
  const classes = useStyles();
  const { userState } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      userState?.isAuthenticated &&
      ['active', 'canceled'].includes(userState.subscribedStatus)
    ) {
      setOpen(false);
    }
  }, [userState.isAuthenticated, userState.subscribedStatus]);

  const subscribeApp = async () => {
    try {
      setLoading(true);
      const data = await getCheckoutSession();
      const url = data?.data?.url;
      setLoading(false);
      window.location.href = url;
    } catch (err) {
      toast.error('internal server error.');
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  let title = 'Free Trial Ended';
  let content =
    'Your free trial has ended. Subscribe now to continue enjoying access and perks.';

  if (userState.subscribedStatus === 'trial') {
    title = 'Subscribe';
    content = `Your free trial will expire in ${userState?.daysLeft} days. Click subscribe button to upgrade.`;
  }
  return (
    <Modal open={openModal}>
      <Box className={classes.banner}>
        {userState.subscribedStatus !== 'trial_ended' && (
          <Box onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </Box>
        )}
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.subTitle}>{content}</Typography>
        <LoadingButton
          variant="contained"
          onClick={subscribeApp}
          sx={{
            height: '45px',
            width: '100%',
            fontWeigh: '600',
          }}
          loading={loading}
        >
          Subscribe
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default Checkout;
