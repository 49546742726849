import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { getAnalytics, logEvent as logFirebaseEvent, setUserId as setFirebaseUserId } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDYn-W5EPjZf4KexHyGnTUcXTyqTd6oXPo",
  authDomain: "tandem-notifications.firebaseapp.com",
  projectId: "tandem-notifications",
  storageBucket: "tandem-notifications.appspot.com",
  messagingSenderId: "1031326330868",
  appId: "1:1031326330868:web:3167b45b74cbfb6d06d3a1",
  measurementId: "G-T8BZXVCQ5W"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Analytics
export const analytics = getAnalytics(app);

// Wrap several firebase analytics functions to make it easier to import and call
export const logEvent = (eventName: string, eventParams: any = null, options: any = null) => {
  return logFirebaseEvent(analytics, eventName, eventParams, options);
}
export const setUserId = (userId: string) => {
  return setFirebaseUserId(analytics, userId);
}
// Can also set Firebase custom user properties: https://console.firebase.google.com/project/_/analytics/userproperty

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

const requestPermission = () => {
  try {
    Notification.requestPermission();
  } catch (e) {
    // Notification not defined, or other error
    console.log("Error with Notification.requestPermission:", String(e))
  }
};

export const getFirebaseDeviceKey = async () => {
  console.log("DEBUG: GettingFirebaseDeviceKey");
  let messaging = null;
  try {
    messaging = getMessaging();
    console.log("DEBUG: GettingFirebaseDeviceKey MessageReceived");
  } catch (e) {
    return null;
  }
  return await getToken(messaging, {
    vapidKey: `${process.env.REACT_APP_FIREBASE_VAPID_KEY}`,
  })
    .then((currentToken) => {
      console.log("DEBUG: GettingFirebaseDeviceKey currentToken",currentToken)
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log("DEBUG: requestPermission");
        requestPermission();
      }
      return currentToken;
    })
    .catch((e) => {
      console.log("DEBUG: GettingFirebaseDeviceKey FirebaseErrorMessage", e);
      requestPermission();
    });
};

// TODO: implement native message bridge as in:
// https://github.com/FirebaseExtended/analytics-webview/blob/master/web/public/index.js
// https://firebase.google.com/docs/analytics/webview
