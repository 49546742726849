import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    background:
      'linear-gradient(180deg, #EDF4FF 0%, rgba(237, 244, 255, 0) 100%); filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    height: '100%',
    marginLeft: '1px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  innerContainer: {
    marginTop: '1%',
    padding: '10px 20px',
    width: '100%',
  },
}));

export default useStyles;
