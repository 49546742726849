export const UserInitialValues = {
  userState: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    postal_code: '',
    is_email_verified: false,
    isAuthenticated: false,
    isInitialApiFetched: false,
    subscribedStatus: '',
    daysLeft: 0,
    tandem_outside_alert: false,
    role: 'user',
  },
  setUserState: () => {},
};

export const AppInitialValues = {
  appState: {
    userUpComingDateIndex: 0,
    userThisWeekDateIndex: 0,
    userPastDateIndex: 0,
    upComingDateIndex: 0,
    secondaryBundleIndex: 0,
    // 'This Week', 'Upcoming'
    isSubscriptionActive: false,
  },
  setAppState: () => {},
};

export const ContextInitialValues = {
  ...UserInitialValues,
  ...AppInitialValues,
};
