/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context/User';
import { editButtonStyle } from '../styles';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { COLORS } from 'constants/colors';
import moment from 'moment';

const EditNameEmail = () => {
  const { userState } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div
      style={{
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '5px',
      }}
    >
      <Button
        onClick={() => navigate('/edit-profile')}
        variant="outlined"
        size="large"
        sx={editButtonStyle}
        endIcon={
          <div style={{ color: COLORS.blue }}>
            <ModeEditOutlinedIcon fontSize="small" />
          </div>
        }
      >
        <div style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 700 }}>Name:</span> {userState?.first_name}{' '}
          {userState?.last_name} <br />
          <span style={{ fontWeight: 700 }}>Email:</span>{' '}
          <span style={{ textTransform: 'lowercase' }}>{userState?.email}</span>{' '}
          <br />
          <span style={{ fontWeight: 700 }}>Birthdate:</span>{' '}
          {
            // @ts-ignore
            userState?.birth_date
              ? moment(userState?.birth_date).format('MMMM DD, yy')
              : 'Not Provided'
          }{' '}
          <br />
        </div>
      </Button>
    </div>
  );
};

export default EditNameEmail;
