import { useMutation } from 'react-query';
import { addLog as addLogApi} from 'api/logs';
import { ILogs } from 'models/Logs';


export const useCreateLog = () => {
  const { mutateAsync: addLog, isLoading } = useMutation(
    (data: ILogs) => addLogApi(data),
    {
      onSuccess: ({ data }) => {
        console.log(data)
      },
      onError: (error: any) => {
        console.log(error)
      },
    }
  );
  return {
    isLoading,
    addLog,
  };
};
