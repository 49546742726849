import { COLORS } from 'constants/colors';
import React from 'react';

const Seperator = () => (
    <div style={{
        borderBottom: `1px solid ${COLORS.grey}`,
        margin: '24.98px',
        marginTop: '0px'
    }}></div>
)

export default Seperator;