// imports
import { Routes, Route, Navigate } from 'react-router-dom';

// screens
import Signup from '../pages/Signup';
import Signin from '../pages/Signin';
import NotAvailable from '../pages/NotAvailable';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import BundleDetail from 'pages/BundleDetail';
import CodeConfirmation from 'pages/CodeConfirmation';
import Home from 'pages/Home';

const UnauthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/bundles/:slug" element={<BundleDetail />} />
      <Route path="/" element={<Signin />} />
      <Route path="/discover" element={<Home />} />
      <Route path="/join-beta" element={<Signup />}>
        <Route path=":token">
          <Route path=":id" element={<Signup />} />
        </Route>
      </Route>
      <Route path="/not-available" element={<NotAvailable />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/confirm" element={<CodeConfirmation />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
