import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundColor: '#fff',
    borderRadius: '0.6rem',
    width: '96%',
    maxWidth: '500px',

    padding: '2rem',
    textAlign: 'center',

    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
  },
  title: {
    fontWeight: 600,
  },
  subTitle: {
    textAlign: 'left',
    color: '#929DA5',
    paddingTop: '20px',
    paddingBottom: '20px',
    fontSize: '14px',
  },
  closeButton: {
    position: 'absolute',
    right: 30,
    top: 20,
    cursor: 'pointer',
  },
}));
