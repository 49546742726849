import { makeStyles } from '@mui/styles';
import { COLORS } from 'constants/colors';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '3.5%',
    width: '35vw',
    height: '480px',
  },
  contentContainer: {
    color: COLORS.black,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5%',
    gap: '5px',
  },
  bundleMainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  bundleIsPublishedContainer: {
    display: 'flex',
    width: '100%',
    gap: '3.5vw',
    alignItems: 'center',
  },
  bundleDescriptionContainer: {
    border: `1px solid ${COLORS.lightGrey}`,
    fontSize: '14px',
    width: '75%',
    maxHeight: '24px',
    overflow: 'hidden',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  bundleSummaryContainer: {
    height: '100px !important',
    maxHeight: '100px !important',
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
  overRideCardContainer: {
    paddingTop: '3%',
  },
}));

export default useStyles;
