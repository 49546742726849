import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { IInputWithIcon } from './InputWithIcon.types';
import { COLORS } from 'constants/colors';

const InputWithIcon = ({
  placeholder = '',
  handleChange = () => {},
}: IInputWithIcon) => {
  return (
    <Box>
      <TextField
        id="input-with-icon-textfield"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        variant="standard"
        sx={{
          width: '96%',
          border: `1px solid ${COLORS.blue}`,
          borderRadius: 50,
          padding: '10px 10px 10px 20px',
        }}
        placeholder={placeholder}
        onChange={(e: any) => handleChange(e.target.value)}
      />
    </Box>
  );
};

export default InputWithIcon;
