import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { COLORS } from 'constants/colors';

import { IUserConfirmationDialog } from './UserConfirmationDialog.types';

const buttonStyle: any = {
  backgroundColor: COLORS.red,
  color: COLORS.white,
  fontSize: '16px',
  padding: '5px 15px',
  borderRadius: '2px',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const UserConfirmationDialog = ({
  headerText,
  paragraphText,
  confirmationText,
  cancelText,
  open,
  setOpen,
  handleConfirmationClick,
}: IUserConfirmationDialog) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          color={COLORS.blue}
          fontWeight={'bold'}
        >
          {headerText}
        </DialogTitle>
        {paragraphText && (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              color={COLORS.black}
            >
              {paragraphText}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <button
            style={{ ...buttonStyle, backgroundColor: COLORS.blue }}
            onClick={handleClose}
          >
            {cancelText}
          </button>
          <button
            onClick={() => {
              setOpen(false);
              handleConfirmationClick();
            }}
            style={buttonStyle}
          >
            {confirmationText}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserConfirmationDialog;
