import { Box, Modal, Typography } from '@mui/material';
import { COLORS } from 'constants/colors';
import React, { useMemo, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from 'assets/send-icon';
import { useAddPartner } from 'hooks/api';

interface Props {
    children?: React.ReactNode;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InputProps {
    placeholder: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ConnectModal: React.FC<Props> = ({ open, setOpen }) => {
    const firstName = useRef("");
    const email = useRef("");
    const { addPartner, isAddingPartner } = useAddPartner();

    const formWrapperStyle = useMemo(() => (
        {
            position: 'absolute' as 'absolute',
            top: '48%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '90%',
            width: 400,
            color: 'white',
            bgcolor: COLORS.darkBlue,
            boxShadow: 24,
            p: 4,
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    ), [])

    const inputStyle: React.CSSProperties = useMemo(() => (
        {
            padding: '8px',
            borderRadius: '10px',
            border: `1px solid ${COLORS.grey}`,
            outline: 'none',
            background: 'transparent',
            color: 'white'
        }
    ), [])

    const ModalInput: React.FC<InputProps> = (props) => (
        <input className="connect-partner-form" style={inputStyle} {...props} />
    )

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={formWrapperStyle}>
            <Box onClick={() => setOpen(false)} sx={{ cursor: 'pointer' }} position="absolute" right="20px" top="20px">
                <CloseIcon fontSize="medium" />
            </Box>
            <Typography fontSize="18px" lineHeight="21px" fontWeight="700" id="modal-modal-title" variant="h6" component="h2">
                Connect with a Partner
            </Typography>
            <Typography fontSize="18px" lineHeight="21px" textAlign="center" id="modal-modal-description" sx={{ mt: 2 }}>
                Connecting enables personalized date recommendations based on your combined preferences.
            </Typography>
            <Box justifyContent="space-evenly" paddingX="10px"  display="flex" flexDirection="column" border={`1px solid ${COLORS.grey}`} marginTop="40px" height="209px" width="100%" borderRadius="4px">
                <Typography fontSize="10px" lineHeight="12px" letterSpacing="0.04em">
                    What’s your partner’s contact?
                </Typography>
                <ModalInput placeholder="First name" onChange={e => firstName.current = (e.currentTarget.value)} />
                <ModalInput placeholder="Email" onChange={e => email.current = (e.currentTarget.value)} />
                <Box onClick={isAddingPartner ? undefined: () => {addPartner({ email: email.current, firstName: firstName.current }); setOpen(false)}} minHeight="38px" display="flex" flexDirection='row' gap='10px' alignItems='center' padding="5px" borderRadius="10px" sx={{ backgroundColor: COLORS.blue, cursor: 'pointer' }}>
                    <SendIcon />
                    <Box>Send connection request</Box>
                </Box>
            </Box>
        </Box>
        </Modal>
    )
}

export default ConnectModal