import axios from 'axios';

import { ISaveBundleProps, IUnsaveBundleProps } from 'hooks/api';
import { logEvent } from 'helpers/firebase';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getSavedBundlesApi = async () => {
  return axios(`${BASE_URL}/api/user/bundle/all`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
  }).then((response) => response);
};

export const saveBundleApi = async (data: ISaveBundleProps) => {
  return axios(`${BASE_URL}/api/user/bundle`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'POST',
    data,
  }).then((response) => {
    logEvent('add_to_wishlist', { 'items': [{ 'item_id': data.bundle_id }] });
    return response;
  });
};

export const unsaveBundleApi = async (data: IUnsaveBundleProps) => {
  return axios(`${BASE_URL}/api/user/bundle`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'DELETE',
    data,
  }).then((response) => response);
};
