import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import TypeFormQuizForm from 'components/TypeFormQuizForm/TypeFormQuizForm';
import { COLORS } from 'constants/colors';
import { logEvent } from 'helpers/firebase';

interface Props {
  children?: React.ReactNode;
  handleSubmit?: (arg0: boolean) => any;
  userId: string;
}

const QuizForm: React.FC<Props> = ({ handleSubmit, userId }) => {
  const navigate = useNavigate();

  const handleSubmitApi = (completed: boolean = false) => {
    logEvent('tutorial_complete', { 'quizCompleted': completed });
    if (handleSubmit) {
      handleSubmit(completed);
    } else {
      navigate('/');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop="10px"
      padding="10px"
    >
      <Typography
        variant="body2"
        fontSize="24px"
        color="#3381FF"
        fontWeight="700"
      >
        Adjust your preferences
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        paddingLeft="40px"
        paddingRight="40px"
        fontWeight="400"
        lineHeight="21px"
        fontSize="14px"
        marginTop="10px"
      >
         Complete our onboarding profile quiz to help our curators tailor your experiences.
      </Typography>

      <Box
        width="100%"
        paddingLeft="40px"
        paddingRight="40px"
        marginTop="40px"
        position="relative"
      >
        <TypeFormQuizForm
          buttonText="Take the quiz"
          buttonStyle={{
            backgroundColor: COLORS.blue,
            color: COLORS.white,
            border: 'none',
            width: '100%',
            fontWeight: 'bold',
            paddingTop: '11px',
            paddingBottom: '11px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          userId={userId}
          handleSubmit={() => handleSubmitApi(true)}
        />
        <Typography
          onClick={() => handleSubmitApi(false)}
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          position="absolute"
          variant="body2"
          color="rgba(122, 122, 122, 1)"
          fontSize="16px"
          fontWeight="700"
          right="40px"
          paddingTop="11px"
        >
          skip
        </Typography>
      </Box>
    </Box>
  );
};

export default QuizForm;
