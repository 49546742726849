import { Box, Container, Typography, Link } from '@mui/material';
import { UserContext } from 'context/User';
import React, { useContext } from 'react';
import { boxStyle } from '../styles';

const NeedSupport = () => {
  const { appState } = useContext(UserContext);

  return (
    <Box sx={boxStyle}>
      <Container>
        <Typography
          variant="h6"
          fontSize="14px"
          marginBottom="10px"
          marginTop="5px"
        >
          <b>
            {appState.isSubscriptionActive
              ? 'Manage Payment Info'
              : 'Need Support?'}
          </b>
        </Typography>
        <Typography variant="subtitle2" fontSize="12px">
          Issues? Contact{' '}
          <Link href="mailto:support@tandemexperiences.com">
            support@tandemexperiences.com
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default NeedSupport;
