/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react';

import ComponentSection from './ComponentSection/ComponentSection';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Card from 'components/Card/Card';
import { toast } from 'react-toastify';

import { COLORS } from 'constants/colors';
import { COMPONENT_INITIAL_VALUES } from './constants';

import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from '../Header/Header.styles';
import AddIcon from '@mui/icons-material/Add';

import { IBundleComponentDetails, IImageItem } from './BundleComponents.types';
import { IBundleComponent } from 'models/IBundleComponent';
import placeholderImage from 'assets/image.png';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';

type Dictionary<T> = {
  [Key: string]: T;
};

const BundleComponents = ({
  componentsTrigger,
  getComponents,
  bundle,
  mode,
}: IBundleComponentDetails) => {
  const classes = useStyles();
  const [components, setComponents] = useState<Dictionary<IBundleComponent>>(
    bundle
      ? bundle.components
      : {
          '0': { ...COMPONENT_INITIAL_VALUES },
        }
  );
  const [activeComponentIndex, setActiveComponentIndex] = useState('0');

  useEffect(() => {
    if (!bundle) {
      setComponents({
        '0': { ...COMPONENT_INITIAL_VALUES },
      });
      return;
    }
    setComponents(bundle.components);
  }, [bundle]);

  const Seperator = () => (
    <div style={{ borderBottom: `1px solid ${COLORS.grey}` }}></div>
  );

  const deleteComponent = (key: string) => {
    if (Object.keys(components).length === 1) {
      toast.dismiss();
      toast.error('Bundle must have at least 1 component');
      return;
    }
    const componentsTemp: any = {};
    let index = 0;
    Object.keys(components).forEach((keyTemp: string) => {
      if (key !== keyTemp) {
        componentsTemp[index.toString()] = components[keyTemp];
        index += 1;
      }
    });
    setComponents(componentsTemp);
    setActiveComponentIndex('0');
  };

  const onImageError = (e: any) => {
    e.target.src = placeholderImage;
    e.target.style['object-fit'] = 'fill';
  };

  const ImageItem = ({ id }: IImageItem) => {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={'5px'}
        sx={{
          outline:
            id === activeComponentIndex
              ? `3px solid ${COLORS.blue}`
              : `1px solid ${COLORS.lightGrey}`,
        }}
      >
        {components[id]?.component_type === 'Travel' ? (
          <RouteOutlinedIcon
            sx={{
              color: 'black',
              width: '80px',
              height: '120px',
            }}
            onClick={() => setActiveComponentIndex(id)}
          />
        ) : (
          <img
            src={components[id].image_url ?? placeholderImage}
            alt={'Invalid Image'}
            style={{
              width: '80px',
              height: '120px',
              cursor: 'pointer',
              objectFit: components[id].image_url ? 'cover' : 'fill',
              textAlign: 'center',
              fontSize: 'x-large',
            }}
            onError={onImageError}
            onClick={() => setActiveComponentIndex(id)}
          />
        )}
        <DeleteIcon
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => deleteComponent(id)}
        />
      </Box>
    );
  };

  const createNewComponent = () => {
    const nextIndex: string = Object.keys(components).length.toString();
    const componentsTemp = { ...components };
    componentsTemp[nextIndex] = COMPONENT_INITIAL_VALUES;
    setComponents(componentsTemp);
    setActiveComponentIndex((prevState: string) => {
      const prevIndex: number = parseInt(prevState);
      const nextIndexNumber: number = prevIndex + 1;
      const newIndex: string = nextIndexNumber.toString();

      return newIndex;
    });
  };

  const AddComponent = () => {
    return (
      <Box
        className={classes.addComponentContainer}
        onClick={createNewComponent}
      >
        <AddIcon sx={{ fontSize: '70px' }} />
      </Box>
    );
  };

  const Images = () => (
    <Box marginTop={'1%'} gap={'2%'}>
      <Grid container columnGap={'10px'} rowGap={'10px'}>
        {Object.keys(components).map((key: string, index: number) => (
          <ImageItem key={index} id={key} />
        ))}
        {AddComponent()}
      </Grid>
    </Box>
  );

  return (
    <Box width={'100%'} margin={'1.3% 0% 0% 2%'}>
      <Card overRideClass={classes.overrideCard}>
        <Box>
          <Typography fontWeight={'bold'} fontSize={'18px'}>
            Bundle Components
          </Typography>
        </Box>
        <Images />
        <Box marginTop={'2%'}>
          <Seperator />
          <Box marginTop={'2%'}>
            <ComponentSection
              name={`Component #${parseInt(activeComponentIndex) + 1}`}
              componentsTrigger={componentsTrigger}
              getComponents={getComponents}
              components={components}
              setComponents={setComponents}
              activeComponentIndex={activeComponentIndex}
              setActiveComponentIndex={setActiveComponentIndex}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default BundleComponents;
