import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { logEvent } from 'helpers/firebase';

export type IShare = {
  title: string;
  text?: string;
  url: string;
  image?: string;
  id?: string | number;
};

export const getImageBlob = async (url: string | undefined) => {
  if (!url) return null;
  const blob = await fetch(url).then((r) => r.blob());
  return blob;
};
export const handleShare = async ({ id, title, text, url, image }: IShare) => {
  let shareMethod = '';
  if (isMobile && navigator && navigator.share) {
    const blob = await getImageBlob(image);
    const shareObject: IShare = {
      title: title,
      url: url,
    };
    if (text) {
      shareObject['text'] = text;
    }
    const files = [];
    if (blob) files.push(blob);
    navigator.share({
      ...shareObject,
      files: [
        new File(files, 'file.png', {
          type: blob?.type,
        }),
      ],
    });
    shareMethod = 'navigator';
  } else if (navigator && navigator.clipboard) {
    toast.success('Bundle url copied to clipboard!');
    navigator.clipboard.writeText(url);
    shareMethod = 'clipboard'
  }
  if (shareMethod) {
    logEvent('share', { 'method': shareMethod, 'content_type': 'Bundle', 'item_id': String(id) });
  } else {
    toast.error('Error trying to share bundle');
    logEvent('exception', { 'description': `Error sharing bundle ${id}`, 'fatal': false });
  }
};
