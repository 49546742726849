import axios from 'axios';
import { ILogs } from 'models/Logs';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const addLog = async (data: ILogs) => {
  return axios(`${BASE_URL}/api/logs/`, {
    headers: {
      Authorization: localStorage.getItem('tandemToken') || '',
    },
    method: 'POST',
    data: data,
  }).then((response) => response);
};
