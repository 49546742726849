import React from 'react';

const Header = () => (
  <div
    style={{
      width: '100%',
      padding: '24.98px',
      paddingBottom: '16px',
      fontWeight: '900',
      fontSize: '19px',
      lineHeight: '22px',
      textAlign: 'center',
    }}
  >
    Your Saved Experiences
  </div>
);

export default Header;
