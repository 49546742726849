import { Box } from '@mui/material';
import useStyles from './Card.styles';

type ICard = {
  overRideClass?: string;
  children?: any;
};

const Card = ({ overRideClass, children }: ICard) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.container} ${overRideClass}`}>{children}</Box>
  );
};

export default Card;
